import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { IContractFieldToggleContent } from '../../interfaces/IContracts'
import { getNWord } from '../../utils/helpers'

export const ContractFieldToggleContent: FC<IContractFieldToggleContent> = ({
  buttonLabel,
  itemsList,
}) => {
  return (
    <ul className={`listPositionInside ${buttonLabel} textPrimary pl2`}>
      {!!itemsList?.length &&
        itemsList.map((item) => (
          <li key={item}>
            <DynamicFormattedMessage
              id={`page.contract.${getNWord(buttonLabel)}.${item}.label`}
            />
          </li>
        ))}
    </ul>
  )
}
