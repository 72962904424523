import { format } from 'date-fns'
import {
  MIXED_FORM_CONTRACT_TYPE,
  IContractOptions,
  IFieldOption,
  IReclamationForm,
  IDestructionCertificateProduct,
  IDestructionCertificateForm,
} from '../interfaces/IContracts'
import { getJwtUserDetails } from './api'
import {
  FORM_DATE_REQUEST_FORMAT,
  ENGAGEMENTS_PER_PAGE,
  CLIENT_EMAIL,
  CONTENT_DATA,
  DCR_EMAIL,
  KAM_EMAIL,
} from '../enums/common'
import { AUTRE, OUI } from '../constants/form'
import { ROUTES } from '../enums/routes'
import { timezoneOffset, toNumber } from './helpers'
import { IUsersOptions } from '../interfaces/IUsers'
import {
  ICustomObject,
  SEND_EMAIL_API_TYPE,
  TSendEmailData,
} from '../interfaces/ICommonComponents'
import {
  RECLAMATION_AREA,
  MIXED_FORM_CONTRACT_TYPE_ROUTES_CLIENT,
  MIXED_FORM_CONTRACT_TYPE_ROUTES_GENERAL,
} from '../constants/mixedForms'

export const prepareReclamationRequest = (values: any, clientId: string) => {
  const toBoolean = (field: string) => field === OUI

  return {
    clientId: parseInt(clientId, 10),
    userId: getJwtUserDetails().id,
    type: values.type,
    laboratory: values.laboratory,
    incidentDate: values.incidentDate
      ? format(values.incidentDate, FORM_DATE_REQUEST_FORMAT)
      : '',
    timezoneOffset: timezoneOffset(),
    notifier: values.notifier !== AUTRE ? values.notifier : values.other,
    agreementContactInfo: toBoolean(values.agreementContactInfo),
    contactInfo: {
      fullName: values.fullName,
      officeName: values.officeName,
      address: values.reclamationAddress,
      postalCode: values.postalCode,
      city: values.city,
      phone: values.phone,
      fax: values.fax,
      email: values.email,
    },
    agreementExtraDetails: toBoolean(values.agreementExtraDetails),
    patient: {
      initials: values.initials,
      age: parseInt(values.age, 10),
      gender: values.gender?.length ? values.gender[0] : null,
    },
    product: {
      product: values.productConcerned,
      cipCode: values.productCipCode,
      lot: values.lot,
      expirationDate: values.expirationDate
        ? format(values.expirationDate, FORM_DATE_REQUEST_FORMAT)
        : null,
    },
    description: values.description,
    specialtyAvailable: toBoolean(values.specialtyAvailable),
    signature: values.signature,
  } as IReclamationForm
}

export const checkValidation = (validation: any, setShowError: any) => {
  if (validation() !== RECLAMATION_AREA) {
    setShowError(false)
  } else {
    setShowError(true)
  }
}

export const isSmallerThan = (value: Date, comparedValue = new Date()) => {
  return value.getTime() < comparedValue.getTime() || 'smallerThanNow'
}

export const getContractRoute = (
  isMixedForm?: boolean,
  clientId?: number,
  year?: number | null,
  mixedFormType?: MIXED_FORM_CONTRACT_TYPE
) => {
  if (isMixedForm && mixedFormType) {
    return clientId
      ? MIXED_FORM_CONTRACT_TYPE_ROUTES_CLIENT[mixedFormType]
      : MIXED_FORM_CONTRACT_TYPE_ROUTES_GENERAL[mixedFormType]
  }

  if (year) {
    return clientId
      ? ROUTES.clientEngagementsHistory
      : ROUTES.engagementsHistory
  }

  return clientId ? ROUTES.clientEngagements : ROUTES.engagements
}

export const prepareOptions = (options: IContractOptions[]) => {
  return options.map(({ id, label }) => ({
    name: label,
    value: id.toString(),
  }))
}

const getFieldsValues = (values: any, data: any) => {
  const fields: IFieldOption[] = []
  data?.fields?.forEach((item: any) => {
    fields.push({
      id: item.id,
      selectedOptionId: toNumber(values[`field${item.id}`]),
    })
  })

  return fields
}

const getContactNameById = (contactPersons: IUsersOptions[], id: string) => {
  return contactPersons.find((element: IUsersOptions) => {
    return element.value === id
  }) as IUsersOptions
}

export const prepareContractRequestBody = (
  values: any,
  data: any,
  clientId: string,
  contactPersons: IUsersOptions[],
  newContactPerson: IUsersOptions
) => {
  const contactPerson: IUsersOptions = getContactNameById(
    [...contactPersons, newContactPerson],
    values.contactPerson
  )

  return {
    formId: data.formId,
    signature: values.signature,
    client: {
      id: toNumber(clientId),
      contactPerson: contactPerson.name,
    },
    fields: getFieldsValues(values, data),
  }
}

export const prepareSendEmailRequestBody = (
  emailCheckbox: boolean,
  data: any,
  type: SEND_EMAIL_API_TYPE
) => {
  return {
    kamEmail: data?.client?.kamEmail,
    dcrEmail: data?.client?.dcrEmail,
    clientEmail: emailCheckbox ? data?.client?.email : null,
    type,
  }
}

export const prepareManualSendEmailRequestBody = (
  data: TSendEmailData,
  clientEmail: string,
  key: string = CLIENT_EMAIL
) => {
  const kamEmail = data?.kamEmail ? { [KAM_EMAIL]: data?.kamEmail } : {}
  const dcrEmail = data?.dcrEmail ? { [DCR_EMAIL]: data?.dcrEmail } : {}
  const contentData = data?.contentData
    ? { [CONTENT_DATA]: data?.contentData }
    : {}

  return {
    ...kamEmail,
    ...dcrEmail,
    [key]: clientEmail,
    type: data?.type,
    ...contentData,
    entityId: data?.entityId,
  }
}

export const getMissingData = (obj: ICustomObject) =>
  Object.keys(obj).filter((key) => obj[key] === (null || ''))

export const createContractsQuery = (
  year: number,
  offset: number,
  size: number
) => {
  const query = `year=${year}&size=${size}&offset=${
    offset * ENGAGEMENTS_PER_PAGE
  }`

  return query
}

export const prepareProductsPhoto = (photo: string) => {
  if (!photo) return ''

  const uploadedImageData = JSON.parse(photo)

  return uploadedImageData.dataURL
}

export const prepareDestructionCertificateRequestBody = (
  values: any,
  data: any,
  clientId: number,
  contactPersons: IUsersOptions[],
  newContactPerson: IUsersOptions,
  products: IDestructionCertificateProduct[],
  comment: string
) => {
  const contactPerson: IUsersOptions = getContactNameById(
    [...contactPersons, newContactPerson],
    values.contactPerson
  )

  return {
    clientId,
    noOfExpiredProducts: values.noOfExpiredProducts,
    noOfDamagedProducts: values.noOfDamagedProducts,
    signatoryFirstName: contactPerson.firstName,
    signatoryLastName: contactPerson.lastName,
    signature: values.signature,
    city: values.city,
    discountMarket: values.discountMarket,
    photo: prepareProductsPhoto(values.photo),
    timezoneOffset: timezoneOffset(),
    signedAt: values.signedAt
      ? format(new Date(values.signedAt), FORM_DATE_REQUEST_FORMAT)
      : '',
    comment,
    products,
  } as IDestructionCertificateForm
}

export const handleFormModal = (
  formValues: any,
  callback: (modalStatus: boolean) => void
) => {
  let modalStatus = false

  Object.entries(formValues).forEach((item: any) => {
    if ((item[1] !== '' && typeof item[1] === 'string') || item[1] === false) {
      modalStatus = true
    }
  })

  callback(modalStatus)
}
