import React, { FC, useCallback, useEffect, useState } from 'react'
import { IClientDcrCallData } from '../../interfaces/IClientDetails'
import { ClientEventCard } from '../clientAppointmentCard/ClientEventCard'
import { isEventCached } from '../../utils/offline'
import { EVENT_SECTION_DCR_CALLS_COUNT } from '../../constants/visitReport'

const ClientEventsList: FC<{
  data: IClientDcrCallData[]
  total: number
  clientId: string
  path: string
  cachedData?: string[] | undefined
  customClass?: string
  itemsToBeDisplayed?: number
}> = ({
  data,
  total,
  clientId,
  path,
  cachedData,
  customClass,
  itemsToBeDisplayed,
}) => {
  const [itemsToDisplay, setItemsToDisplay] = useState<number>(
    itemsToBeDisplayed || EVENT_SECTION_DCR_CALLS_COUNT
  )

  const handleScroll = useCallback(() => {
    const clientDetailsPageEventsContent = document.querySelector(
      '.clientDetailsPageEventsContent'
    )
    if (!clientDetailsPageEventsContent) return

    const clientDetailsContentHeight =
      clientDetailsPageEventsContent.clientHeight
    const clientDetailsContentScrollHeight =
      clientDetailsPageEventsContent.scrollHeight
    const scrollPosition = clientDetailsPageEventsContent.scrollTop

    const maxScroll =
      clientDetailsContentScrollHeight - clientDetailsContentHeight

    if (scrollPosition >= maxScroll - 200 && itemsToDisplay < total) {
      setItemsToDisplay(
        itemsToDisplay + (itemsToBeDisplayed || EVENT_SECTION_DCR_CALLS_COUNT)
      )
    }
  }, [itemsToDisplay, total, itemsToBeDisplayed])

  useEffect(() => {
    const clientDetailsPageEventsContent = document.querySelector(
      '.clientDetailsPageEventsContent'
    )

    if (clientDetailsPageEventsContent) {
      clientDetailsPageEventsContent.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (clientDetailsPageEventsContent) {
        clientDetailsPageEventsContent.removeEventListener(
          'scroll',
          handleScroll
        )
      }
    }
  }, [handleScroll])

  return (
    <>
      {total !== 0 &&
        data.slice(0, itemsToDisplay).map((eventItem: IClientDcrCallData) => {
          return (
            <ClientEventCard
              key={eventItem.id}
              path={path}
              data={eventItem}
              clientId={clientId}
              customClass={customClass ?? ''}
              disabled={!isEventCached(cachedData, eventItem.id)}
            />
          )
        })}
    </>
  )
}

export default ClientEventsList
