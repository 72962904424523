import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLoadData } from '../UseLoadData'
import { getAppointmentTypes } from '../../services/appointmentsService'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { getAppointmentTypesValue } from '../../store/selectors/sharedDataSelectors'
import {
  getClientAppointmentsList,
  getClientsListServiceData,
} from '../../services/getClientsListService'
import { IFilters } from '../../interfaces/IFilters'
import { allClientsActions } from '../../store/reducers/allClientsReducer'
import { getAllClientsValue } from '../../store/selectors/allClientsSelectors'
import { clientAppointmentsActions } from '../../store/reducers/clientAppointmentsReducer'
import { getJwtUserDetails } from '../../utils/api'
import { sharedDataSelectors } from '../../store/selectors'
import { ROUTES } from '../../enums/routes'
import { getRangeDate } from '../../utils/appointments'
import { STATUS } from '../../enums/common'

export const useInitiateAppointment = (filters: IFilters) => {
  const jwt = getJwtUserDetails()
  const userId = useSelector(sharedDataSelectors.getUserSavedData).selectedUser
  const { pathname } = useLocation()

  const pageUserId = useMemo(
    () => (pathname === ROUTES.agenda ? +userId : jwt?.id),
    [pathname, jwt, userId]
  )

  const {
    data: appointmentTypes,
    status: appointmentTypesStatus,
  } = useLoadData(() => getAppointmentTypes(), {
    fetchFromRedux: true,
    storeInRedux: true,
    reduxAction: sharedDataActions.setAppointmentTypes,
    reduxSelector: getAppointmentTypesValue,
  })

  const { data: allClients, status: allClientsStatus } = useLoadData(
    () => getClientsListServiceData(filters, 0, 500),
    {
      dependencies: [filters],
      fetchFromRedux: true,
      storeInRedux: true,
      reduxAction: allClientsActions.setAllClients,
      reduxSelector: getAllClientsValue,
    }
  )

  const { data: allAppointments, status: allAppointmentsStatus } = useLoadData(
    () => getClientAppointmentsList(getRangeDate(), pageUserId),
    {
      dependencies: [pageUserId],
      storeInRedux: true,
      reduxAction: clientAppointmentsActions.setAllAppointments,
    }
  )

  const isAppointmentDataStatusPending = useMemo(
    () =>
      appointmentTypesStatus === STATUS.PENDING ||
      allClientsStatus === STATUS.PENDING ||
      allAppointmentsStatus === STATUS.PENDING,
    [appointmentTypesStatus, allClientsStatus, allAppointmentsStatus]
  )

  return {
    appointmentTypes,
    allClients,
    allAppointments,
    isAppointmentDataStatusPending,
  }
}
