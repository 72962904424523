import { useForm } from 'react-hook-form'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { addDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import {
  IDashboardVisitsCoverageFilters,
  IVisitsCoverageData,
  TChartDataPerType,
} from '../../../interfaces/IDashboard'
import { useLoadData } from '../../UseLoadData'
import { getOptionsService } from '../../../services/getClientsListService'
import {
  DATEPICKER_FILTERS_FORMAT,
  STATUS,
  TYPE_CIBLE_2_ROUTE,
} from '../../../enums/common'
import {
  dashboardVisitsCoverageFiltersInitialState,
  DEFAULT_VISIT_COVERAGE_DATA_PER_TYPE,
  FILTER_CLIENT_DEFAULT_OPTION,
  FILTER_CLIENT_YEARS,
  FILTER_END_DATE,
  FILTER_START_DATE,
  GROUPMENT_FILTER,
  SELECTED_USER_PARAM,
} from '../../../constants/dashboard'
import {
  currentYearValue,
  getEndOfDay,
  getFirstDayOfCurrentYear,
  getStartOfDay,
} from '../../../utils/helpers'
import { dashboardVisitsCoverageReducerActions } from '../../../store/reducers/dashboardVisitsCoverageReducer'
import {
  dashboardVisitsCoverageSelector,
  statusNetworkSelector,
} from '../../../store/selectors'
import { useGroupIds } from '../UseGroupIds'
import { getDashboardService } from '../../../services/dashboardServices'
import { getJwtUserDetails, handleError } from '../../../utils/api'
import { setUrlParams } from '../../../utils/dashboard'
import { ROUTES } from '../../../enums/routes'
import { isGreaterThan } from '../../../utils/appointments'
import { IHandledError } from '../../../interfaces/ICommonComponents'
import { sharedDataActions } from '../../../store/reducers/sharedDataReducer'

export const useDashboardVisitsCoverageFilters = (
  userId: string,
  setFormState: Dispatch<SetStateAction<IHandledError>>,
  usersStatusPending: boolean,
  setIsUserNotAvailableError: Dispatch<SetStateAction<boolean>>,
  shouldUseLoggedUserId: boolean
) => {
  const intl = useIntl()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const { filters, fetchDataStatus } = useSelector(
    dashboardVisitsCoverageSelector.getDashboardVisitsCoverageValue
  )

  const user = useRef(userId)
  const [isQueryReady, setIsQueryReady] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isFormReset, setIsFormReset] = useState(false)

  const currentYear = currentYearValue

  const form = useForm<IDashboardVisitsCoverageFilters>({ mode: 'onChange' })
  const { control, errors, setValue, getValues } = form

  const { groupIdsResponse, groupIdsStatusPending } = useGroupIds()
  const targetTypes = useLoadData(() => getOptionsService(TYPE_CIBLE_2_ROUTE))
  const targetTypesPending = useMemo(
    () => targetTypes.status === STATUS.PENDING,
    [targetTypes.status]
  )

  const isRegularPageLoad = useMemo(() => !location.search, [location.search])

  const [filtersDates, setFiltersDates] = useState({
    startDate: filters.startDate || getFirstDayOfCurrentYear(),
    endDate: filters.endDate || new Date(),
  })

  const startDate = {
    id: FILTER_START_DATE,
    value: new Date(filtersDates.startDate),
    label: `form.field.${FILTER_START_DATE}.label`,
    format: DATEPICKER_FILTERS_FORMAT,
    setValue: setFiltersDates,
    maxDate: addDays(new Date(filtersDates.endDate), -1),
    classes: 'inputField inputDate datepickerLeft py1 mb2 mb0sm mb2lg',
    control,
    error: errors[FILTER_START_DATE],
    rules: {
      required: true,
    },
  }

  const endDate = {
    id: FILTER_END_DATE,
    value: new Date(filtersDates.endDate),
    label: `form.field.${FILTER_END_DATE}.label`,
    format: DATEPICKER_FILTERS_FORMAT,
    setValue: setFiltersDates,
    minDate: addDays(new Date(filtersDates.startDate), 1),
    classes: 'inputField inputDate datepickerLeft py1 alignSelfStart',
    control,
    error: errors[FILTER_END_DATE],
    rules: {
      required: true,
      validate: {
        greaterThanStartDate: !isRegularPageLoad
          ? () =>
              isGreaterThan(
                new Date(filtersDates.endDate),
                new Date(filtersDates.startDate)
              )
          : () => {},
      },
    },
  }

  const fetchChartGlobalData = useCallback(
    async (values: IDashboardVisitsCoverageFilters) => {
      // getDashboardService for global chart
      setFormState({
        status: STATUS.PENDING,
        message: '',
        messageCode: '',
      })

      try {
        const chartResponse = await getDashboardService<IVisitsCoverageData>(
          { ...values },
          user.current
        )
        dispatch(
          dashboardVisitsCoverageReducerActions.setChartData(chartResponse)
        )
        setFormState({
          status: STATUS.SUCCESS,
          message: '',
          messageCode: '',
        })
      } catch (error) {
        const handledError = handleError(error, intl)
        setFormState(handledError)

        dispatch(
          dashboardVisitsCoverageReducerActions.setChartData(
            DEFAULT_VISIT_COVERAGE_DATA_PER_TYPE
          )
        )
      }
    },
    [dispatch, intl, setFormState]
  )

  const fetchChartPerTypeData = useCallback(
    async (values: IDashboardVisitsCoverageFilters) => {
      // getDashboardService for targetType charts
      await targetTypes?.data
        ?.reduce(async (previousPromise, targetType) => {
          const chartTargetPerType = await previousPromise
          try {
            const chartTargetTypeResponse = await getDashboardService<IVisitsCoverageData>(
              { ...values, targetTypeIds: [targetType] },
              user.current
            )
            chartTargetPerType.push({
              [targetType.label]: {
                ...chartTargetTypeResponse,
                status: STATUS.SUCCESS,
                message: '',
                messageCode: '',
              },
            })
          } catch (error) {
            const handledError = handleError(error, intl)
            chartTargetPerType.push({
              [targetType.label]: {
                ...DEFAULT_VISIT_COVERAGE_DATA_PER_TYPE,
                ...handledError,
              },
            })
          }

          return chartTargetPerType
        }, Promise.resolve([] as TChartDataPerType[]))
        .then((chartTargetPerType) =>
          dispatch(
            dashboardVisitsCoverageReducerActions.setChartDataPerType(
              chartTargetPerType as TChartDataPerType[]
            )
          )
        )
    },
    [dispatch, intl, targetTypes]
  )

  const fetchChartData = useCallback(
    async (values: IDashboardVisitsCoverageFilters) => {
      await Promise.all([
        fetchChartGlobalData(values),
        fetchChartPerTypeData(values),
      ]).then(() =>
        dispatch(
          dashboardVisitsCoverageReducerActions.setFetchDataStatus(
            STATUS.SUCCESS
          )
        )
      )
    },
    [fetchChartGlobalData, fetchChartPerTypeData, dispatch]
  )

  const loadFiltersFromUrlQuery = useCallback(async () => {
    const urlParams = new URLSearchParams(location.search)
    urlParams.forEach((value, key) => {
      if (key === FILTER_START_DATE || key === FILTER_END_DATE) {
        const isValueValid = Date.parse(value)
        const fallbackForInvalidDate =
          key === FILTER_START_DATE ? getFirstDayOfCurrentYear() : new Date()

        const date = isValueValid
          ? utcToZonedTime(value, 'UTC')
          : fallbackForInvalidDate
        setFiltersDates((prevState) => {
          return {
            ...prevState,
            [key]: date,
          }
        })

        return
      }

      if (key === GROUPMENT_FILTER) {
        const urlOptions = groupIdsResponse?.data?.filter((item) =>
          value.includes(item.value)
        )
        setValue(key, urlOptions)

        return
      }

      if (key === SELECTED_USER_PARAM) {
        user.current = value
        dispatch(sharedDataActions.setSelectedUser(user.current))

        return
      }

      setValue(key, value)
    })
  }, [setValue, groupIdsResponse, location.search, dispatch])

  const onSubmit = async (val: IDashboardVisitsCoverageFilters) => {
    dispatch(
      dashboardVisitsCoverageReducerActions.setFetchDataStatus(STATUS.PENDING)
    )
    const submitValues = {
      ...val,
      startDate: getStartOfDay(filtersDates.startDate.toString()),
      endDate: getEndOfDay(filtersDates.endDate.toString()),
    }
    user.current = userId
    const urlParams = setUrlParams({
      selectedUser: user.current,
      ...submitValues,
    })

    dispatch(
      dashboardVisitsCoverageReducerActions.setVisitsCoverageFilters(
        submitValues
      )
    )

    setIsFormSubmitted(true)
    setIsUserNotAvailableError(false)
    await fetchChartData(submitValues)

    history.push({
      pathname: ROUTES.dashboardVisitsCoverage,
      search: `?${urlParams}`,
    })
  }

  const resetFormToInitialState = useCallback(() => {
    dispatch(
      dashboardVisitsCoverageReducerActions.setFetchDataStatus(STATUS.IDLE)
    )
    dispatch(
      dashboardVisitsCoverageReducerActions.setVisitsCoverageFilters(
        dashboardVisitsCoverageFiltersInitialState
      )
    )

    setFiltersDates((prevState) => {
      return {
        ...prevState,
        startDate: getFirstDayOfCurrentYear(),
        endDate: new Date(),
      }
    })
    FILTER_CLIENT_YEARS.map((key) =>
      setValue(key, FILTER_CLIENT_DEFAULT_OPTION)
    )
    setIsUserNotAvailableError(false)
  }, [setValue, dispatch, setIsUserNotAvailableError])

  const resetFilters = useCallback(async () => {
    setIsFormReset(true)

    resetFormToInitialState()
    setValue(GROUPMENT_FILTER, [])

    history.push({
      pathname: ROUTES.dashboardVisitsCoverage,
      search: '',
    })
  }, [setValue, resetFormToInitialState, history])

  useEffect(() => {
    if (!isRegularPageLoad || isFormReset) return

    resetFormToInitialState()
    setValue(GROUPMENT_FILTER, groupIdsResponse.data)
  }, [
    setValue,
    groupIdsResponse.data,
    isRegularPageLoad,
    resetFormToInitialState,
    isFormReset,
  ])

  useEffect(() => {
    if (isRegularPageLoad) return

    loadFiltersFromUrlQuery().then(() => {
      setIsQueryReady(true)
    })
  }, [isRegularPageLoad, loadFiltersFromUrlQuery])

  const shouldLoadFromQuery = useMemo(
    () =>
      !isFormSubmitted &&
      isQueryReady &&
      !usersStatusPending &&
      !groupIdsStatusPending &&
      !targetTypesPending,
    [
      isQueryReady,
      groupIdsStatusPending,
      isFormSubmitted,
      targetTypesPending,
      usersStatusPending,
    ]
  )

  const setCurrentUser = useCallback(async () => {
    if (shouldUseLoggedUserId) {
      user.current = getJwtUserDetails()?.id.toString()
    }
  }, [shouldUseLoggedUserId])

  useEffect(() => {
    if (!shouldLoadFromQuery) return

    const queryValues = {
      ...(getValues() as IDashboardVisitsCoverageFilters),
      startDate: getStartOfDay(filtersDates.startDate.toString()),
      endDate: getEndOfDay(filtersDates.endDate.toString()),
    }
    dispatch(
      dashboardVisitsCoverageReducerActions.setFetchDataStatus(STATUS.PENDING)
    )
    dispatch(
      dashboardVisitsCoverageReducerActions.setVisitsCoverageFilters(
        queryValues
      )
    )

    setCurrentUser()
      .then(() => {
        dispatch(sharedDataActions.setSelectedUser(user.current))
      })
      .then(() => {
        fetchChartData(queryValues).then(() => {
          setIsQueryReady(false)
        })
      })

    // eslint-disable-next-line
  }, [
    getValues,
    fetchChartData,
    dispatch,
    shouldLoadFromQuery,
    setCurrentUser
  ])

  const disabledActionButtons = useMemo(
    () =>
      fetchDataStatus === STATUS.PENDING ||
      usersStatusPending ||
      groupIdsStatusPending ||
      targetTypesPending,
    [
      groupIdsStatusPending,
      fetchDataStatus,
      targetTypesPending,
      usersStatusPending,
    ]
  )

  return {
    form,
    onSubmit,
    resetFilters,
    groupIdsResponse,
    groupIdsStatusPending,
    startDate,
    endDate,
    currentYear,
    filters,
    hasNetwork,
    disabledActionButtons,
  }
}
