import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { joinData } from '../../../utils/helpers'
import { IClientDetailsData } from '../../../interfaces/IClientDetails'
import Checkbox from '../../form/Checkbox'

const ClientAddressSection: FC<{
  clientDetails: IClientDetailsData
  includeAddress2Details?: boolean
}> = ({ clientDetails, includeAddress2Details = false }) => {
  const { register } = useFormContext()

  return (
    <div className="addressArea">
      <div className="textPrimary mb2">
        <DynamicFormattedMessage
          id="form.destructionCertificate.address.label"
          tag="h4"
          className="textPrimary fontMedium pb05"
        />
        <p>
          {joinData([
            clientDetails?.address1,
            clientDetails?.zipCode,
            clientDetails?.city,
          ]) || '-'}
        </p>
        {includeAddress2Details && clientDetails?.address2 && (
          <p className="pt05">
            {joinData([
              clientDetails?.address2,
              clientDetails?.zipCode,
              clientDetails?.city,
            ])}
          </p>
        )}
      </div>
      <div className="textPrimary">
        <DynamicFormattedMessage
          id="form.destructionCertificate.email.label"
          tag="h4"
          className="textPrimary fontMedium pb05"
          wrapTextClass="pr05"
        >
          {(clientDetails?.email && (
            <Checkbox
              name="emailCheckbox"
              id="emailCheckbox"
              customClass="dInlineBlock"
              wrapperClass="inputCheckboxWrapperInline"
              register={register}
              defaultValue={!!clientDetails?.email}
              values={{ email: clientDetails?.email }}
            />
          )) ||
            '-'}
        </DynamicFormattedMessage>
      </div>
      <div className="textPrimary">
        <DynamicFormattedMessage
          id="form.destructionCertificate.fax.label"
          tag="h4"
          className="textPrimary fontMedium pb05"
        >
          <span className="fontRegular pl05">{clientDetails?.faxNumber}</span>
        </DynamicFormattedMessage>
      </div>
      <div className="textPrimary">
        <DynamicFormattedMessage
          id="form.destructionCertificate.tel.label"
          tag="h4"
          className="textPrimary fontMedium pb05"
        >
          <span className="fontRegular pl05">{clientDetails?.phoneNumber}</span>
        </DynamicFormattedMessage>
      </div>
    </div>
  )
}

export default ClientAddressSection
