import React, { FC, useState } from 'react'
import Button from './Button'
import DynamicFormattedMessage from './DynamicFormattedMessage'
import { IToggleSection } from '../../../interfaces/ICommonComponents'

export const ToggleSection: FC<IToggleSection> = ({
  children,
  buttonLabel,
  customClass = 'fontRegular textItalic',
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false)

  const onButtonClick = () => {
    setIsContentVisible(!isContentVisible)
  }

  return (
    <div className="my15">
      <DynamicFormattedMessage
        id={buttonLabel}
        tag={Button}
        className={`btn textPrimary withBackgroundTransparent pl0 py0 toggle ${customClass} ${
          isContentVisible ? 'opened mb1' : ''
        }`}
        onClick={onButtonClick}
        data-qa="toggleSection"
      />
      {isContentVisible && <>{children}</>}
    </div>
  )
}
