import React, { FC } from 'react'
import { UsersSelect } from '../../users/UsersSelect'
import { IUsersOptions } from '../../../interfaces/IUsers'
import { Filters } from './Filters'
import { useDashboardVisitsCoverage } from '../../../hooks/dashboard/dashboardVisitsCoverage/UseDashboardVisitsCoverage'
import Loader from '../../loader/Loader'
import { STATUS } from '../../../enums/common'
import { ChartGlobalArea } from './ChartGlobalArea'
import { ChartPerTypeArea } from './ChartPerTypeArea'
import { UserUnavailableMessage } from '../common/UserUnavailableMessage'

export const DashboardVisitsCoverageContent: FC = () => {
  const {
    hasNetwork,
    userId,
    users,
    onUserChange,
    statusPending,
    selectedUserName,
    formState,
    setFormState,
    formStateIdleOrPending,
    chartData,
    chartDataPerType,
    fetchDataStatus,
    isUsersLoadingError,
    isUserNotAvailableError,
    setIsUserNotAvailableError,
    isUserDifferentFromSearchParam,
    shouldUseLoggedUserId,
  } = useDashboardVisitsCoverage()

  return (
    <>
      <UsersSelect
        name="userFilter"
        users={users?.data as IUsersOptions[]}
        onUserChange={onUserChange}
        defaultUser={userId.toString()}
        disabled={!hasNetwork || statusPending}
        wrapperClass="colMd5 mb1 px0"
        isUsersLoadingError={isUsersLoadingError}
        isUserNotAvailableError={isUserNotAvailableError}
      />
      <UserUnavailableMessage shouldRender={isUserDifferentFromSearchParam} />
      <Filters
        userId={userId}
        setFormState={setFormState}
        usersStatusPending={statusPending}
        setIsUserNotAvailableError={setIsUserNotAvailableError}
        shouldUseLoggedUserId={shouldUseLoggedUserId}
      />
      {fetchDataStatus === STATUS.PENDING && <Loader />}
      {!formStateIdleOrPending && (
        <ChartGlobalArea
          chartDataResponse={{ ...chartData, ...formState }}
          selectedUserName={selectedUserName}
        />
      )}
      {!formStateIdleOrPending && (
        <ChartPerTypeArea
          chartDataResponsePerType={chartDataPerType}
          selectedUserName={selectedUserName}
        />
      )}
    </>
  )
}
