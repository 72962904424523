export const INPUT_EXPIRED_PRODUCTS = 'noOfExpiredProducts'
export const INPUT_DAMAGED_PRODUCTS = 'noOfDamagedProducts'
export const INPUT_REMISE_MARCHE = 'discountMarket'
export const INPUT_CITY = 'city'
export const INPUT_DATE_SIGNED_AT = 'signedAt'
export const INPUT_COMMENT = 'comment'
export const INPUT_PHOTO = 'photo'

export const PRODUCT_TABLE_INPUT_MAX_LENGTH = 50
export const PRODUCT_TABLE_COMMENT_MAX_LENGTH = 50
export const INPUT_LENGTH = {
  [INPUT_REMISE_MARCHE]: 99,
  [INPUT_CITY]: 99,
}

export const INPUT_SIZE_MIN = {
  [INPUT_EXPIRED_PRODUCTS]: 1,
  [INPUT_DAMAGED_PRODUCTS]: 1,
}

export const INPUT_SIZE_MAX = {
  [INPUT_EXPIRED_PRODUCTS]: 99,
  [INPUT_DAMAGED_PRODUCTS]: 99,
}
