import { addHours, format } from 'date-fns'
import {
  IClientAppointmentData,
  IClientDcrCallData,
  IClientDetailsActions,
  IClientDetailsData,
  IClientReportData,
} from '../interfaces/IClientDetails'
import {
  IAppointmentDate,
  IAppointmentType,
  IContactPerson,
} from '../interfaces/IAppointments'
import { ROUTES } from '../enums/routes'
import { ICustomObject } from '../interfaces/ICommonComponents'
import { DATE_REQUEST_FORMAT, KAM_EMAIL } from '../enums/common'

export const clientDetailDataInitialState: IClientDetailsData = {
  id: 0,
  cipCode: '',
  clientCode: '',
  name: '',
  email: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  faxNumber: '',
  phoneAgentName: '',
  phoneAgentComment: '',
  fieldAgentName: '',
  fieldAgentComment: '',
  group: '',
  gpsLongitude: 0,
  gpsLatitude: 0,
  potentiel1: '',
  potentiel2: '',
  potentiel3: '',
  qualification1: '',
  qualification2: '',
  qualification3: '',
  targetType1: '',
  targetType2: '',
  targetType3: '',
  potential: '',
  kamEmail: '',
}

export const clientAppointmentDataInitialState: IClientAppointmentData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    contactPerson: '',
  },
]

export const clientReportsDataInitialState: IClientReportData[] = [
  {
    id: 0,
    startDate: '',
    eventType: '',
    formationType: '',
    outcome: '',
    contactPerson: '',
  },
]

export const clientDcrCallDataInitialState: IClientDcrCallData[] = [
  {
    resourceType: '',
    id: 0,
    startDate: '',
    eventType: '',
    formationType: '',
    outcome: '',
    contactPerson: '',
    comment: '',
  },
]

export const appointmentTypesDataInitialState: IAppointmentType[] = [
  {
    id: 0,
    eventType: '',
    isVisible: false,
  },
]
export const contactPersonsDataInitialState: IContactPerson[] = [
  {
    id: 0,
    firstName: '',
    lastName: '',
  },
]

export const datesInitialState: IAppointmentDate = {
  startDate: format(addHours(new Date(), 1), DATE_REQUEST_FORMAT),
  endDate: format(addHours(new Date(), 2), DATE_REQUEST_FORMAT),
}

export const ACTION_NOUVEAU_RENDEZ_VOUS: string = 'newAppointment'
export const ACTION_NOUVEAU_RAPPORT = 'raport'
const ACTION_FICHE_CLIENT: string = 'ficheClient'
export const ACTION_FORMULAIRES: string = 'formularies'
export const ACTION_HISTORIQUE: string = 'historique'

// For the moment, only the client file page / route is implemented
// TODO Update remaining rountes, when the page is created
export const USER_DETAILS_ACTION_LIST: IClientDetailsActions[] = [
  {
    actionName: ACTION_NOUVEAU_RAPPORT,
    actionUrl: ROUTES.newVisitReport,
  },
  {
    actionName: ACTION_NOUVEAU_RENDEZ_VOUS,
    actionUrl: ROUTES.newAppointmentAgenda,
  },
  {
    actionName: ACTION_FICHE_CLIENT,
    actionUrl: ROUTES.clientFile,
  },
  {
    actionName: ACTION_FORMULAIRES,
    actionUrl: ROUTES.clientForms,
  },
  {
    actionName: ACTION_HISTORIQUE,
    actionUrl: ROUTES.history,
  },
]

export const FIELD_AGENT_COMMENT = 'fieldAgentComment'
export const PHONE_AGENT_COMMENT = 'phoneAgentComment'
export const GPS_LONGITUDE = 'gpsLongitude'
export const GPS_LATITUDE = 'gpsLatitude'
export const POTENTIEL_1 = 'potentiel1'
export const POTENTIEL_2 = 'potentiel2'
export const POTENTIEL_3 = 'potentiel3'
export const QUALIFICATION_1 = 'qualification1'
export const QUALIFICATION_3 = 'qualification3'
export const TARGET_TYPE_2 = 'targetType2'
export const KAM = 'fieldAgentName'
export const DCR = 'phoneAgentName'

export const FILTERED_DATA_FIELDS: (keyof IClientDetailsData)[] = [
  FIELD_AGENT_COMMENT,
  PHONE_AGENT_COMMENT,
  GPS_LONGITUDE,
  GPS_LATITUDE,
  POTENTIEL_2,
  POTENTIEL_3,
  QUALIFICATION_3,
  POTENTIEL_1,
  KAM,
  DCR,
  KAM_EMAIL,
]

export const SYNERGIE_DATA_FIELDS: (keyof IClientDetailsData)[] = [KAM, DCR]

const MONDAY = 'monday'
const TUESDAY = 'tuesday'
const WEDNESDAY = 'wednesday'
const THURSDAY = 'thursday'
const FRIDAY = 'friday'
const SATURDAY = 'saturday'
const SUNDAY = 'sunday'

export const WEEK_DAYS_LIST = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
]

// RangeStepInput default, min, max and step for visit report first panel numberOfPersons
export const RANGE_PERSONS_DEFAULT = 1
export const RANGE_PERSONS_MIN = 1
export const RANGE_PERSONS_MAX = 20
export const RANGE_PERSONS_STEP = 1

export const VISIT_ACTION_TYPE_APPEL = 'Appel'
export const VISIT_OUTCOME_VISITE_DISTANCE = 'Visite argu a Distance'

const CLIENT_NAME = 'CLIENT'
const KAM_NAME = 'KAM'
const DCR_NAME = 'DCR'

export const EMAIL_CONTACT: ICustomObject = {
  email: CLIENT_NAME,
  kamEmail: KAM_NAME,
  dcrEmail: DCR_NAME,
}

export const DESTRUCTION_CERTIFICATE_EMAIL_CONTACT: ICustomObject = {
  email: CLIENT_NAME,
  kamEmail: KAM_NAME,
}

// Offline sync page actions list
export const ACTION_TYPES = [
  'appointments',
  'visit-reports',
  'reclamations',
  'destructionCertificates',
  'contracts',
  'sendEmail',
  'clients',
  'upload',
] as const

export enum CLIENT_SOURCE {
  MAP = 'map',
}

export const DCR_CALL = 'dcrCall'
