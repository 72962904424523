import React, { ReactElement } from 'react'
import ReactTooltip from 'react-tooltip'
import { useIntl } from 'react-intl'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { iconInfo } from '../../assets/images/images'

function TextInput({
  name,
  id,
  register,
  error,
  placeholder = undefined,
  defaultValue,
  onFocus,
  disabled = false,
  customLabel,
  type = 'text',
  customClass = '',
  inputClass = '',
  hasLabel = true,
  hasInfoLabel,
  labelIdentifier,
}: {
  name: string
  id: string
  register: (ref: HTMLInputElement) => void
  error?: FieldError | undefined
  placeholder?: string | undefined
  defaultValue?: string | undefined
  onFocus?: () => void
  disabled?: boolean
  customLabel?: string
  type?: string
  customClass?: string | undefined
  inputClass?: string
  hasLabel?: boolean
  hasInfoLabel?: boolean
  labelIdentifier?: string
}): ReactElement {
  const intl = useIntl()
  return (
    <div className={`inputGroup mb15 ${customClass && customClass}`}>
      {hasLabel && (
        <div className="dFlex">
          <div className="dFlex alignContentCenter">
            <DynamicFormattedMessage
              tag="label"
              id={`form.field.${labelIdentifier ?? name}.label`}
              className="inputLabel"
              htmlFor={id}
            />
            {hasInfoLabel && (
              <DynamicFormattedMessage
                tag="span"
                id={`form.field.${name}.info.label`}
                className="infoLabel"
              />
            )}
          </div>
          {name === 'range' && (
            <>
              <img
                src={iconInfo}
                alt="icon info"
                className="inputLabel ml1"
                data-tip={intl.formatMessage({
                  id: 'tooltip.map.infoRange.label',
                })}
              />
              <ReactTooltip
                className="fontMediumSize"
                place="top"
                type="info"
                effect="solid"
              />
            </>
          )}
        </div>
      )}
      <div className="relative inputFieldWrapper" data-content={customLabel}>
        <input
          className={`inputField ${inputClass} ${customLabel ? 'pr3' : ''}`}
          type={type}
          name={name}
          ref={register}
          id={id}
          placeholder={
            placeholder &&
            intl.formatMessage({
              id: `placeholder.${placeholder}`,
            })
          }
          defaultValue={defaultValue}
          data-qa={id}
          onFocus={onFocus}
          disabled={disabled}
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        />
      </div>
      {error && (
        <DynamicFormattedMessage
          tag="span"
          id={`form.validation.${
            ['pattern', 'validate'].includes(error.type)
              ? error.message
              : error.message || error.type
          }`}
          className="errorMessage"
        />
      )}
    </div>
  )
}

export default TextInput
