import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { IClientFileDataListProps } from '../../interfaces/IClientDetails'
import { filterClientData } from '../../utils/clientData'
import { QUALIFICATION_1, TARGET_TYPE_2 } from '../../constants/userData'
import ClientFileItemData from './ClientFileItemData'

export const ClientFileDataList: FC<IClientFileDataListProps> = ({
  clientDetailsData,
  filteringList,
  shouldOmit,
}) => {
  const listItems = filterClientData(
    clientDetailsData,
    filteringList,
    shouldOmit
  )

  return (
    <ul className="unstyledList clientFileDataList">
      {listItems.map((item) => {
        const date = new Date()
        const currentMonth = date.getMonth()
        const currentYear =
          currentMonth < 9 ? date.getFullYear() : date.getFullYear() + 1
        const renderedYear =
          item === QUALIFICATION_1 || item === TARGET_TYPE_2
            ? currentYear
            : currentYear - 1

        return (
          <li key={item}>
            <DynamicFormattedMessage
              tag="span"
              id={`clientFile.dataType.${item}.label`}
              values={{ year: renderedYear }}
            />
            <div>
              <ClientFileItemData {...{ item, clientDetailsData }} />
            </div>
          </li>
        )
      })}
    </ul>
  )
}
