import { useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ADVANCED_FILTERS_MODAL_FORM_KEY,
  ADVANCED_SEARCH_SELECT_INPUT_FIELDS,
  advancedFiltersInitialState,
} from '../../constants/filters'
import { clientsFiltersActions } from '../../store/reducers/filtersReducer'
import { convertFiltersDatetime } from '../../utils/data'
import { IFilters, TModalFilters } from '../../interfaces/IFilters'
import { useUrlParamsHook } from '../UseUrlParamsHook'
import { useGeolocation } from '../map/UseGeolocation'
import { getCurrentYear } from '../../utils/helpers'
import { INITIAL_ACTIVE_PAGE } from '../../enums/common'
import { ROUTES } from '../../enums/routes'
import { clearSimpleSearchInput, isPage } from '../../utils/filters'

export const useFiltersHook = (
  {
    setFilters,
    filters,
    toggleModal,
    changePage,
  }: TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>,
  callback?: () => void
) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [isFocused, setIsFocused] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [lastVisitDate, setLastVisitDate] = useState({
    startDate: filters?.lastVisitStart ?? '',
    endDate: filters?.lastVisitEnd ?? '',
  })
  const [formKey, setFormKey] = useState(ADVANCED_FILTERS_MODAL_FORM_KEY)

  useGeolocation({ isFocused, setIsDisabled })

  const { setUrlQueryParams } = useUrlParamsHook()

  const isMapPage = useMemo(() => isPage(pathname, ROUTES.map), [pathname])

  const isPortfolioPage = useMemo(
    () => isPage(pathname, ROUTES.accountsPortfolio),
    [pathname]
  )

  const onSubmit = async (values: IFilters) => {
    const simpleSearchInput = document.getElementsByClassName(
      'simpleSearchName'
    )[0] as HTMLInputElement
    simpleSearchInput.value = ''
    setFilters((prevState: IFilters) => {
      const newFilters = {
        ...prevState,
        ...values,
        search: '',
        lastVisitStart: lastVisitDate.startDate,
        lastVisitEnd: lastVisitDate.endDate,
      }

      dispatch(
        clientsFiltersActions.setFilters(convertFiltersDatetime(newFilters))
      )

      if (changePage) {
        changePage(INITIAL_ACTIVE_PAGE)
      }

      return newFilters
    })

    if (typeof toggleModal !== 'undefined') {
      toggleModal()
    }

    setUrlQueryParams(values)
  }

  const resetSimpleSearchOnPortfolioPage = useCallback(
    (route?: string) => {
      history.push({
        pathname: route ?? pathname,
        search: `?page=${INITIAL_ACTIVE_PAGE}`,
      })

      clearSimpleSearchInput('simpleSearchName')
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })

      setFilters((prevState: IFilters) => {
        const newFilters = {
          ...prevState,
          ...advancedFiltersInitialState,
          search: '',
          lastVisitStart: lastVisitDate.startDate,
          lastVisitEnd: lastVisitDate.endDate,
        }

        dispatch(
          clientsFiltersActions.setFilters(convertFiltersDatetime(newFilters))
        )
        dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))

        if (changePage) {
          changePage(INITIAL_ACTIVE_PAGE, true)
        }

        return newFilters
      })

      if (callback) {
        callback()
      }
    },
    [
      pathname,
      callback,
      dispatch,
      history,
      changePage,
      lastVisitDate.endDate,
      lastVisitDate.startDate,
      setFilters,
    ]
  )

  const resetSimpleSearchOnMapPage = useCallback(
    (route?: string) => {
      history.push({
        pathname: route ?? pathname,
        search: '',
      })

      clearSimpleSearchInput('simpleSearchText')
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })

      setFilters((prevState) => {
        return {
          ...prevState,
          ...advancedFiltersInitialState,
          search: '',
          lastVisitStart: '',
          lastVisitEnd: '',
        }
      })

      dispatch(
        clientsFiltersActions.setFilters(
          convertFiltersDatetime({
            ...advancedFiltersInitialState,
            search: '',
            lastVisitStart: '',
            lastVisitEnd: '',
          })
        )
      )
      dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))

      if (callback) {
        callback()
      }
    },
    [pathname, setFilters, dispatch, history, callback]
  )

  const resetFiltersOnSimpleSearch = useCallback(
    (route?: string) => {
      return isPortfolioPage
        ? resetSimpleSearchOnPortfolioPage(route)
        : isMapPage
        ? resetSimpleSearchOnMapPage(route)
        : false
    },
    [
      isPortfolioPage,
      resetSimpleSearchOnPortfolioPage,
      isMapPage,
      resetSimpleSearchOnMapPage,
    ]
  )

  const resetFiltersOnPortfolioPage = useCallback(
    (route?: string) => {
      history.push({
        pathname: route ?? pathname,
        search: `?page=${INITIAL_ACTIVE_PAGE}`,
      })

      setFilters(() => {
        if (changePage) {
          changePage(INITIAL_ACTIVE_PAGE)
        }

        return advancedFiltersInitialState
      })
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })

      setFormKey(`${formKey}Reset`)

      dispatch(
        clientsFiltersActions.setFilters(
          convertFiltersDatetime(advancedFiltersInitialState)
        )
      )
      dispatch(clientsFiltersActions.setSelectedPage(INITIAL_ACTIVE_PAGE))

      if (callback) {
        callback()
      }
    },
    [pathname, dispatch, callback, changePage, history, setFilters, formKey]
  )

  const resetFilters = useCallback(
    (route?: string) => {
      return isPortfolioPage
        ? resetFiltersOnPortfolioPage(route)
        : isMapPage
        ? resetFiltersOnSimpleSearch(route)
        : false
    },
    [
      isPortfolioPage,
      resetFiltersOnPortfolioPage,
      isMapPage,
      resetFiltersOnSimpleSearch,
    ]
  )

  return {
    isFocused,
    setIsFocused,
    isDisabled,
    setIsDisabled,
    currentYear: getCurrentYear(),
    lastVisitDate,
    setLastVisitDate,
    onSubmit,
    resetFilters,
    resetFiltersOnSimpleSearch,
    formKey,
  }
}
