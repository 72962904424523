import React, { FC } from 'react'
import { generatePath } from 'react-router-dom'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import Alerts from '../components/alerts/Alerts'
import { STATUS } from '../enums/common'
import Loader from '../components/loader/Loader'
import { ClientDetailsInfo } from '../components/clientDetailsPage/ClientDetailsInfo'
import { ClientDetailsActions } from '../components/clientDetailsPage/ClientDetailsActions'
import { ClientDetailsMap } from '../components/map/ClientDetailsMap'
import { ClientEventsSection } from '../components/clientEventsSection/ClientEventsSection'
import ModalContainer from '../components/modal/Modal'
import { ErrorMessageModal } from '../components/modal/ErrorMessageModal'
import { ROUTES } from '../enums/routes'
import { InfoMessageAlerts } from '../components/alerts/InfoMessageAlerts'
import { capitaliseString, getAlertType } from '../utils/helpers'
import {
  IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE,
  VISIT_REPORT_IMAGE_LIST,
} from '../constants/visitReport'
import { StickyWrapper } from '../components/common/ui/StickyWrapper'
import { useClientDetailsPage } from '../hooks/clientDetails/UseClientDetailsPage'
import { ClientLastVisitReportImagesSection } from '../components/clientDetailsPage/ClientLastVisitReportImagesSection'
import { useLoadData } from '../hooks/UseLoadData'
import { getClientLastVisitReportImages } from '../services/visitReportsService'
import { getClientLastVisitReport } from '../store/selectors/clientLastVisitReportSelectors'

const ClientDetails: FC = () => {
  const {
    history,
    state,
    clientId,
    name,
    cipCode,
    gpsLatitude,
    gpsLongitude,
    status,
    message,
    clientInfo,
    clientLocation,
    clientAppointments,
    clientLastOrder,
    contactPersons,
    pageTitleProps,
    isClientCached,
    cachedVisitReports,
    cachedAppointments,
    lastClientDetailsData,
    isModalOpen,
    setIsModalOpen,
    hasOnlyOneInfoColumn,
    dataStatusPendingByAction,
    clientFilteredAppointments,
    clientNextAppointment,
    clientDcrCalls,
  } = useClientDetailsPage()

  const { data } = useLoadData(() => getClientLastVisitReportImages(clientId), {
    fetchFromRedux: true,
    reduxSelector: getClientLastVisitReport,
    reduxStorePath: clientId.toString(),
  })

  const isWithoutImages = VISIT_REPORT_IMAGE_LIST.every(
    (photo) => data[photo]?.content === null
  )

  return (
    <section className="pageContentWrapper clientDetailsPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={state?.backRoute ?? generatePath(ROUTES.accountsPortfolio)}
          backData={state?.offset}
          {...pageTitleProps}
        />
        <StickyWrapper>
          <Alerts status={status} message={message ?? ''} />
          {!isClientCached && (
            <InfoMessageAlerts
              type="warning"
              message="network.offline.message.client.dataUncached"
              data={[lastClientDetailsData?.name]}
            />
          )}
        </StickyWrapper>
        {status && status === STATUS.PENDING && <Loader />}
        {name && name.length > 0 && isClientCached && (
          <div className="clientDetailsPageContent">
            <div
              className={`mb15 ${
                hasOnlyOneInfoColumn
                  ? 'clientDetailsPageContentBottom'
                  : 'clientDetailsPageContentTop'
              }`}
            >
              <ClientDetailsInfo
                {...clientInfo}
                contactPerson={contactPersons}
                hasOnlyOneColumn={hasOnlyOneInfoColumn}
                clientFilteredAppointments={clientFilteredAppointments}
              />
              <ClientDetailsActions
                clientId={clientId}
                offset={state?.offset}
                currentPage={state?.currentPage}
                client={{ id: +clientId, name, cipCode }}
                dataStatusPendingByAction={dataStatusPendingByAction}
              />
            </div>
            <div
              className={`clientDetailsPageContentBottom ${
                isWithoutImages ? 'noVisitReportImages' : ''
              }`}
            >
              <ClientEventsSection
                clientAppointments={clientAppointments}
                clientLastOrder={clientLastOrder}
                clientId={clientId}
                cachedClientAppointments={cachedAppointments}
                cachedClientReports={cachedVisitReports}
                clientNextAppointment={clientNextAppointment}
                clientDcrCalls={clientDcrCalls}
              />
              <div className="clientDetailsPageContentBottomRight">
                <ClientDetailsMap
                  clientLatitude={gpsLatitude}
                  clientLongitude={gpsLongitude}
                  clientAddress={clientLocation}
                />
                <ClientLastVisitReportImagesSection
                  path={ROUTES.viewVisitReport}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalContainer
        isOpen={(isModalOpen && state?.messageCode !== undefined) || false}
        portalClassName={`errorMessageModalWrapper background${capitaliseString(
          getAlertType(state?.messageCode)
        )}`}
      >
        <ErrorMessageModal
          closeModal={() => {
            setIsModalOpen(false)
            history.replace(
              { pathname: generatePath(ROUTES.clientDetails, { clientId }) },
              { offset: state?.offset, currentPage: state?.currentPage }
            )
          }}
          message={state?.messageCode || IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE}
        />
      </ModalContainer>
    </section>
  )
}

export default ClientDetails
