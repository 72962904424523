import { useDispatch } from 'react-redux'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { sharedDataActions } from '../../store/reducers/sharedDataReducer'
import { ROUTES } from '../../enums/routes'
import { loginActions } from '../../store/reducers/loginReducer'
import { AUTHORIZATION_TOKEN } from '../../constants/api'
import { clearDataFromIndexedDb } from '../../utils/offline'
import { INavLinkSubmenuItem } from '../../interfaces/INavigation'
import { joinData } from '../../utils/helpers'

export const useNavbarLink = (
  isMenuOpen: boolean,
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>,
  nodes?: INavLinkSubmenuItem[],
  closeMenuOnClick?: () => void
) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const isNavbarLinkSubmenuActive = useRef(false)

  const onNavbarLinkSubmenuClick = () => {
    dispatch(sharedDataActions.setNavClick(true))
    isNavbarLinkSubmenuActive.current = true
    if (closeMenuOnClick) {
      closeMenuOnClick()
    }
  }

  const onNavbarLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Click behaviour for navlink with nodes and menu closed
    if (!isMenuOpen && nodes?.length) {
      event.preventDefault()
      dispatch(sharedDataActions.setNavClick(false))
      setIsMenuOpen(true)
      setIsSubmenuOpen(true)

      return
    }

    // Click behaviour for navlink without nodes or menu closed
    if (!isMenuOpen || !nodes?.length) {
      dispatch(sharedDataActions.setNavClick(true))
      if (closeMenuOnClick) {
        closeMenuOnClick()
      }

      return
    }

    // Click behaviour for navlink with nodes and menu opened
    event.preventDefault()
    dispatch(sharedDataActions.setNavClick(false))
    setIsSubmenuOpen(!isSubmenuOpen)
  }

  const onLogoutConfirm = () => {
    setIsModalOpen(false)
    history.push(ROUTES.login)
    dispatch(loginActions.signOut())
    localStorage.removeItem(AUTHORIZATION_TOKEN)
    clearDataFromIndexedDb('authorization-token')
  }

  const onLogoutCancel = () => setIsModalOpen(false)

  const onLogoutButtonClick = () => {
    setIsModalOpen(true)
    setIsMenuOpen(false)
    setIsSubmenuOpen(false)
    isNavbarLinkSubmenuActive.current = false
  }

  useEffect(() => {
    const matchRoute =
      !!nodes?.length &&
      nodes?.some((node) => node.navName === pathname.substring(1))
    if (matchRoute) {
      setIsSubmenuOpen(true)
      isNavbarLinkSubmenuActive.current = true

      return
    }

    setIsSubmenuOpen(false)
    isNavbarLinkSubmenuActive.current = false
  }, [nodes, pathname])

  const navbarLinkWithNodes = useMemo(
    () => (nodes ? 'navbarLinkWithNodes' : ''),
    [nodes]
  )
  const navbarLinkWithNodesOpened = useMemo(
    () => (!!nodes && isSubmenuOpen ? 'navbarLinkWithNodesOpened' : ''),
    [nodes, isSubmenuOpen]
  )
  const navbarLinkActive = useCallback(
    (isActive: boolean) => (isActive ? 'navbarLinkActive' : ''),
    []
  )

  const navbarLinkClass = joinData(
    [
      navbarLinkWithNodes,
      navbarLinkWithNodesOpened,
      navbarLinkActive(isNavbarLinkSubmenuActive.current),
    ],
    ' '
  )

  return {
    isModalOpen,
    isSubmenuOpen,
    onNavbarLinkClick,
    onNavbarLinkSubmenuClick,
    onLogoutConfirm,
    onLogoutCancel,
    onLogoutButtonClick,
    navbarLinkClass,
  }
}
