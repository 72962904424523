import { api, getAuthHeader } from '../utils/api'
import { IRequest, TApiRoutes } from '../interfaces/IRequest'
import { API_PATHS } from '../enums/apiPaths'
import {
  IContractForm,
  IDestructionCertificateForm,
  IReclamationForm,
} from '../interfaces/IContracts'
import {
  addDataInIndexedDb,
  asyncForEach,
  openIndexedDb,
} from '../utils/offline'
import { API_VERSION, BASE_BACKEND_URL, INITIAL_OFFSET } from '../enums/common'
import { createContractsQuery } from '../utils/contracts'
import { TSendEmailData } from '../interfaces/ICommonComponents'
import { CONTENT_TYPE_APP_PDF } from '../constants/api'

export const getContractHistoryData = async (
  clientId?: number
): Promise<any> => {
  const urlParam = clientId ? `?clientId=${clientId}` : ''
  const params: IRequest = {
    endpoint: `${API_PATHS.contractTypes}${urlParam}`,
  }

  const {
    body: { currentYear, history },
  }: any = await api(params)

  return { currentYear, history }
}

export const getContractTypes = async (clientId?: number): Promise<any> => {
  const urlParam = clientId ? `?clientId=${clientId}` : ''
  const params: IRequest = {
    endpoint: `${API_PATHS.contractTypes}${urlParam}`,
  }

  const {
    body: { data },
  }: any = await api(params)

  return data
}

export const getReclamations = async (clientId?: number) => {
  const urlParam = clientId ? `?clientId=${clientId}` : ''
  const params: IRequest = {
    endpoint: `${API_PATHS.reclamations}${urlParam}`,
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export const getDestructionCertificates = async (clientId?: number) => {
  const urlParam = clientId ? `?clientId=${clientId}` : ''
  const params: IRequest = {
    endpoint: `${API_PATHS.destructionCertificates}${urlParam}`,
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export const createNewReclamation = (formData: IReclamationForm) => {
  const params: IRequest = {
    endpoint: API_PATHS.reclamations,
    method: 'POST',
    headers: getAuthHeader(),
    body: JSON.stringify(formData),
  }

  return api(params)
}

/**
 * Returns contracts for a specific a year.
 *
 * Usage:
 ** for current year contracts: getContracts(contractId, new Date().getFullYear(), MAX_ENGAGEMENTS, clientId)
 ** for historic contracts: getContracts(contractId, year, MAX_ENGAGEMENTS, clientId)
 *
 * @param contractID
 * @param year
 * @param size
 * @param clientId
 * @param offset
 * @return {data, totalCount}
 */
export const getContracts = async (
  contractID: number,
  year: number,
  size: number,
  clientId?: number,
  offset: number = INITIAL_OFFSET
) => {
  const query = createContractsQuery(year, offset, size)

  const urlParam = clientId ? `?clientId=${clientId}&` : '?'
  const params: IRequest = {
    endpoint: `${API_PATHS.contracts}/${contractID}${urlParam}${query}`,
  }

  const {
    body: { data, totalCount },
  } = await api(params)

  return { data, totalCount }
}

export const downloadForm = (
  id: number,
  route: TApiRoutes = API_PATHS.contracts
) => {
  const params: IRequest = {
    endpoint: `${route}/${id}/generate`,
  }

  return api(params, CONTENT_TYPE_APP_PDF, true)
}

export const deleteForm = (
  id: string,
  route: TApiRoutes = API_PATHS.contracts
) => {
  const params: IRequest = {
    endpoint: `${route}/${id}`,
    method: 'DELETE',
  }

  return api(params)
}

export const getContractForm = async (
  contractTypeId: number,
  clientId: number
): Promise<IContractForm> => {
  const params: IRequest = {
    endpoint: `${API_PATHS.contractTypes}/${contractTypeId}${API_PATHS.form}/${clientId}`,
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export const createNewContract = async (formData: any) => {
  const params: IRequest = {
    endpoint: API_PATHS.contracts,
    method: 'POST',
    body: JSON.stringify(formData),
  }

  return api(params)
}

export const sendEmail = async (emailData: TSendEmailData) => {
  const params: IRequest = {
    endpoint: API_PATHS.sendEmail,
    method: 'POST',
    body: JSON.stringify(emailData),
  }

  return api(params)
}

export const handleNewContractAddToIndexedDb = (
  data: any,
  key: 'new-contract-background-sync' | 'new-contract-emails',
  timestamp: number,
  tempId: string | number,
  apiPath: API_PATHS
) => {
  if ('serviceWorker' in navigator && 'SyncManager' in window) {
    navigator.serviceWorker.ready.then(() => {
      addDataInIndexedDb(
        key,
        {
          data,
          timestamp,
          tempId,
          requestData: {
            url: `${BASE_BACKEND_URL}${API_VERSION}${apiPath}`,
            method: 'POST',
          },
        },
        () => {},
        tempId
      )
    })
  }
}

export const handleDeleteContractEmails = (contractTimestamp: string) => {
  const onSuccess = async (dataEmails: any, objectStore: any) => {
    if (!dataEmails?.length) return

    await asyncForEach(dataEmails, async (data: any) => {
      if (!data?.tempId.toString().startsWith(contractTimestamp.toString()))
        return

      objectStore.delete(data?.tempId)
    })
  }

  openIndexedDb('new-contract-emails', onSuccess)
}

export const createNewDestructionCertificate = (
  formData: IDestructionCertificateForm
) => {
  const params: IRequest = {
    endpoint: API_PATHS.destructionCertificates,
    method: 'POST',
    headers: getAuthHeader(),
    body: JSON.stringify(formData),
  }

  return api(params)
}
