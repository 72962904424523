import React, { FC, useMemo } from 'react'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { IClientEventsSection } from '../../interfaces/IClientDetails'
import { STATUS, NEXT_VISIT_REPORT_DATE_FORMAT } from '../../enums/common'
import { ROUTES } from '../../enums/routes'
import Loader from '../loader/Loader'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ClientEventsList from './ClientEventsList'
import {
  EVENT_SECTION_APPOINTMENTS_COUNT,
  EVENT_SECTION_DCR_CALLS_COUNT,
} from '../../constants/visitReport'

export const ClientEventsSection: FC<IClientEventsSection> = ({
  clientAppointments,
  clientLastOrder,
  clientId,
  cachedClientAppointments = [],
  cachedClientReports = [],
  clientNextAppointment,
  clientDcrCalls,
}) => {
  const extraPadding = `${clientDcrCalls.total > 3 ? 'extraPadding' : ''}`
  const { data: clientLastOrderDate } = clientLastOrder

  const isDataLoadPending = useMemo(
    () =>
      clientAppointments.status === STATUS.PENDING ||
      clientDcrCalls.status === STATUS.PENDING ||
      clientLastOrder.status === STATUS.PENDING,
    [clientAppointments.status, clientDcrCalls.status, clientLastOrder.status]
  )

  const isDataLoadSuccess = useMemo(
    () =>
      clientAppointments.status === STATUS.SUCCESS &&
      clientDcrCalls.status === STATUS.SUCCESS &&
      clientLastOrder.status === STATUS.SUCCESS,
    [clientAppointments.status, clientDcrCalls.status, clientLastOrder.status]
  )

  const isClientNextAppointmentLoaded = useMemo(
    () => clientNextAppointment && clientNextAppointment !== undefined,
    [clientNextAppointment]
  )

  const nextRdv =
    isClientNextAppointmentLoaded &&
    clientNextAppointment &&
    clientNextAppointment !== undefined &&
    clientNextAppointment?.clientNextAppointment[0]?.startDate
      ? format(
          utcToZonedTime(
            new Date(
              clientNextAppointment?.clientNextAppointment[0]?.startDate
            ),
            'UTC'
          ),
          NEXT_VISIT_REPORT_DATE_FORMAT
        )
      : null

  return (
    <div className="clientDetailsPageEventsListWrapper hasBorderRadius withBackgroundColorDefault px15 py15">
      <DynamicFormattedMessage
        id="events.section.title.label"
        tag="p"
        className="mb1 clientDetailsPageEventsSectionTitle"
      />
      {isDataLoadSuccess && (
        <div>
          <div className="clientDetailsPastEvents px15 pt15 pb1 dFlex">
            <DynamicFormattedMessage
              id={`events.section.${
                clientLastOrderDate ? 'past.events' : 'no.data'
              }.label`}
              tag="p"
              className="mr1 mb1"
            />
            {clientLastOrderDate && (
              <span className="fontMedium"> {clientLastOrderDate} </span>
            )}
          </div>
          {nextRdv ? (
            <div className="clientDetailsNextVisitReport px15 pb1 dFlex">
              <DynamicFormattedMessage
                id="events.section.nextVisitReport.label"
                tag="p"
                className="mr1 mb1"
              />
              <span className="fontMedium">{nextRdv}</span>
            </div>
          ) : (
            <div className="clientDetailsNextVisitReport px15 pb1 dFlex">
              <DynamicFormattedMessage
                id="events.section.nextVisitReportMissing.label"
                tag="p"
                className="mr1 mb1"
              />
            </div>
          )}
        </div>
      )}
      <div
        className={`clientDetailsPageEventsContent ${extraPadding} ${
          isDataLoadPending ? 'loading' : ''
        }`}
      >
        {isDataLoadSuccess && (
          <>
            <ClientEventsList
              data={clientAppointments.clientAppointmentsData}
              total={clientAppointments.total}
              clientId={clientId}
              path={ROUTES.viewAppointment}
              cachedData={cachedClientAppointments}
              customClass="futureEvent"
              itemsToBeDisplayed={EVENT_SECTION_APPOINTMENTS_COUNT}
            />
            <ClientEventsList
              data={clientDcrCalls.clientDcrCallsData}
              total={clientDcrCalls.total}
              clientId={clientId}
              path={ROUTES.viewVisitReport}
              cachedData={cachedClientReports}
              itemsToBeDisplayed={EVENT_SECTION_DCR_CALLS_COUNT}
            />

            <DynamicFormattedMessage
              shouldRender={
                clientDcrCalls.total === 0 && clientAppointments.total === 0
              }
              id="clientDetails.events.missingEvents"
            />
          </>
        )}
        {isDataLoadPending && <Loader />}
      </div>
    </div>
  )
}
