import { generatePath, Link, useLocation } from 'react-router-dom'
import React, { FC } from 'react'
import { SendEmailSection } from '../common/ui/SendEmailSection'
import {
  KAM_EMAIL,
  SEND_EMAIL_API_TYPE_APPOINTMENT_ICS,
} from '../../enums/common'
import { DownloadButton } from '../common/ui/DownloadButton'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ROUTES } from '../../enums/routes'
import Button from '../common/ui/Button'
import { IClientAppointmentActionButtons } from '../../interfaces/IAppointments'

export const ClientAppointmentActionButtons: FC<IClientAppointmentActionButtons> = ({
  appointmentId,
  clientId,
  isAppointmentInTheFuture,
  isDownloadStatePending,
  handleDownloadClick,
  handleDeleteAppointmentButtonClick,
  setSendEmailState,
  setAvailableEmails,
  sendEmailContentData,
  hasNetwork,
}) => {
  const { state } = useLocation()

  return (
    <div className="dFlex justifyContentMdEnd alignItemsStart flexWrap flexMdNowrap flexGrow px15">
      <div
        className={`row mx0 mb1 ${!clientId ? 'appointmentDataError' : 'mr2'}`}
      >
        <SendEmailSection
          entityId={appointmentId}
          sendEmailType={SEND_EMAIL_API_TYPE_APPOINTMENT_ICS}
          inputEmailKey={KAM_EMAIL}
          setSendEmailState={setSendEmailState}
          setAvailableEmails={setAvailableEmails}
          contentData={sendEmailContentData}
        />
        <DownloadButton
          disabled={isDownloadStatePending || !hasNetwork}
          loading={isDownloadStatePending}
          onClick={handleDownloadClick}
        />
      </div>
      {clientId && (
        <DynamicFormattedMessage
          id="cta.clientPage.label"
          tag={Link}
          className="btn btnPrimary linkUnstyled py15 mb1 mb0sm fontMedium wFull wAutosm"
          data-qa="viewUserPage"
          to={generatePath(ROUTES.clientDetails, {
            clientId,
          })}
        />
      )}
      <div className="row mx0">
        <DynamicFormattedMessage
          id="cta.editAppointment.label"
          tag={Link}
          to={{
            pathname: generatePath(ROUTES.editAppointment, {
              appointmentId,
            }),
            state: {
              prevLocation: window.location.pathname,
              prevState: state,
            },
          }}
          className="btn btnPrimary py15 ml2sm mb1 mb0sm fontMedium wFull wAutosm"
          data-qa="editAppointment"
        />
        {isAppointmentInTheFuture && (
          <DynamicFormattedMessage
            id="cta.deleteAppointment.label"
            onClick={handleDeleteAppointmentButtonClick}
            tag={Button}
            className="btn btnDanger btnDelete ml15sm py15 pl3 fontMedium"
            data-qa="deleteAppointment"
          />
        )}
      </div>
    </div>
  )
}
