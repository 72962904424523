import React, { ReactElement, KeyboardEvent, useMemo } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import Button from '../common/ui/Button'
import {
  IAdvancedSearchFormData,
  TModalFilters,
} from '../../interfaces/IFilters'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import TextInput from '../form/TextInput'
import FormGroupDate from '../form/FormGroupDate'
import {
  ON_KEY_PRESS,
  RANGE_LABEL,
  YES_NO_OPTIONS,
  FILTERS_RADIO_OPTIONS_LIST,
} from '../../constants/form'
import MultipleSelect from '../form/MultipleSelect'
import DynamicButtons from '../form/DynamicButtons'
import Checkbox from '../form/Checkbox'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../enums/common'
import { useFiltersHook } from '../../hooks/accountsPortfolio/UseFiltersHook'
import { ADVANCED_SEARCH_SELECT_INPUT_FIELDS } from '../../constants/filters'
import { FILTER_CLIENT, FILTER_CLIENT_YEARS } from '../../constants/dashboard'
import {
  currentYearValue,
  getDynamicButtonDefaultValue,
} from '../../utils/helpers'

export const FiltersModal = ({
  setFilters,
  changePage,
  filters,
  toggleModal,
  fieldsData,
}: TModalFilters<typeof ADVANCED_SEARCH_SELECT_INPUT_FIELDS>): ReactElement => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm<IAdvancedSearchFormData>({
    mode: 'onChange',
  })

  const {
    groupIds: groupements,
    potential1Ids: potentiel,
    targetType1Ids: type1,
    targetType2Ids: type2,
    targetType3Ids: type3,
  } = fieldsData

  const {
    setIsFocused,
    isDisabled,
    currentYear,
    lastVisitDate,
    setLastVisitDate,
    onSubmit,
    resetFilters,
    formKey,
  } = useFiltersHook(
    { setFilters, filters, toggleModal, changePage, fieldsData },
    () => {
      setValue('groupIds', [])
      setLastVisitDate({
        startDate: '',
        endDate: '',
      })
    }
  )

  const startDate = useMemo(() => {
    return {
      id: 'lastVisitStart',
      value: lastVisitDate.startDate ? new Date(lastVisitDate.startDate) : '',
      label: 'form.field.lastVisit',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerLeft',
      maxDate: new Date(),
      control,
      error: errors.lastVisitStart,
    }
  }, [
    lastVisitDate.startDate,
    errors.lastVisitStart,
    control,
    setLastVisitDate,
  ])

  const endDate = useMemo(() => {
    return {
      id: 'lastVisitEnd',
      value: lastVisitDate.endDate ? new Date(lastVisitDate.endDate) : '',
      setValue: setLastVisitDate,
      classes: 'inputField inputDate datepickerRight',
      control,
      error: errors.lastVisitEnd,
    }
  }, [lastVisitDate.endDate, errors.lastVisitEnd, control, setLastVisitDate])

  const triggerSubmit = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ON_KEY_PRESS && !e.shiftKey) {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <div onKeyUp={triggerSubmit}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="row modalFiltersForm"
        onReset={() => resetFilters()}
        key={formKey}
      >
        <div className="px1 mb15 colMd4">
          <TextInput
            name="name"
            id="name"
            register={register({ required: false, maxLength: 80 })}
            error={errors.name}
            placeholder="typeIn"
            defaultValue={filters?.name}
          />
        </div>
        <div className="px1 mb15 colMd5">
          <TextInput
            name="address"
            id="address"
            register={register({ required: false, maxLength: 100 })}
            error={errors.address}
            placeholder="typeIn"
            defaultValue={filters?.address}
          />
        </div>
        <div className="px1 mb15 colMd3">
          <TextInput
            name="zipCode"
            id="zipCode"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
              minLength: {
                value: 2,
                message: 'number.minLength',
              },
              maxLength: {
                value: 5,
                message: 'number.maxLength',
              },
              min: {
                value: 0,
                message: 'number',
              },
              max: {
                value: 99999,
                message: 'number.maxLength',
              },
            })}
            error={errors.zipCode}
            placeholder="typeIn"
            defaultValue={filters?.zipCode}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="city"
            id="city"
            register={register({ required: false, maxLength: 80 })}
            error={errors.city}
            placeholder="typeIn"
            defaultValue={filters?.city}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="phoneNumber"
            id="phoneNumber"
            register={register({
              required: false,
              pattern: {
                value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                message: 'phoneNumber',
              },
            })}
            error={errors.phoneNumber}
            placeholder="typeIn"
            defaultValue={filters?.phoneNumber}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <MultipleSelect
            id="groupIds"
            control={control}
            error={errors.groupIds as FieldError | undefined}
            options={groupements.data}
            defaultValue={filters?.groupIds || []}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <FormGroupDate
            startDate={startDate}
            endDate={endDate}
            startDateOnly={true}
            isClearable={true}
          />
        </div>
        <div className="px1 colMd4 relative inputRange">
          <TextInput
            name="range"
            id="range"
            register={register({
              required: false,
              pattern: {
                value: /^[0-9]*$/,
                message: 'number',
              },
            })}
            error={errors.range}
            placeholder="typeIn"
            defaultValue={filters?.range}
            onFocus={() => setIsFocused(true)}
            disabled={isDisabled}
            customLabel={RANGE_LABEL}
            hasInfoLabel
          />
        </div>
        <div className="px1 mb15 colMd4">
          <TextInput
            name="cipCode"
            id="cipCode"
            register={register({ required: false, maxLength: 80 })}
            error={errors.cipCode}
            placeholder="typeIn"
            defaultValue={filters?.cipCode}
          />
        </div>
        <div className="px1 mb3 colMd4">
          <MultipleSelect
            id="targetType2Ids"
            control={control}
            error={errors.targetType2Ids as FieldError | undefined}
            options={type2.data}
            defaultValue={filters?.targetType2Ids || []}
            labelValues={{ year: currentYear }}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb3 colMd4">
          <MultipleSelect
            id="targetType1Ids"
            control={control}
            error={errors.targetType1Ids as FieldError | undefined}
            options={type1.data}
            defaultValue={filters?.targetType1Ids || []}
            labelValues={{ year: currentYear - 1 }}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb3 colMd4">
          <MultipleSelect
            id="targetType3Ids"
            control={control}
            error={errors.targetType3Ids as FieldError | undefined}
            options={type3.data}
            defaultValue={filters?.targetType3Ids || []}
            inModalContainer={true}
          />
        </div>
        <div className="px1 mb15 colMd4">
          <MultipleSelect
            id="potential1Ids"
            control={control}
            error={errors.potential1Ids as FieldError | undefined}
            options={potentiel.data}
            defaultValue={filters?.potential1Ids || []}
            inModalContainer={true}
          />
        </div>
        <div className="pl1 pr0 mb15 colMd2">
          <DynamicButtons
            optionsArray={YES_NO_OPTIONS}
            register={register}
            name="qualification2"
            error={errors.qualification2}
            defaultValue={getDynamicButtonDefaultValue(filters?.qualification2)}
            labelValues={{ year: currentYear - 1 }}
            inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
            setValue={setValue}
          />
        </div>
        <div className="pl1 pr0 mb15 colMd2">
          <DynamicButtons
            optionsArray={YES_NO_OPTIONS}
            register={register}
            name="qualification1"
            error={errors.qualification1}
            defaultValue={getDynamicButtonDefaultValue(filters?.qualification1)}
            labelValues={{ year: currentYear }}
            inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
            setValue={setValue}
          />
        </div>
        <div className="px1 mb15 mt25 colMd4">
          <Checkbox
            name="noAppointments"
            id="noAppointments"
            register={register}
            defaultValue={filters?.noAppointments}
          />
        </div>
        <div className="row wFull mx0">
          {FILTER_CLIENT_YEARS.map((filterClient, index) => (
            <DynamicButtons
              key={filterClient}
              optionsArray={FILTERS_RADIO_OPTIONS_LIST}
              register={register({ required: false })}
              error={errors[filterClient]}
              name={filterClient}
              defaultValue={getDynamicButtonDefaultValue(
                filters![filterClient]
              )}
              labelName={FILTER_CLIENT}
              labelValues={{ year: currentYearValue - index }}
              customClass="colMd4 colSm6 px1 mt15 mb3"
              inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
              setValue={setValue}
            />
          ))}
        </div>
        <div className="col12 modalFiltersCtaRow px1">
          <div className="modalFiltersCtaWrapper">
            <Button
              onClick={toggleModal}
              type="submit"
              className="btn btnOutline textUppercase"
              data-qa="cancelFilters"
            >
              <DynamicFormattedMessage id="form.button.cancel" />
            </Button>
          </div>
          <div className="modalFiltersCtaWrapper">
            <Button
              type="reset"
              className="btn btnLink mr3"
              data-qa="resetFilters"
            >
              <DynamicFormattedMessage id="form.button.resetFilters" />
            </Button>
            <Button
              type="submit"
              className="btn btnPrimary textUppercase"
              data-qa="submitAdvancedFilters"
            >
              <DynamicFormattedMessage id="form.button.submit" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
