import { STATUS } from '../enums/common'
import { TApiResponse } from '../interfaces/IClientDetails'

export const GET_USER_DETAIL_ERROR_MESSAGE =
  'Attention. Une erreur est survenue !'

export const LOGIN_ERROR_MESSAGE = 'Vos données de connexion sont incorrectes'
export const AUTHORIZATION_TOKEN = 'Authorization'
export const CALENDAR_INITIAL_VIEW = 'calendarInitialView'
export const CALENDAR_INITIAL_EVENT_TYPE = 'calendarEventType'

export const DATA_INITIAL_STATE: TApiResponse = {
  status: STATUS.IDLE,
  message: '',
}

export const CONTENT_TYPE_APP_PDF = 'application/pdf'
export const CONTENT_TYPE_APP_JSON = 'application/json'
