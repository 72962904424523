import React, { ReactElement } from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { OptionsType, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import ErrorMessage from './ErrorMessage'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ISelectOption } from '../../interfaces/IFilters'

function ClientsSelect({
  name,
  options,
  control,
  clientId,
  error,
  handleSelectChange,
  getOptions,
  disabled,
  noOptionsPlaceholder,
}: {
  name: string
  options: ISelectOption[]
  control: Control<Record<string, any>>
  clientId?: number
  error?: FieldError | undefined
  handleSelectChange: (selectedValue: string) => void
  getOptions: (
    query: string,
    callBack: (parameter: OptionsType<ISelectOption>) => void
  ) => void
  disabled?: boolean
  noOptionsPlaceholder?: string
}): ReactElement {
  const intl = useIntl()

  return (
    <div className="inputGroup">
      <DynamicFormattedMessage
        id={`form.field.${name}.label`}
        className="inputLabel"
      />
      <Controller
        control={control}
        name={name}
        defaultValue={clientId?.toString()}
        rules={{ required: true }}
        render={({ onChange }) => (
          <AsyncSelect
            id={name}
            cacheOptions
            defaultOptions={options}
            placeholder={
              disabled
                ? noOptionsPlaceholder
                : intl.formatMessage({
                    id: 'search.placeholder',
                  })
            }
            defaultValue={
              options.filter(
                (option) => option.value === `${clientId}`
              ) as ValueType<any, false>
            }
            loadOptions={getOptions}
            onChange={(selectedValue: ValueType<ISelectOption, false>) => {
              onChange(selectedValue?.value)
              handleSelectChange(selectedValue?.value!)
            }}
            className="customSearchInput"
            classNamePrefix="inputSearchField"
            components={{
              IndicatorSeparator: () => null,
            }}
            loadingMessage={() =>
              intl.formatMessage({ id: 'search.loading.message' })
            }
            isDisabled={disabled}
            noOptionsMessage={() =>
              intl.formatMessage({ id: 'search.no.options' })
            }
          />
        )}
      />
      <ErrorMessage error={error} />
    </div>
  )
}

export default ClientsSelect
