import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  IVisitReportForm,
  TImportFilesSection,
} from '../../../../interfaces/IVisitReports'
import { PHOTO_FILE_NAME } from '../../../../enums/common'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import { CustomImageUpload } from '../../../form/CustomImageUpload'
import { PhotoCapture } from '../../../form/PhotoCapture'
import { VISIT_REPORT_IMAGE_LIST } from '../../../../constants/visitReport'
import { newVisitReportActions } from '../../../../store/reducers/newVisitReportReducer'
import { convertFormDatetime } from '../../../../utils/visitReports'
import { ImagePlaceholder } from '../../../common/ui/ImagePlaceholder'

export const VisitReportImportFileSection: FC<TImportFilesSection> = ({
  camera,
  defaultData,
  isCameraOpen,
  images,
  updateForm,
  isMandatoryError,
  clearError,
}) => {
  const dispatch = useDispatch()
  const { register, getValues } = useFormContext()
  const { photoRef, hasUserMedia, grantedPermission, isVideoPlaying } = camera

  return (
    <>
      {VISIT_REPORT_IMAGE_LIST.map((imgKey) => {
        const imageValue = getValues(imgKey)
        const defaultValue = defaultData[imgKey]
          ? `${defaultData[imgKey as keyof IVisitReportForm]}`
          : JSON.stringify({
              keepOld: images![imgKey]?.content,
            })
        const image = imageValue?.length && JSON.parse(imageValue)
        const isImgDisabled =
          (image?.fileName && image?.fileName !== PHOTO_FILE_NAME) ||
          !!image?.keepOld ||
          !hasUserMedia ||
          !grantedPermission ||
          !isVideoPlaying

        return (
          <div className="row mb2" key={imgKey}>
            <div className="col12">
              <input
                type="hidden"
                name={imgKey}
                id={imgKey}
                ref={register}
                defaultValue={defaultValue}
              />
              <DynamicFormattedMessage
                id={`imageType.${imgKey}.label`}
                tag="h3"
                className="textPrimary fontBold textUppercase mb15"
              />
              <div className="row mx0">
                <div className="colMd5">
                  <CustomImageUpload
                    photoPath=""
                    type={imgKey}
                    key={imgKey}
                    updateForm={updateForm}
                    isDisabled={
                      image?.fileName && image?.fileName === PHOTO_FILE_NAME
                    }
                    showUploadBtn={!isImgDisabled}
                    onUploadCallback={() => {
                      dispatch(
                        newVisitReportActions.setNewVisitReport({
                          ...convertFormDatetime(
                            getValues() as IVisitReportForm
                          ),
                        })
                      )
                    }}
                    isMandatoryError={isMandatoryError}
                    clearError={clearError}
                  />
                  <PhotoCapture
                    type={imgKey}
                    isCameraOpen={isCameraOpen}
                    photoRef={photoRef}
                    isDisabled={isImgDisabled}
                    onPhotoUpdateCallback={() => {
                      dispatch(
                        newVisitReportActions.setNewVisitReport({
                          ...convertFormDatetime(
                            getValues() as IVisitReportForm
                          ),
                        })
                      )
                    }}
                  />
                </div>
                {!image?.dataURL ? (
                  <div className="colMd7">
                    <ImagePlaceholder />
                  </div>
                ) : (
                  <div className="customImageUploadItem row colMd7 justifyContentCenter">
                    <div className="customImageUploadImagePreview colMd6">
                      <img src={image?.dataURL} alt="Capture Preview" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
