import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TabPanel } from 'react-tabs'
import { ValidationErrors } from 'final-form'
import { FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { NEW_VISIT_REPORT_TABS } from '../../constants/tabs'
import { VisitReportFirstPanel } from './newVisitReportComponents/VisitReportFirstPanel'
import { VisitReportSecondPanel } from './newVisitReportComponents/VisitReportSecondPanel'
import { VisitReportThirdPanel } from './newVisitReportComponents/VisitReportThirdPanel'
import { CustomTabs } from '../common/ui/CustomTabs'
import { useVisitReportFormHook } from '../../hooks/clientVisitReport/UseVisitReportForm'
import Alerts from '../alerts/Alerts'
import { newVisitReportSelectors } from '../../store/selectors'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import {
  convertFormDatetime,
  getActiveTab,
  preparePlvListOptions,
} from '../../utils/visitReports'
import { useLoadData } from '../../hooks/UseLoadData'
import {
  getClientLastVisitReportImages,
  getPlvList,
} from '../../services/visitReportsService'
import { VisitReportFormNav } from '../clientVisitReport/VisitReportFormContent'
import Loader from '../loader/Loader'
import { getClientLastVisitReport } from '../../store/selectors/clientLastVisitReportSelectors'
import { getPlvListValue } from '../../store/selectors/sharedDataSelectors'
import { StickyWrapper } from '../common/ui/StickyWrapper'

function VisitReportForm({
  clientId,
  setIsModalOpen,
}: {
  clientId: number
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}): ReactElement {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const defaultData = useSelector(
    newVisitReportSelectors.getNewVisitReportValues
  )
  const { data } = useLoadData(() => getClientLastVisitReportImages(clientId), {
    fetchFromRedux: false,
    reduxSelector: getClientLastVisitReport,
    reduxStorePath: clientId.toString(),
  })

  const loadedData = useMemo(() => data && Object.keys(data).length !== 0, [
    data,
  ])

  const { data: plvData } = useLoadData(() => getPlvList(), {
    initialData: [],
    fetchFromRedux: true,
    reduxSelector: getPlvListValue,
  })
  const plvOptions = useMemo(
    () => preparePlvListOptions(plvData),
    // eslint-disable-next-line
    [plvData]
  )

  const [visitDate, setVisitDate] = useState({
    startDate: defaultData.visitDate,
  })
  const [activeTab, setActiveTab] = useState(0)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [formIsChanged, setFormIsChanged] = useState(false)
  const [errorField, setErrorField] = useState({
    field: '',
    triggerFocus: true,
  })
  const [isMandatoryImageError, setIsMandatoryImageError] = useState(false)
  // eslint-disable-next-line
  const [hasDuplicateErrors, setHasDuplicateErrors] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const handleDuplicateImageError = (hasDuplicateError: boolean) => {
    setHasDuplicateErrors(hasDuplicateError)
  }

  const methods = useVisitReportFormHook(
    clientId,
    setVisitDate,
    setIsModalOpen,
    state,
    data
  )
  const {
    form: { handleSubmit, getValues },
    formState: { status, message },
    onSubmit,
  } = methods

  const setActiveTabOnError = (errors: ValidationErrors) => {
    setErrorField(({ triggerFocus }) => ({
      field: Object.keys(errors)[0],
      triggerFocus: !triggerFocus,
    }))
  }

  const storeFormData = () => {
    dispatch(
      newVisitReportActions.setNewVisitReport({
        ...convertFormDatetime(getValues()),
      })
    )
  }

  const updateForm = () => {
    setFormIsChanged(!formIsChanged)
  }

  const handleClearMandatoryImageError = () => {
    setIsMandatoryImageError(false)
  }

  useEffect(() => {
    setActiveTab(getActiveTab(errorField.field))
  }, [errorField.field, errorField.triggerFocus])

  useEffect(() => {
    if (disabled) {
      setIsMandatoryImageError(false)
    }
  }, [disabled])

  return (
    <CustomTabs
      tabList={NEW_VISIT_REPORT_TABS}
      activeTab={activeTab}
      onClick={storeFormData}
      setActiveTab={setActiveTab}
      isDisabled={disabled}
    >
      <FormProvider {...methods.form}>
        <form
          onSubmit={handleSubmit(onSubmit, setActiveTabOnError)}
          className="newVisitReportForm col dFlex py25 px25"
          onChange={() => {
            updateForm()
          }}
        >
          <StickyWrapper customClass="px0">
            <Alerts status={status} message={message} />
          </StickyWrapper>
          <TabPanel forceRender={true}>
            <VisitReportFirstPanel
              clientId={clientId}
              defaultData={defaultData}
              visitDate={visitDate}
              setVisitDate={setVisitDate}
              setDisabled={setDisabled}
            />
          </TabPanel>
          <TabPanel forceRender={true}>
            <VisitReportSecondPanel
              isDisabled={disabled}
              defaultData={defaultData}
              plvOptions={plvOptions}
            />
          </TabPanel>
          <TabPanel forceRender={true}>
            {loadedData ? (
              <VisitReportThirdPanel
                defaultData={defaultData}
                images={data}
                updateForm={updateForm}
                clientId={clientId}
                isMandatoryError={isMandatoryImageError}
                clearError={handleClearMandatoryImageError}
                handleDuplicateImageError={handleDuplicateImageError}
                setIsSubmitDisabled={setIsSubmitDisabled}
              />
            ) : (
              <Loader />
            )}
          </TabPanel>
          <VisitReportFormNav
            status={status}
            images={data}
            formIsChanged={formIsChanged}
            setIsModalOpen={setIsModalOpen}
            isSubmitDisabled={isSubmitDisabled}
            setIsSubmitDisabled={setIsSubmitDisabled}
          />
        </form>
      </FormProvider>
    </CustomTabs>
  )
}

export default VisitReportForm
