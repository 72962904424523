import * as img from '../assets/images/images'
import { INavLinkItemProps } from '../interfaces/INavigation'
import { ROUTES } from '../enums/routes'

export const DASHBOARD = 'dashboard'
export const DASHBOARD_CA = 'ca'
export const DASHBOARD_VISITS_COVERAGE = 'visitsCoverage'
export const AGENDA = 'agenda'
export const MAP = 'map'
export const ACCOUNTS_PORTFOLIO = 'accountsPortfolio'
export const NEW_APPOINTMENT = 'newAppointment'
export const FORMS = 'forms'
export const LOGOUT = 'logout'
export const NEW_VISIT_REPORT = 'newVisitReport'
export const HISTORY = 'history'
export const NEW_RECLAMATION = 'newReclamation'
export const NEW_CONTRACT = 'newContract'
export const RECLAMATIONS = 'reclamations'
export const OFFLINE_SYNCHRO = 'offline-synchro'
export const NEW_DESTRUCTION_CERTIFICATE = 'newDestructionCertificate'

export const NAVBAR_LINKS: INavLinkItemProps[] = [
  {
    navName: DASHBOARD,
    imgClosed: img.iconDashboard,
    imgOpened: img.iconDashboardWhite,
    nodes: [
      {
        navName: DASHBOARD,
      },
      {
        navName: DASHBOARD_CA,
      },
      {
        navName: DASHBOARD_VISITS_COVERAGE,
      },
    ],
  },
  {
    navName: AGENDA,
    imgClosed: img.iconCalendar,
    imgOpened: img.iconCalendarWhite,
  },
  {
    navName: MAP,
    imgClosed: img.iconMap,
    imgOpened: img.iconMapWhite,
  },
  {
    navName: ACCOUNTS_PORTFOLIO,
    imgClosed: img.iconFolder,
    imgOpened: img.iconFolderWhite,
    query: 'page=1',
  },
  {
    navName: FORMS,
    imgClosed: img.iconText,
    imgOpened: img.iconTextWhite,
  },
  {
    navName: OFFLINE_SYNCHRO,
    imgClosed: img.iconOfflineSynchro,
    imgOpened: img.iconOfflineSynchroWhite,
  },
  {
    navName: LOGOUT,
    imgClosed: img.iconLogout,
    imgOpened: img.iconLogout,
    isLogout: true,
  },
]

/**
 * add any route that should be blurred in this array
 */
export const BLURRED_ROUTES = [
  ROUTES.dashboardCa,
  ROUTES.dashboardVisitsCoverage,
]
