import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { HISTORY_ANNUAL_INITIAL_STATE_TOTAL } from '../../__mocks__/historyMock'
import { TYPE_SUMMARY, VIEW_TYPE } from '../../constants/tables'
import {
  IHistoryAnnualTotal,
  IHistoryTableRow,
  IRecapHistoryRafQty,
  THistoryViewType,
} from '../../interfaces/IHistory'
import {
  getHistoryTotalPerViewType,
  getRafQtyReduxPath,
} from '../../utils/history'
import { IStore } from '../../store'
import { getClientHistoryValue } from '../../store/selectors/clientHistorySelectors'

export const useHistoryTotal = (
  data: IHistoryTableRow[],
  clientId: number,
  viewType: THistoryViewType = VIEW_TYPE.TOTAL,
  initialState: IHistoryAnnualTotal = HISTORY_ANNUAL_INITIAL_STATE_TOTAL
) => {
  const summaryData = useMemo(
    () =>
      data?.length ? data?.filter(({ type }) => type === TYPE_SUMMARY) : [],
    [data]
  )

  const rafQty = useSelector(
    (state: IStore) =>
      getClientHistoryValue(
        state,
        getRafQtyReduxPath(clientId)
      ) as IRecapHistoryRafQty[]
  )

  const totalRafQty = useMemo(
    () =>
      rafQty.reduce(
        (sum: number, currentVal: IRecapHistoryRafQty) =>
          sum + (currentVal.type === TYPE_SUMMARY ? currentVal.qty : 0),
        0
      ),
    [rafQty]
  )

  const summaryTotal = useMemo(
    () => getHistoryTotalPerViewType(summaryData, viewType, initialState),
    [summaryData, viewType, initialState]
  )

  return {
    rafQty,
    totalRafQty,
    summaryTotal,
  }
}
