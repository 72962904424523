import { IFilters } from '../interfaces/IFilters'
import {
  FILTER_CLIENT_CURRENT_YEAR,
  FILTER_CLIENT_PREVIOUS_YEAR,
} from './dashboard'

export const advancedFiltersInitialState: IFilters = {
  name: '',
  address: '',
  phoneNumber: '',
  groupIds: [],
  lastVisitStart: '',
  lastVisitEnd: '',
  range: '',
  qualification1: [],
  qualification2: [],
  cipCode: '',
  targetType1Ids: [],
  targetType2Ids: [],
  targetType3Ids: [],
  potential1Ids: [],
  zipCode: '',
  city: '',
  noAppointments: false,
  search: '',
  recentColaborationCode: [],
  previousColaborationCode: [],
}
// every field/key added for advancedFiltersInitialState needs to be added
// in ADVANCED_SEARCH_QUERY_MAPPING and correspondingly in
// ADVANCED_SEARCH_SELECT_INPUT_FIELDS and ADVANCED_SEARCH_INPUT_FIELDS

export const FILTER_NAME_FIELD = 'name'
export const FILTER_ADDRESS_FIELD = 'address'
export const FILTER_PHONE_NUMBER_FIELD = 'phoneNumber'
export const FILTER_LAST_VISIT_START_FIELD = 'lastVisitStart'
export const FILTER_LAST_VISIT_END_FIELD = 'lastVisitEnd'
export const FILTER_RANGE_FIELD = 'range'
export const FILTER_QUALIFICATION1_FIELD = 'qualification1'
export const FILTER_QUALIFICATION2_FIELD = 'qualification2'
export const FILTER_CIP_CODE_FIELD = 'cipCode'
export const FILTER_ZIPCODE_FIELD = 'zipCode'
export const FILTER_CITY_FIELD = 'city'
export const FILTER_GROUPMENT_FIELD = 'groupIds'
export const FILTER_CIBLE_2_FIELD = 'targetType2Ids'
export const FILTER_CIBLE_1_FIELD = 'targetType1Ids'
export const FILTER_STRATEGO_FIELD = 'targetType3Ids'
export const FILTER_POTENTIAL_FIELD = 'potential1Ids'
export const FILTER_APPOINTMENTS_FIELD = 'noAppointments'

export const FILTER_DEFAULT_SEARCH_FIELD = 'search'

export const PAGE_PARAM = 'page'

export const DEFAULT_COUNT_VALUE = -1

export const ADVANCED_SEARCH_QUERY_MAPPING = {
  page: PAGE_PARAM,
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  groupIds: FILTER_GROUPMENT_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  qualification1: FILTER_QUALIFICATION1_FIELD,
  qualification2: FILTER_QUALIFICATION2_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  targetType1Ids: FILTER_CIBLE_1_FIELD,
  targetType2Ids: FILTER_CIBLE_2_FIELD,
  targetType3Ids: FILTER_STRATEGO_FIELD,
  potential1Ids: FILTER_POTENTIAL_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  search: FILTER_DEFAULT_SEARCH_FIELD,
  recentColaborationCode: FILTER_CLIENT_CURRENT_YEAR,
  previousColaborationCode: FILTER_CLIENT_PREVIOUS_YEAR,
} as const

export const ADVANCED_SEARCH_SELECT_INPUT_FIELDS = {
  groupIds: FILTER_GROUPMENT_FIELD,
  targetType1Ids: FILTER_CIBLE_1_FIELD,
  targetType2Ids: FILTER_CIBLE_2_FIELD,
  targetType3Ids: FILTER_STRATEGO_FIELD,
  potential1Ids: FILTER_POTENTIAL_FIELD,
} as const

export const ADVANCED_SEARCH_INPUT_FIELDS = {
  name: FILTER_NAME_FIELD,
  address: FILTER_ADDRESS_FIELD,
  phoneNumber: FILTER_PHONE_NUMBER_FIELD,
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
  range: FILTER_RANGE_FIELD,
  qualification1: FILTER_QUALIFICATION1_FIELD,
  qualification2: FILTER_QUALIFICATION2_FIELD,
  cipCode: FILTER_CIP_CODE_FIELD,
  zipCode: FILTER_ZIPCODE_FIELD,
  city: FILTER_CITY_FIELD,
  noAppointments: FILTER_APPOINTMENTS_FIELD,
  search: FILTER_DEFAULT_SEARCH_FIELD,
  recentColaborationCode: FILTER_CLIENT_CURRENT_YEAR,
  previousColaborationCode: FILTER_CLIENT_PREVIOUS_YEAR,
}

export const ADVANCED_SEARCH_DATE_FIELDS = {
  lastVisitStart: FILTER_LAST_VISIT_START_FIELD,
  lastVisitEnd: FILTER_LAST_VISIT_END_FIELD,
}

export const ADVANCED_FILTERS_MODAL_FORM_KEY = 'advancedFiltersModalForm'

export const INPUT_SEARCH_ACCOUNTS_PORTFOLIO = 'simpleSearchName'

export const INPUT_SEARCH_MAP = 'simpleSearchText'
