import { useMemo } from 'react'
import { useLoadData } from '../UseLoadData'
import { getOptionsService } from '../../services/getClientsListService'
import { GROUPMENTS_ROUTE, STATUS } from '../../enums/common'

export const useGroupIds = () => {
  const groupIdsResponse = useLoadData(() =>
    getOptionsService(GROUPMENTS_ROUTE)
  )
  const groupIdsStatusPending = useMemo(
    () => groupIdsResponse.status === STATUS.PENDING,
    [groupIdsResponse.status]
  )

  return {
    groupIdsResponse,
    groupIdsStatusPending,
  }
}
