import { useDispatch } from 'react-redux'
import { FC, useEffect, useMemo, useState } from 'react'
import set from 'lodash.set'
import { VIEW_TYPE } from '../../constants/tables'
import { useLoadData } from '../UseLoadData'
import { getClientRecapHistory } from '../../services/historyService'
import { getClientHistoryValue } from '../../store/selectors/clientHistorySelectors'
import { processRecapHistoryTableData } from '../../utils/history'
import {
  IHistoryTableData,
  IHistoryTableRow,
  IRecapHistory,
} from '../../interfaces/IHistory'
import { SemesterTableContent } from '../../components/clientHistory/components/semesterTable/SemesterTableContent'
import { TrimesterTableContent } from '../../components/clientHistory/components/trimesterTable/TrimesterTableContent'
import { QuarterTableContent } from '../../components/clientHistory/components/quarterTable/QuarterTableContent'
import { STATUS } from '../../enums/common'
import { clientHistoryActions } from '../../store/reducers/clientHistoryReducer'

export const useHistoryRecap = (clientId: number) => {
  const dispatch = useDispatch()
  const [view, setView] = useState(VIEW_TYPE.TOTAL)
  const { data, status } = useLoadData(
    () => getClientRecapHistory(clientId, view),
    {
      dependencies: [view],
      fetchFromRedux: true,
      reduxSelector: getClientHistoryValue,
      reduxStorePath: `${clientId?.toString()}.${view}`,
    }
  )

  const tableRows = useMemo(
    () => processRecapHistoryTableData(data as IRecapHistory[]),
    [data]
  )
  const TableContent = useMemo<FC<IHistoryTableData> | null>(() => {
    if (view === VIEW_TYPE.SEMESTER) return SemesterTableContent
    if (view === VIEW_TYPE.TRIMESTER) return TrimesterTableContent
    if (view === VIEW_TYPE.QUARTERLY) return QuarterTableContent

    return null
  }, [view])

  const getRafQty = (rows: IHistoryTableRow[]) =>
    rows.map((item) => ({
      name: item.name,
      qty:
        (item.previousYear?.TOTAL1?.quantity || 0) -
        (item.currentYear?.TOTAL2?.quantity || 0),
      type: item.type,
    }))

  useEffect(() => {
    if (view === VIEW_TYPE.TOTAL && status === STATUS.SUCCESS) {
      const rafTotals = getRafQty(tableRows)
      const payload = set({}, `${clientId?.toString()}.rafQty`, rafTotals)
      dispatch(clientHistoryActions.setRafQty(payload))
    }
  }, [tableRows, view, clientId, dispatch, status])

  return {
    view,
    setView,
    tableRows,
    TableContent,
    status,
  }
}
