import React, { FC } from 'react'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import DynamicFormattedMessage from '../../components/common/ui/DynamicFormattedMessage'
import { ClientAppointmentData } from '../../components/clientAppointmentCard/ClientAppointmentData'
import { ROUTES } from '../../enums/routes'
import ModalContainer from '../../components/modal/Modal'
import { ConfirmationMessageModal } from '../../components/modal/ConfirmationMessageModal'
import { ErrorMessageModal } from '../../components/modal/ErrorMessageModal'
import { FILE_EXTENSION_ICS, STATUS } from '../../enums/common'
import Alerts from '../../components/alerts/Alerts'
import { useViewAppointment } from '../../hooks/clientAppointment/UseViewAppointment'
import { ClientAppointmentActionButtons } from '../../components/clientAppointmentCard/ClientAppointmentActionButtons'
import { InfoMessageAlerts } from '../../components/alerts/InfoMessageAlerts'
import { getFileExtension } from '../../utils/helpers'
import { StickyWrapper } from '../../components/common/ui/StickyWrapper'

const ViewAppointment: FC = () => {
  const {
    appointmentId,
    appointmentTypes,
    isModalOpen,
    isErrorModalOpen,
    closeErrorModal,
    deleteResponse,
    handleDeleteAppointmentButtonClick,
    onDeleteAppointmentConfirmation,
    onDeleteAppointmentCancel,
    clientAppointmentData,
    isClientAppointmentDataPending,
    isAppointmentInTheFuture,
    sendEmailState,
    setSendEmailState,
    availableEmails,
    setAvailableEmails,
    sendEmailContentData,
    downloadAppointmentState,
    handleDownloadClick,
    isDownloadStatePending,
    hasNetwork,
  } = useViewAppointment()

  return (
    <section className="pageContentWrapper viewAppointment pl75">
      <div className="px3 py3">
        <PageTitleSection
          backRoute={
            clientAppointmentData.status !== STATUS.DANGER
              ? ROUTES.agenda
              : ROUTES.dashboard
          }
        />
        <div className="pageTitleWrapper dFlex alignItemsCenter mb2 row">
          <div className="ml15 mb1">
            <h1 className="pageTitle">
              <DynamicFormattedMessage
                tag="span"
                id="page.newAppointment.title.label"
              />
            </h1>
          </div>
          {!isClientAppointmentDataPending && (
            <ClientAppointmentActionButtons
              appointmentId={+appointmentId}
              clientId={clientAppointmentData?.data?.client?.id}
              isAppointmentInTheFuture={isAppointmentInTheFuture}
              isDownloadStatePending={isDownloadStatePending}
              handleDownloadClick={handleDownloadClick}
              handleDeleteAppointmentButtonClick={
                handleDeleteAppointmentButtonClick
              }
              setSendEmailState={setSendEmailState}
              setAvailableEmails={setAvailableEmails}
              sendEmailContentData={sendEmailContentData}
              hasNetwork={hasNetwork}
            />
          )}
          <ModalContainer isOpen={isModalOpen}>
            <ConfirmationMessageModal
              onConfirm={onDeleteAppointmentConfirmation}
              backButton={onDeleteAppointmentCancel}
              messages={{
                cancel: 'cancelDelete',
                confirm: 'confirmDelete',
                title: 'titleDelete',
                content: 'contentDelete',
              }}
            />
          </ModalContainer>
          <ModalContainer
            isOpen={isErrorModalOpen}
            portalClassName="errorMessageModalWrapper"
          >
            <ErrorMessageModal closeModal={closeErrorModal} message="general" />
          </ModalContainer>
        </div>
        <StickyWrapper>
          <Alerts
            status={downloadAppointmentState.status}
            message={downloadAppointmentState.message}
          />
          <Alerts
            status={sendEmailState.status}
            message={sendEmailState.message}
          />
          {sendEmailState.status === STATUS.SUCCESS && (
            <InfoMessageAlerts
              message={`form.file.${sendEmailState.status}`}
              type={sendEmailState.status}
              data={availableEmails}
              intlAdditionalData={getFileExtension(FILE_EXTENSION_ICS)}
              flash={true}
            />
          )}
        </StickyWrapper>
        <ClientAppointmentData
          {...clientAppointmentData}
          deleteStatus={deleteResponse.status}
          appointmentTypes={appointmentTypes}
        />
      </div>
    </section>
  )
}

export default ViewAppointment
