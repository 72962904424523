import { IFieldWithDetails } from '../interfaces/IContracts'

export const CONTACT_PERSON_INPUT = 'contactPerson'
export const CUSTOM_CONTACT_PERSON_VALUE = 'customContactPerson'

export const FRONTLINE_ASSORTMENT_CONDITION =
  "Ristournes liées à une condition d'assortiment"

export const DIRECT_FRONTLINE_SELLOUT_REFERENCES = [
  'reference1',
  'reference2',
  'reference3',
  'reference4',
  'reference5',
  'reference6',
]
export const DISTRIBUTEUR_FRONTLINE_SELLOUT_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES
export const DISTRIBUTEUR_FRONTLINE_NOUVEAU_CLIENT_SELLOUT_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES
export const FRONTLINE_GROWTH_RELATED_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES
export const GROUPEMENT_DISTRIBUTEUR_FRONTLINE_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES
export const ELSIE_DIRECT_FRONTLINE_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES
export const ELSIE_DISTRIBUTEUR_FRONTLINE_REFERENCES = DIRECT_FRONTLINE_SELLOUT_REFERENCES

const DIRECT_FRONTLINE_LABEL = 'Direct Frontline'
const DISTRIBUTEUR_FRONTLINE_LABEL = 'Distributeur Frontline'
const DISTRIBUTEUR_FRONTLINE_NOUVEAU_CLIENT = 'Direct Frontline nouveau client'
const FRONTLINE_GROWTH_RELATED_LABEL = 'Groupement Direct Frontline'
const GROUPEMENT_DISTRIBUTEUR_FRONTLINE_LABEL =
  'Groupement Distributeur Frontline'
const ELSIE_DIRECT_FRONTLINE_LABEL = 'ELSIE Direct Frontline'
const ELSIE_DISTRIBUTEUR_FRONTLINE_LABEL = 'ELSIE Distributeur Frontline'

export const CONTRACT_FIELDS_WITH_DETAILS_SECTION: IFieldWithDetails = {
  [DIRECT_FRONTLINE_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: DIRECT_FRONTLINE_SELLOUT_REFERENCES,
      },
    ],
  },
  [DISTRIBUTEUR_FRONTLINE_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: DISTRIBUTEUR_FRONTLINE_SELLOUT_REFERENCES,
      },
    ],
  },
  [DISTRIBUTEUR_FRONTLINE_NOUVEAU_CLIENT]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: DISTRIBUTEUR_FRONTLINE_NOUVEAU_CLIENT_SELLOUT_REFERENCES,
      },
    ],
  },
  [FRONTLINE_GROWTH_RELATED_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: FRONTLINE_GROWTH_RELATED_REFERENCES,
      },
    ],
  },
  [GROUPEMENT_DISTRIBUTEUR_FRONTLINE_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: GROUPEMENT_DISTRIBUTEUR_FRONTLINE_REFERENCES,
      },
    ],
  },
  [ELSIE_DIRECT_FRONTLINE_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: ELSIE_DIRECT_FRONTLINE_REFERENCES,
      },
    ],
  },
  [ELSIE_DISTRIBUTEUR_FRONTLINE_LABEL]: {
    fields: [
      {
        fieldLabel: FRONTLINE_ASSORTMENT_CONDITION,
        buttonLabel: 'references',
        itemsList: ELSIE_DISTRIBUTEUR_FRONTLINE_REFERENCES,
      },
    ],
  },
}
