import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import FullCalendar from '@fullcalendar/react'
import { FullCalendarSection } from '../common/calendar/FullCalendarSection'
import { useCalendarHook } from '../../hooks/clientAgenda/UseCalendarHook'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import {
  EVENT_TYPE_RANDEZ_VOUS,
  EVENT_TYPE_VISIT_REPORT,
} from '../../enums/agenda'
import { IUsersOptions } from '../../interfaces/IUsers'
import { Tooltip } from '../common/Tooltip/Tooltip'
import { TooltipContent } from '../common/Tooltip/TooltipContent'
import { AGENDA } from '../../constants/routes'
import { AGENDA_LEGEND } from '../../enums/common'
import { statusNetworkSelector } from '../../store/selectors'
import Alerts from '../alerts/Alerts'
import { UsersSelect } from '../users/UsersSelect'
import { StickyWrapper } from '../common/ui/StickyWrapper'
import { useGetUsers } from '../../hooks/UseGetUsers'

export const AgendaSection: FC = () => {
  const { users, usersStatusPending, isUsersLoadingError } = useGetUsers()

  const {
    changeFilter,
    changeUser,
    calendarConfig,
    events,
    calendarRef,
    eventsType,
    eventsStatusPending,
    userId,
    updateCalendarView,
  } = useCalendarHook()

  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  return (
    <section className="mb2 calendarWrapper">
      <div className="row alignItemsCenter justifyContentBetween">
        <UsersSelect
          name="clientFilter"
          users={users?.data as IUsersOptions[]}
          onUserChange={changeUser}
          defaultUser={userId}
          disabled={!hasNetwork || eventsStatusPending || usersStatusPending}
          wrapperClass="colMd4 mb15"
          isUsersLoadingError={isUsersLoadingError}
        />
        <div className="colMd8 mb15 dFlex justifyContentEnd alignItemsCenter">
          <Tooltip title="calendar.filter.type">
            <TooltipContent route={AGENDA} data={AGENDA_LEGEND} />
          </Tooltip>
          <DynamicFormattedMessage
            id="calendar.filter.visitReport"
            data-qa="calendar.filter.visitReport"
            tag={Button}
            onClick={() => changeFilter(EVENT_TYPE_VISIT_REPORT)}
            className={`btn btnOutline hasBoxShadow btnFilterAppointment px1 py1 mr15 ${
              eventsType === EVENT_TYPE_VISIT_REPORT ? 'active' : ''
            }`}
          />
          <DynamicFormattedMessage
            id="calendar.filter.appointment"
            data-qa="calendar.filter.appointment"
            tag={Button}
            onClick={() => changeFilter(EVENT_TYPE_RANDEZ_VOUS)}
            className={`btn btnOutline hasBoxShadow btnFilterAppointment px1 py1 ${
              eventsType === EVENT_TYPE_RANDEZ_VOUS ? 'active' : ''
            }`}
          />
        </div>
      </div>
      <StickyWrapper>
        <Alerts status={events.status} message={events.message} />
      </StickyWrapper>
      <FullCalendarSection
        calendarConfig={{ ...calendarConfig }}
        events={events?.data}
        calendarRef={calendarRef as React.RefObject<FullCalendar>}
        eventsPending={usersStatusPending || eventsStatusPending}
        updateCalendarView={updateCalendarView}
      />
    </section>
  )
}
