import { Dispatch, SetStateAction, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { utcToZonedTime } from 'date-fns-tz'
import { STATUS } from '../enums/common'
import {
  IAppointmentFormParams,
  TNewAppointmentResponse,
} from '../interfaces/IAppointments'
import { prepareAppointmentRequest } from '../utils/appointments'
import {
  createNewAppointment,
  editAppointment,
} from '../services/appointmentsService'
import { ROUTES } from '../enums/routes'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../store/selectors'
import { handleError } from '../utils/api'
import { useInitiateAppointment } from './clientDetails/UseInitiateAppointment'
import { advancedFiltersInitialState } from '../constants/filters'

export const useNewAppointmentFormHook = (
  appointmentId: number,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const intl = useIntl()
  const form = useForm()
  const history = useHistory()
  const [formState, setFormState] = useState<TNewAppointmentResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const [isUnavailable, setIsUnavailable] = useState(false)
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const { allAppointments } = useInitiateAppointment(
    advancedFiltersInitialState
  )

  const resetIsUnavailable = () => {
    setIsUnavailable(false)
  }

  const onSubmit = async (values: any) => {
    const rdvStartDate = values.startDate
    const { duration } = values

    const rdvEndDate = new Date(rdvStartDate)

    rdvEndDate.setTime(
      rdvStartDate.getTime() +
        (duration === 0.5 ? 30 * 60 * 1000 : 60 * 60 * 1000)
    )

    const isOverlap = allAppointments.some((appointment) => {
      if (appointment.start && appointment.end) {
        const appointmentStart = utcToZonedTime(appointment.start, 'UTC')
        const appointmentEnd = utcToZonedTime(appointment.end, 'UTC')

        return rdvStartDate < appointmentEnd && rdvEndDate > appointmentStart
      }
      return false
    })

    if (isOverlap) {
      setIsUnavailable(true)
      return
    }

    setIsModalOpen(false)
    const body: IAppointmentFormParams = prepareAppointmentRequest(
      values,
      appointmentId
    )

    setFormState({ status: STATUS.PENDING, message: '' })
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      appointmentId
        ? await editAppointment(body, appointmentId)
        : await createNewAppointment(body)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      history.push(ROUTES.agenda, { date: values.startDate })
    } catch (e) {
      if (!hasNetwork) {
        dispatch(statusNetworkReducerActions.submitedForm())
        return history.push(ROUTES.agenda, { date: values.startDate })
      }

      const handledError = handleError(e, intl)
      setFormState(handledError)
    }
  }

  return { form, onSubmit, formState, isUnavailable, resetIsUnavailable }
}
