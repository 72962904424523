import React, { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { ISecondTab } from '../../../interfaces/IVisitReports'
import {
  NO_PLV,
  PROMOTION_MONTHS,
  VISIT_PROMOTION,
} from '../../../constants/visitReport'
import MultipleSelect from '../../form/MultipleSelect'
import DynamicButtons from '../../form/DynamicButtons'
import { OUI, RADIO_OPTIONS_LIST } from '../../../constants/form'
import { getDynamicButtonDefaultValue } from '../../../utils/helpers'
import { DYNAMIC_BUTTONS_INPUT_TYPE } from '../../../enums/common'
import SelectInput from '../../form/SelectInput'
import TextInput from '../../form/TextInput'

export const VisitReportSecondPanel: FC<ISecondTab> = ({
  isDisabled = false,
  defaultData,
  plvOptions,
}) => {
  const { register, setValue, watch, errors, control } = useFormContext()
  const noPlvOption = plvOptions.find(({ options }) =>
    options.find(({ label }) => label === NO_PLV)
  )?.options[0]

  const watchedFields = watch([VISIT_PROMOTION])

  const isVisible = useMemo(
    () => watchedFields.promotion && watchedFields.promotion.includes(OUI),
    [watchedFields.promotion]
  )

  return (
    <>
      <div className="row mb1 mx0 relative zIndex100">
        <div className="colMd6 dFlex alignItemsCenter">
          <DynamicFormattedMessage
            id="form.section.plv.title.label"
            tag="h3"
            className="textPrimary fontBold textUppercase"
          />
        </div>
        <div className="colMd6 dFlex justifyContentEnd">
          <MultipleSelect
            id="plvOptions"
            options={plvOptions}
            defaultValue={[]}
            singleValueOverride={noPlvOption}
            hideMultiRemove={false}
            showLabel={false}
            className="wFull"
            parentClassName="wFull"
            control={control}
            rules={{
              validate: {
                required: (value) =>
                  isDisabled || (!!Array.isArray(value) && !!value.length),
              },
            }}
            error={errors.plvOptions}
          />
        </div>
      </div>
      <div className="row mx0 mb1">
        <div className="colMd6 dFlex alignItemsCenter">
          <DynamicFormattedMessage
            id="form.field.promotion.label"
            className="textPrimary fontBold"
          />
        </div>
        <div className="colSm6 py05 dFlex justifyContentEnd">
          <DynamicButtons
            optionsArray={RADIO_OPTIONS_LIST}
            register={register({
              required: !isDisabled,
            })}
            name={VISIT_PROMOTION}
            customClass="noLabel noWrap actionButtons alignRight"
            error={errors.promotion}
            defaultValue={getDynamicButtonDefaultValue(defaultData?.promotion)}
            inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
            setValue={setValue}
          />
        </div>
      </div>
      {isVisible && (
        <div>
          <div className="row mx0 mb2">
            <div className="colMd6 dFlex alignItemsCenter">
              <DynamicFormattedMessage
                id="form.field.promotionMonth.label"
                className="textPrimary fontBold"
              />
            </div>
            <div className="colMd6 dFlex justifyContentEnd">
              <SelectInput
                id="promotionMonth"
                name="promotionMonth"
                options={PROMOTION_MONTHS}
                register={register({ required: isVisible })}
                error={errors.promotionMonth}
                defaultValue={
                  defaultData?.promotionMonth !== null
                    ? defaultData?.promotionMonth
                    : ''
                }
                renderLabel={false}
                defaultPlaceholder={false}
                parentClassName="wFull"
              />
            </div>
          </div>
          <div className="row mx0">
            <div className="colMd6 dFlex alignItemsCenter">
              <DynamicFormattedMessage
                id="form.field.previousSellOut.label"
                className="textPrimary fontBold mb15"
              />
            </div>
            <div className="colMd6 dFlex justifyContentEnd">
              <TextInput
                name="previousSellOut"
                id="previousSellout"
                type="number"
                register={register({
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'number',
                  },
                  required: false,
                })}
                placeholder="typeIn"
                error={errors.previousSellOut}
                hasLabel={false}
                customClass="wFull"
              />
            </div>
          </div>
          <div className="row mx0">
            <div className="colMd6 dFlex alignItemsCenter">
              <DynamicFormattedMessage
                id="form.field.currentSellOut.label"
                className="textPrimary fontBold mb15"
              />
            </div>
            <div className="colMd6 dFlex justifyContentEnd">
              <TextInput
                name="currentSellOut"
                id="currentSellout"
                type="number"
                register={register({
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'number',
                  },
                  required: false,
                })}
                placeholder="typeIn"
                error={errors.currentSellOut}
                hasLabel={false}
                customClass="wFull"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
