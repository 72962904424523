import React, { FC, useMemo } from 'react'
import { generatePath, Link, useLocation, useParams } from 'react-router-dom'
import { format } from 'date-fns-tz'
import { useSelector } from 'react-redux'
import { VISIT_REPORT_IMAGE_LIST } from '../../constants/visitReport'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { NEXT_VISIT_REPORT_DATE_FORMAT } from '../../enums/common'
import { useClientLastVisitReportImages } from '../../hooks/clientVisitReport/UseClientLastVisitReportImages'
import { statusNetworkSelector } from '../../store/selectors'

export const ClientLastVisitReportImagesSection: FC<{
  path: string
}> = ({ path }) => {
  const { clientId } = useParams()
  const { pathname } = useLocation()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const data = useClientLastVisitReportImages(clientId)

  const loadedData = useMemo(() => data && Object.keys(data).length !== 0, [
    data,
  ])

  const reportId = loadedData && data?.id ? data.id : ''

  const lastDate =
    loadedData && data?.creationDate && data?.creationDate.length > 0
      ? format(new Date(data.creationDate), NEXT_VISIT_REPORT_DATE_FORMAT)
      : ''

  const isWithoutImages = VISIT_REPORT_IMAGE_LIST.every(
    (photo) => data?.[photo]?.content === null
  )

  return (
    <div
      className={`px15 pt15 mt2 lastVisitReportImages ${
        isWithoutImages ? 'noVisitReportImages' : ''
      }`}
    >
      {loadedData && !isWithoutImages ? (
        <div className="row pt2 pb5 withBackgroundColorDefault">
          <p className="mb3 col12">
            <Link
              to={{
                pathname: generatePath(path, {
                  reportId,
                  clientId,
                }),
                state: { clientId, eventBackUrl: pathname },
              }}
              className={`linkUnstyled ${
                !hasNetwork ? 'notAllowed textLighterGrey' : ''
              }`}
              onClick={(e) => (!hasNetwork ? e.preventDefault() : () => {})}
            >
              <DynamicFormattedMessage
                id="viewReport.field.lastVisitReportDate.label"
                values={{ date: lastDate }}
                tag="span"
                className="textPrimary fontBold"
              />
            </Link>
          </p>
          {VISIT_REPORT_IMAGE_LIST.map((photo) => {
            const content = data?.[photo]?.content

            if (content !== null && content !== undefined) {
              return (
                <div className="imageContainer col4 textCenter" key={photo}>
                  <img src={content} alt={photo} />
                  <DynamicFormattedMessage
                    id={`viewReport.field.${photo}.label`}
                    tag="p"
                    className="textPrimary textCenter"
                  />
                </div>
              )
            }

            return null
          })}
        </div>
      ) : null}
    </div>
  )
}
