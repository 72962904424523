import { differenceInMinutes, endOfDay, startOfDay } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Dispatch, SetStateAction } from 'react'
import {
  CURRENT_DAY_DATETIME_FORMAT,
  NO_DATE,
  RANGE_INPUT_MINUTES,
  THIRTY_MIN,
  REPORT_DATETIME_FORMAT,
  TODAY_APPOINTMENT_TIME_FORMAT,
  WYSIWYG_INPUT_MAX_LENGTH,
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  WARNING,
  DANGER,
  STATUS_BAD_GATEWAY,
  CODE_1011,
  DATE_REQUEST_FORMAT,
  FILE_DOWNLOAD_TIME_FORMAT,
  MISSION_NAME,
  FILE_EXTENSION_PDF,
} from '../enums/common'
import { IUser } from '../interfaces/IUsers'
import {
  TDynamicButton,
  IFileDetails,
  IControlDownload,
} from '../interfaces/ICommonComponents'
import { APP_MONTH_INDEX } from '../constants/generalData'
import { IArrayOptions } from '../interfaces/IAppointments'
import { CONTENT_TYPE_APP_PDF } from '../constants/api'
import { OUI_OPTION_VALUE } from '../constants/form'

export const formatDate = (
  date: string | Date | null,
  dateFormat: string = CURRENT_DAY_DATETIME_FORMAT,
  replacement: string = NO_DATE,
  isHistoryPage?: boolean
) => {
  if (typeof date === 'string') {
    return date ? format(utcToZonedTime(date, 'UTC'), dateFormat) : replacement
  }

  if (isHistoryPage) {
    return date
      ? `<span class="fontBold">${format(
          date,
          REPORT_DATETIME_FORMAT
        )}</span> | ${format(date, TODAY_APPOINTMENT_TIME_FORMAT)}`
      : replacement
  }
  return date ? format(date, dateFormat) : replacement
}

export const toNumber = (value: string) => parseInt(value, 10)

export const roundTwoDecimals = (number: number) =>
  Math.round(number * 100) / 100

export const objEqual = (x: any, y: any): boolean => {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => objEqual(x[key], y[key]))
    : x === y
}

export const getFormattedString = (
  param: string | undefined,
  joinSeparator: string = '',
  splitSeparator: string = ' '
) => {
  if (!param) return ''

  return param
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f|&;$%@"<>()+,.]/g, '')
    .toLowerCase()
    .split(splitSeparator)
    .reduce((acc, el) => {
      acc.push(capitaliseString(el))
      return acc
    }, [] as string[])
    .join(joinSeparator)
}

export const filterPassedTime = (createdDate: Date) => {
  return new Date().getTime() - createdDate.getTime() > THIRTY_MIN
}

export const handleFileDownload = (url: string, fileName: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', fileName)
  element.setAttribute('target', '_blank')

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
}

export const isEmptyOrSpaces = (str: string | null) => {
  return str === null || str.match(/^ *$/) !== null
}

// use to handle html content with react dangerouslySetInnerHTML
export const handleHtmlContent = (content: string) => {
  return { __html: content }
}

export const defaultDate = (date: string) =>
  date ? new Date(date) : new Date()

export const dateInterval = (
  startDate: string | Date | null,
  endDate: string | Date | null
): number =>
  (startDate as Date) &&
  (endDate as Date) &&
  differenceInMinutes(endDate as Date, startDate as Date) / RANGE_INPUT_MINUTES

export const getEndDate = (startDate: Date, interval: number) =>
  startDate && new Date(startDate.getTime() + interval * (1000 * 60 * 60))

export const getClosingHours = (startDate: Date) => {
  const closingHours = (startDate as Date) && new Date(startDate)
  closingHours.setHours(21)
  closingHours.setMinutes(0)

  return closingHours
}

export const timeConvert = (number: number) => {
  const hours: number = Math.floor(number)
  const minutes: number = Math.round(number * 60) % 60
  const displayedHours = hours ? `${hours}h` : ''
  const displayedMinutes = minutes ? `${minutes}min` : ''

  return `${displayedHours}${displayedMinutes}`
}

export const joinData = (
  data: (string | undefined | null)[],
  separator?: string
) => {
  return data.filter((el) => !!el).join(`${separator || ','} `)
}

export const capitaliseString = (string: string) =>
  string[0].toUpperCase() + string.slice(1)

export const getAmountFromTotal = (total: number, value: number) =>
  Math.round((value * 100) / total)

export const isEmptyObj = (object: object) =>
  Object.keys(object).length === 0 && object.constructor === Object

export const isObjectEmpty = (obj: object) =>
  Object.getPrototypeOf(obj) === Object.prototype &&
  Object.keys(obj).length === 0

export const validateTextInput = (
  value: string,
  hasToBeValidated: boolean,
  inputMaxNo: number = WYSIWYG_INPUT_MAX_LENGTH
) => {
  if (hasToBeValidated && value?.length > inputMaxNo) return 'maxLength'
}

export const timezoneOffset = () => {
  return String(-new Date().getTimezoneOffset() / 60)
}

export const isDateInTheFuture = (date: string) =>
  new Date(date as string).getTime() > new Date().getTime()

export const getAlertType = (messageCode: string = '') =>
  messageCode === EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE ? WARNING : DANGER

export const isExternalStorageUploadFailed = (
  status: number,
  code: number
): boolean => status === +STATUS_BAD_GATEWAY && code === +CODE_1011

export const getSimpleRoute = (routeName: string, query?: string) =>
  `${routeName}${query ? `?${query}` : ''}`

export const getRoute = (routeName: string, query?: string) =>
  `/${routeName}${query ? `?${query}` : ''}`

export const getCurrentYear = (monthIndex: number = APP_MONTH_INDEX) => {
  const date = new Date()

  return date.getMonth() < monthIndex
    ? date.getFullYear()
    : date.getFullYear() + 1
}

export const getFirstDayOfCurrentYear = () => {
  const initialStartDate = new Date()
  initialStartDate.setMonth(0)
  initialStartDate.setDate(1)

  return initialStartDate
}

export const getStartOfDay = (date: string) =>
  format(startOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getEndOfDay = (date: string) =>
  format(endOfDay(new Date(date)), DATE_REQUEST_FORMAT)

export const getFullName = (
  user: Partial<IUser>,
  toUppercase: boolean = false
) =>
  toUppercase
    ? `${user.lastName?.toUpperCase()} ${user.firstName}`
    : `${user.lastName} ${user.firstName}`

export const getNWord = (string: string, index?: number) => {
  const getCamelCaseWords = string
    .replace(/([a-z](?=[A-Z]))/g, '$1 ')
    .split(' ')

  return index ? getCamelCaseWords[index] : getCamelCaseWords.join('')
}

export const currentYearValue = new Date().getFullYear()

export const currentDate = new Date()

export const currentBusinessYearStartingValue = new Date(
  currentDate.getFullYear(),
  9,
  1
)

export const getDynamicButtonDefaultValue = (
  field: TDynamicButton | string | undefined
) => {
  if (field && field === OUI_OPTION_VALUE) return field

  return field?.length ? field[0] : ''
}

export const matchOptionsValue = <T>(
  options: IArrayOptions[],
  optionToMatch: T
) =>
  options?.length
    ? options.find((option) => option.value === `${optionToMatch}`)
    : undefined

export const getFileForDownload = (
  responseBody: Blob,
  fileDetails: IFileDetails,
  setControlDownload: Dispatch<SetStateAction<IControlDownload>>
) => {
  const { creationDate, formName, clientName, cipCode } = fileDetails
  const file = new Blob([responseBody], {
    type: CONTENT_TYPE_APP_PDF,
  })
  const fileURL = URL.createObjectURL(file)

  const fileDate = formatDate(creationDate, FILE_DOWNLOAD_TIME_FORMAT)
  const fileClientName = clientName.split(' ').join('_')

  const fileName =
    joinData(
      [fileDate, MISSION_NAME, formName, cipCode, fileClientName],
      '-'
    ).replace(/\s/g, '') + FILE_EXTENSION_PDF

  setControlDownload({
    isDownloadModalOpen: true,
    url: fileURL,
    fileName,
  })
}

export const getFileExtension = (extension: string) =>
  extension.substring(1).toUpperCase()
