import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  clientContractsSendEmailSelectors,
  statusNetworkSelector,
} from '../../store/selectors'
import {
  deleteForm,
  getContracts,
  handleDeleteContractEmails,
  sendEmail,
} from '../../services/contractsServices'
import { clientContractsActions } from '../../store/reducers/clientContractsReducer'
import { deleteDataFromIndexedDb } from '../../utils/offline'
import { useDeleteHook } from '../UseDeleteHook'
import {
  getCurrentYear,
  getFileExtension,
  isEmptyObj,
} from '../../utils/helpers'
import {
  CONTRACTS_MONTH_INDEX,
  ENGAGEMENTS_PER_PAGE,
  FILE_EXTENSION_PDF,
  MAX_ENGAGEMENTS,
  STATUS,
} from '../../enums/common'
import { useLoadData } from '../UseLoadData'
import { getClientContractsData } from '../../store/selectors/clientContractsSelectors'
import { usePagination } from '../UsePagination'
import { TChangePage } from '../../interfaces/INavigation'
import { TApiResponse } from '../../interfaces/IClientDetails'
import { DATA_INITIAL_STATE } from '../../constants/api'
import { clientContractsSendEmailActions } from '../../store/reducers/clientContractsSendEmailReducer'
import { IControlDelete, IEmailData } from '../../interfaces/IContracts'
import { useMixedForms } from './UseMixedForms'

export const useCustomContract = () => {
  const pathName = window.location.pathname
  const isHistoryPage = pathName.includes('engagements-history')
  const { clientId, contractId, contractLabel, year } = useParams()
  const dispatch = useDispatch()
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [isFormEmailMessageVisible, setIsFormEmailMessageVisible] = useState(
    false
  )
  const [availableContractEmails, setAvailableContractEmails] = useState([''])
  const [controlDelete, setControlDelete] = useState<IControlDelete>({
    isModalOpen: false,
    contractId,
    isTimePassed: false,
    timestamp: 0,
  })

  const {
    mixedFormType,
    mixedFormTypeApiPath,
    mixedFormTypeNewFormRoute,
    mixedFormTypePath,
    mixedFormOnErrorActions,
    loadDataFunctionByFormType,
    setMixedFormDataByType,
  } = useMixedForms<any>(clientId, controlDelete)

  const deleteTypeOfContract = () => {
    if (!controlDelete.contractId) return Promise.reject()

    if (!contractId && !mixedFormTypeApiPath) return Promise.reject()

    return contractId
      ? deleteForm(controlDelete.contractId)
      : deleteForm(controlDelete.contractId, mixedFormTypeApiPath)
  }

  const onContractDeleteError = () => {
    if (controlDelete.contractId) {
      dispatch(
        clientContractsActions.deleteOldClientContract({
          clientId,
          contractTypeId: contractId,
          contractIdentifier: controlDelete.contractId,
        })
      )
    } else if (controlDelete.timestamp) {
      dispatch(
        clientContractsActions.deleteNewClientContract({
          clientId,
          contractTypeId: contractId,
          contractIdentifier: controlDelete.timestamp.toString(),
        })
      )
      deleteDataFromIndexedDb(
        'new-contract-background-sync',
        controlDelete.timestamp
      )
      handleDeleteContractEmails(controlDelete.timestamp.toString())
    }
  }

  const onReclamationDeleteError = () =>
    mixedFormOnErrorActions[mixedFormType!]()

  const { deleteResponse, requestDelete } = useDeleteHook(
    deleteTypeOfContract,
    {
      onSuccess: () => {},
      onError: () => {
        if (!hasNetwork) {
          if (contractId) {
            onContractDeleteError()
          } else {
            onReclamationDeleteError()
          }
        }
      },
    }
  )

  const contractsYear = useMemo(
    () => (isHistoryPage ? year : getCurrentYear(CONTRACTS_MONTH_INDEX)),
    [isHistoryPage, year]
  )

  const loadMixedFormData = useMemo(
    () => loadDataFunctionByFormType[mixedFormType!],
    [loadDataFunctionByFormType, mixedFormType]
  )

  const onLoadData = useCallback(() => {
    if (!contractId && !loadMixedFormData) {
      return
    }

    return contractId
      ? getContracts(contractId, contractsYear, MAX_ENGAGEMENTS, clientId)
      : loadMixedFormData(+clientId)
  }, [contractId, contractsYear, clientId, loadMixedFormData])

  const contractsList = useLoadData(onLoadData, {
    initialData: {},
    dependencies: [deleteResponse],
    fetchFromRedux: !!clientId && !isHistoryPage && !hasNetwork,
    storeInRedux: true,
    reduxSelector: getClientContractsData,
    reduxAction:
      contractId && !isHistoryPage
        ? clientContractsActions.setClientContracts
        : setMixedFormDataByType[mixedFormType!],
    reduxStorePath: contractId
      ? `${clientId}.contracts.${contractId}`
      : `${clientId}.${mixedFormTypePath}`,
  })

  const { data, status, message } = contractsList

  const { slicedData, currentPage, setCurrentPage } = usePagination(
    data?.data,
    ENGAGEMENTS_PER_PAGE
  )
  const changePage: TChangePage = (page) => setCurrentPage(page)

  // eslint-disable-next-line
  const contracts = useMemo(() => (contractId ? slicedData : (data as [])), [
    contractId,
    slicedData,
    data,
    currentPage,
  ])

  const [controlDownload, setControlDownload] = useState({
    isDownloadModalOpen: false,
    url: '',
    fileName: '',
  })
  const { isDownloadModalOpen, url, fileName } = controlDownload
  const [downloadFormState, setDownloadFormState] = useState<TApiResponse>(
    DATA_INITIAL_STATE
  )

  const [sendEmailState, setSendEmailState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
  })
  const [availableEmails, setAvailableEmails] = useState([''])
  const fileExtension = getFileExtension(FILE_EXTENSION_PDF)
  const { isModalOpen, isTimePassed } = controlDelete

  const dataSendEmail = useSelector(
    clientContractsSendEmailSelectors.getContractsSendEmailValues
  )

  const handleSendEmailFail = () => {
    setIsFormEmailMessageVisible(true)
    setEmailSuccess(false)
  }

  const handleSendEmailData = useCallback(async () => {
    await sendEmail({
      kamEmail: dataSendEmail?.kamEmail,
      dcrEmail: dataSendEmail?.dcrEmail,
      clientEmail: dataSendEmail?.clientEmail,
      type: dataSendEmail?.type,
      entityId: dataSendEmail?.entityId as number,
    })
      .then((response) => {
        if (response.body.success) {
          setIsFormEmailMessageVisible(true)
          setEmailSuccess(true)

          return
        }

        handleSendEmailFail()
      })
      .catch(() => {
        handleSendEmailFail()
      })
  }, [dataSendEmail])

  useEffect(() => {
    if (isEmptyObj(dataSendEmail)) return

    setAvailableContractEmails([
      dataSendEmail?.clientEmail,
      dataSendEmail?.kamEmail,
      dataSendEmail?.dcrEmail,
    ])
    handleSendEmailData().then(() =>
      dispatch(
        clientContractsSendEmailActions.setContractsSendEmailValues(
          {} as IEmailData
        )
      )
    )
  }, [handleSendEmailData, dataSendEmail, dispatch])

  const totalItemsCount = useMemo(
    () =>
      data?.totalCount <= MAX_ENGAGEMENTS ? data?.totalCount : MAX_ENGAGEMENTS,
    [data]
  )

  const isPaginationVisible = useMemo(
    () =>
      contractId &&
      !!contracts?.length &&
      totalItemsCount > ENGAGEMENTS_PER_PAGE,
    [contractId, contracts, totalItemsCount]
  )

  return {
    clientId,
    contractId,
    contractLabel,
    isHistoryPage,
    emailSuccess,
    currentPage,
    controlDelete,
    controlDownload,
    isFormEmailMessageVisible,
    availableContractEmails,
    setControlDelete,
    deleteTypeOfContract,
    onContractDeleteError,
    onReclamationDeleteError,
    deleteResponse,
    requestDelete,
    contractsYear,
    contractsList,
    status,
    message,
    changePage,
    contracts,
    setControlDownload,
    isDownloadModalOpen,
    url,
    fileName,
    downloadFormState,
    setDownloadFormState,
    sendEmailState,
    setSendEmailState,
    availableEmails,
    setAvailableEmails,
    fileExtension,
    isModalOpen,
    isTimePassed,
    handleSendEmailData,
    totalItemsCount,
    isPaginationVisible,
    mixedFormType,
    mixedFormTypePath,
    mixedFormTypeApiPath,
    mixedFormTypeNewFormRoute,
  }
}
