export const filterClientData = <T>(
  data: T,
  filteringList: (keyof T)[],
  shouldOmit: boolean
): (keyof T)[] =>
  (Object.keys(data) as (keyof T)[]).filter((item) => {
    if (shouldOmit) {
      return !filteringList.includes(item)
    }

    return filteringList.includes(item)
  })
