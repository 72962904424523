import { useIntl } from 'react-intl'
import React, { useMemo, useRef } from 'react'
import { ValueType } from 'react-select'
import { ISelectOption } from '../../interfaces/IFilters'

export const useMultipleSelect = (
  isMulti: boolean,
  inModalContainer: boolean,
  singleValueOverride?: ISelectOption,
  onSelectChange?: (values: ValueType<ISelectOption[], boolean>) => void
) => {
  const intl = useIntl()
  const containerRef = useRef(null)

  const handleOnChange = ({ onChange }: any) => (
    values: ValueType<ISelectOption | ISelectOption[], boolean>
  ) => {
    const isSingleValueOverride =
      isMulti &&
      Array.isArray(values) &&
      values.length &&
      values[values.length - 1].value === singleValueOverride?.value

    if (isSingleValueOverride) {
      onChange([singleValueOverride])
    } else {
      onChange(
        values && Array.isArray(values)
          ? values.filter(({ value }) => value !== singleValueOverride?.value)
          : values
      )
    }

    if (onSelectChange && values && Array.isArray(values)) {
      onSelectChange(values)
    }
  }

  /**
   * Use inModalContainer={true} for Multiselect if it's placed in a modal;
   * so in case the select is at the bottom of the modal will display the options menu properly.
   * Important:
   * As a default behavior the menuPlacement prop will work with auto and place the options menu top if there is not enough space at bottom,
   * but it's always in relation to the viewport
   */
  const additionalProps = useMemo(
    () =>
      inModalContainer
        ? {
            menuPortalTarget: document.body,
            styles: {
              menuPortal: (base: React.CSSProperties) => ({
                ...base,
                zIndex: 9999,
              }),
            },
            menuShouldScrollIntoView: false,
            closeMenuOnScroll: (event: any) =>
              event.target.contains(containerRef.current),
          }
        : {},
    [inModalContainer]
  )

  return { intl, handleOnChange, containerRef, additionalProps }
}
