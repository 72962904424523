import { useEffect, useState } from 'react'
import { TClientDetailsItem } from '../../interfaces/IClientDetails'
import { IMarkerType } from '../../interfaces/IMap'
import {
  mapMarker,
  mapMarkerRed,
  mapMarkerYellow,
  mapMarkerDarkBlue,
  mapMarkerDarkGreen,
} from '../../assets/images/images'

/**
 * Hook used to set the map marker type for each client.
 */

export const useMapMarker: (client: TClientDetailsItem) => IMarkerType = (
  client
) => {
  const [markerType, setMarkerType] = useState(mapMarkerYellow)

  useEffect(() => {
    const {
      nextAppointmentDate,
      todayAppointmentDate,
      hasPreviousOrderHistory,
    } = client

    let marker = mapMarker

    if (
      hasPreviousOrderHistory &&
      !todayAppointmentDate &&
      !nextAppointmentDate
    ) {
      marker = mapMarkerDarkGreen
    }

    if (todayAppointmentDate) {
      marker = mapMarkerRed
    }

    if (nextAppointmentDate && !todayAppointmentDate) {
      marker = mapMarkerYellow
    }

    if (
      !hasPreviousOrderHistory &&
      !todayAppointmentDate &&
      !nextAppointmentDate
    ) {
      marker = mapMarkerDarkBlue
    }

    setMarkerType(marker)
  }, [client])

  return { markerType }
}
