import {
  IClientAppointmentsData,
  IClientAppointmentsResponseData,
  IClientCalendarEvent,
  IClientCalendarEventResponse,
  IClientDetailsData,
  IClientDetailsInfoProps,
  IClientEventData,
  IClientReportsData,
  IClientReportsResponse,
  IClientReportsResponseData,
  ISelectClientDetails,
  IClientVisitReport,
  TClientDetailsItem,
  IClientDcrCallResponseData,
  IClientDcrCallsData,
} from '../interfaces/IClientDetails'
import { IUserActivity, IUsersResponse } from '../interfaces/IUsers'
import {
  IAppointmentTypesData,
  IAppointmentDefault,
  IAppointmentList,
  IArrayOptions,
} from '../interfaces/IAppointments'
import {
  ACTION_FORMULAIRES,
  ACTION_HISTORIQUE,
  ACTION_NOUVEAU_RAPPORT,
  ACTION_NOUVEAU_RENDEZ_VOUS,
  datesInitialState,
} from '../constants/userData'
import { STATUS } from '../enums/common'
import {
  IContractData,
  IContractHistoryData,
  ICustomContract,
  IReclamationFormFields,
} from '../interfaces/IContracts'
import {
  IEngagementHistory,
  IHistoryTableRow,
  IOrderHistory,
  IRecapHistory,
  IRecapProducts,
  IRecapSummary,
} from '../interfaces/IHistory'
import { IVisitReport, IVisitReportForm } from '../interfaces/IVisitReports'
import { IOfflineSynchroData } from '../interfaces/IOffline'
import { VISIT_REPORT_CURRENT_VERSION } from '../constants/visitReport'
import { ISelectOption } from '../interfaces/IFilters'
import { IResponseData } from '../interfaces/IResponse'
import {
  IDashboardCaFilters,
  ITurnoverData,
  IVisitsCoverageData,
} from '../interfaces/IDashboard'
import { IHandledError } from '../interfaces/ICommonComponents'
import {
  CUSTOM_COLOR_3,
  DEFAULT_COLOR,
  PRIMARY_COLOR,
} from '../constants/colors'
import { DATA_INITIAL_STATE } from '../constants/api'
import { prepareAppointmentTypesOptions } from '../utils/appointments'

export const mockClientDetailsData: IClientDetailsData = {
  id: 123,
  cipCode: '9999999',
  clientCode: 'FR9999999',
  name: 'Pharmacie Test',
  email: 'asd@asd.com',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  faxNumber: '0170989564',
  phoneAgentName: 'JEANBLANC Albane',
  phoneAgentComment: 'Lorem ipsum dolores',
  fieldAgentName: 'BOND James',
  fieldAgentComment: 'Lorem ipsum dolores',
  group: 'APRIUM PREMIUM',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  potentiel1: 'Lorem ipsum dolores',
  potentiel2: 'Lorem ipsum dolores',
  potentiel3: 'Lorem ipsum dolores',
  qualification1: 'Lorem ipsum dolores',
  qualification2: 'Lorem ipsum dolores',
  qualification3: 'Lorem ipsum dolores',
  targetType1: 'lorem',
  targetType2: 'lorem',
  targetType3: 'lorem',
  potential: 'ispum',
  kamEmail: 'kam@email.com',
  hasPreviousOrderHistory: false,
}

export const mockClientDetailsWithAppointmentsData1: TClientDetailsItem = {
  id: 124,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: null,
}

export const mockClientDetailsWithAppointmentsData2: TClientDetailsItem = {
  id: 123,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: null,
}

export const mockClientReport: IClientReportsResponse = {
  id: 10,
  date: '15.05.2021',
  eventType: 'type1',
  formationType: 'Formation Flash',
  outcome: 'Visite Sell-out',
  contactPerson: 'George',
}

export const mockProcessedClientReport: IClientEventData = {
  id: 10,
  startDate: '15.05.2021',
  eventType: 'type1',
  formationType: 'Formation Flash',
  outcome: 'Visite Sell-out',
  contactPerson: 'George',
}

export const mockClientInfo: IClientDetailsInfoProps = {
  name: '/albert einstein/i',
  address1: 'Rue des long pres',
  phoneNumber: '0170956400',
  cipCode: '123',
  contactPerson: [
    {
      name: 'Jon Snow',
      value: '12',
    },
  ],
  zipCode: '1234',
  gpsLatitude: 2.2137,
  gpsLongitude: 46.2276,
  city: 'Cluj-Napoca',
  clientFilteredAppointments: { total: 1 },
}

export const mockClientAppointmentsData: IClientAppointmentsData = {
  total: 1,
  data: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Visite argumentée',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockUserAppointmentsData: IClientCalendarEventResponse[] = [
  {
    id: 12,
    startDate: '2019-07-24T07:52:48.467Z',
    endDate: '2019-07-24T10:52:48.467Z',
    eventType: 'Visite argumentée',
    contactPerson: 'Muriel Gretchen',
    city: 'Paris',
    clientName: 'Jon Snow',
    cipCode: '1234',
    hasPreviousOrderHistory: true,
  },
  {
    id: 13,
    startDate: '2019-07-24T07:52:48.467Z',
    endDate: '2019-07-24T10:52:48.467Z',
    eventType: 'Visite argumentée',
    contactPerson: 'Tony Stark',
    city: 'London',
    clientName: 'James Bond',
    cipCode: '1234',
    hasPreviousOrderHistory: true,
  },
]

export const mockUserReportsData: IClientVisitReport[] = [
  {
    id: 12,
    startDate: '2019-07-24T07:52:48.467Z',
    eventType: 'Visite argumentée',
    city: 'Paris',
    clientName: 'Jon Snow',
    cipCode: '1234',
  },
  {
    id: 13,
    startDate: '2019-07-24T07:52:48.467Z',
    eventType: 'Visite argumentée',
    city: 'London',
    clientName: 'James Bond',
    cipCode: '1234',
  },
]

export const mockCalendarReportsData: IClientCalendarEvent[] = [
  {
    allDay: false,
    id: 12,
    start: '2019-07-24T07:52:48.467Z',
    title: 'Jon Snow (1234, Paris)',
    color: CUSTOM_COLOR_3,
    borderColor: CUSTOM_COLOR_3,
    textColor: DEFAULT_COLOR,
    end: '2019-07-24T07:52:48.467Z',
    extendedProps: {
      clientId: undefined,
    },
  },
  {
    allDay: false,
    id: 13,
    start: '2019-07-24T07:52:48.467Z',
    title: 'James Bond (1234, London)',
    color: CUSTOM_COLOR_3,
    borderColor: CUSTOM_COLOR_3,
    textColor: DEFAULT_COLOR,
    end: '2019-07-24T07:52:48.467Z',
    extendedProps: {
      clientId: undefined,
    },
  },
]

export const mockCalendarAppointmentsData: IClientCalendarEvent[] = [
  {
    allDay: false,
    end: '2019-07-24T10:52:48.467Z',
    id: 12,
    start: '2019-07-24T07:52:48.467Z',
    title: 'Jon Snow (1234, Paris)',
    color: PRIMARY_COLOR,
  },
  {
    allDay: false,
    end: '2019-07-24T10:52:48.467Z',
    id: 13,
    start: '2019-07-24T07:52:48.467Z',
    title: 'James Bond (1234, London)',
    color: PRIMARY_COLOR,
  },
]

export const mockAppointmentTypesData: IAppointmentTypesData = {
  total: 1,
  data: [
    {
      id: 1,
      eventType: 'Visite argumentée',
      isVisible: true,
    },
    {
      id: 2,
      eventType: 'Visite argumentée',
      isVisible: false,
    },
  ],
}

export const mockAppointmentTypesResponse: IResponseData<IArrayOptions[]> = {
  data: prepareAppointmentTypesOptions(mockAppointmentTypesData.data),
  status: STATUS.SUCCESS,
  message: '',
}

export const mockClientReportsData: IClientReportsData = {
  total: 1,
  data: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockAppointmentDefaultValues: IAppointmentDefault = {
  appointmentTypeId: 1,
  comment: '',
  userId: 3,
  prevLocation: '',
  offset: 0,
  currentPage: 1,
  id: 123,
  client: {
    id: 2,
    name: 'Catena',
    cipCode: 'cipCode123',
  },
  ...datesInitialState,
}

export const validAdminJWT: { authorization: string } = {
  authorization:
    'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTEyOTc2MzgsImV4cCI6MTYxMTMwMTIzOCwiaWQiOjMsInJvbGUiOiJST0xFX0FETUlOIn0.Ncnv533q-vfFhQVBDdxCF_b-XyuwQ5VoKXpsgDL-iF-SeOLZ_dq2ejp8cMNCBX_PsfdyJgZnZB0tMLLktir8SgHXPyJyfCXHbTQTu9Al3Gma8_f6QfVP1edLYGg97QEjTV9kYOx7vt_Zczooyg0_wt1FMjykY82Bcf3cNuNHrZs5AiP12bw49DXds31YOWLkig8UwP1opeNeFQZOjFp07rrm5G8wviz7DcnLqobF86Vj42y2iskFMdk9kgbdjRfulr9zj4PvXJgzSsshivgyOEtIkyrLfkf1ky1f4UZIS7lVbW1xcKgfxGPWERzgzCJY4wmBirLKIex9dudSVxJLg4Q_GW52IIy3Qvud8sK0QuTri5uA4qWHKqFU9GOpfZf0gcEi28Nv42m_ooie5GGBOo4-g1ku3YoLjUDFqylmtJdhgdvMCuBBrgWNF-Gom1YC-yUIFLa7ysUfTUeRx-PYUmuCdbVQOBfqVBuH3mJNJo4floiekGd9R37w00ozs5h1OTv9CnO2pw7n7QgVElB00pM3arMm_ePdE6IaqHTkkGpyPBOxsVU2oyvke2bh3HlmrP66LZGg0g0HtKhlwwhaahghKQq-r2n38hf-s8fXRjsHqKCdAa0JXl3dW_eZo3xUOASOUOTFVbbE9dqkvkdAtJ7gdlLJn1cCfZlDq5ht-mU',
}

export const validUserJWT: { authorization: string } = {
  authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTEyOTc3MzksImV4cCI6MTYxMTMwMTMzOSwiaWQiOjUsInJvbGUiOiJST0xFX1VTRVIifQ.vdxEGyPcjSDaCVRUPk-8RTMSTM0g08tKN3-cCDLPg8pI4oCE4UsTj-ONcOot0OBLPY_jKCJ6eBIhb6ibCk-NTG7n3T0uAuWgOYWkbarHsxcm5rWnFarBKmbkoza5zGQqZsG7c85t2xl2CZJhtmkHZwa_Nb3ZUJyh42g7QHy21N9A2cCYKFjnXOEqjJSZyEtJp_HJ3HQ_W8JQVO3c0yxRRclC4Zlvdv3ZFE1i3dkCxydFRA5JxU8HXalIHXK4j0SOTAZ1obyDqkwzMVh1D3JhA0Sy-atqCO0fXv4hzw_UvMDyCi1Ex-nATpI0ImIxdl6teOFBh_kPSacLdZeYwGaItwjlCD_WNLyHjErTteHemRvA9iRj6WU_fhs5ZHgZ9gtMKc4TgPzUmKZKcJn7tFM5wwm2txUMle9RMXcIspQopV1CFNORJYgAHnqKxdcIgKf_DksboJaPEulSIJq5Pens11KWzgvdSh4fVZut_kUQENJXF1ekyhMD1iG5SEH3b8_W-xjIV9cwPlC-KrMPFYA-2TmOeleoLnvxNQ0m_5KnYIsTyOXYCXslRI466vcyk2x9hz_Jbs5LFPHAK1R0aOdJW7-W1F1R0FSXLsNpkV_IqGWHM8ChV6a16IVM_cvQMvbe-5GI60-zFV7HKPKHaaHCJTBKARNrVGfsRjcoj68ntZ4`,
}

export const mockUsersList: IUsersResponse = {
  data: [
    {
      id: 2,
      firstName: 'Jane',
      lastName: 'Doe',
      username: 'doe',
    },
    {
      id: 3,
      firstName: 'James',
      lastName: 'Bond',
      username: 'bond',
    },
    {
      id: 4,
      firstName: 'Jon',
      lastName: 'Andrews',
      username: 'jon',
    },
    {
      id: 1,
      firstName: 'Daenerys',
      lastName: 'Targaryen',
      username: 'targaryen',
    },
  ],
}

export const mockClientDetailsItemData: TClientDetailsItem = {
  id: 123,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: '',
}

export const mockClientAppointmentsResponseData: IClientAppointmentsResponseData = {
  total: 1,
  status: STATUS.SUCCESS,
  clientAppointmentsData: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockClientReportsResponseData: IClientReportsResponseData = {
  total: 1,
  status: STATUS.SUCCESS,
  clientReportsData: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Anderson Silva',
    },
  ],
}

export const mockClientDcrCallResponseData: IClientDcrCallResponseData = {
  total: 1,
  status: STATUS.SUCCESS,
  clientDcrCallsData: [
    {
      resourceType: 'report',
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Anderson Silva',
      formationType: null,
      outcome: 'Prise de RDV',
      comment: '',
    },
  ],
}

export const mockClientDcrCallData: IClientDcrCallsData = {
  total: 1,
  data: [
    {
      resourceType: 'report',
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Muriel Gretchen',
      formationType: null,
      outcome: 'Prise de RDV',
      comment: '',
    },
  ],
}

export const mockSelectClientDetails: ISelectClientDetails = {
  id: 420,
  cipCode: '420',
  name: 'John Blazer',
}

export const mockOrderHistoryResponse: IOrderHistory[] = [
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 0,
  },
]

export const mockOrderHistoryTableData: IOrderHistory[] = [
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 0,
  },
]

export const mockEngagemenstHistoryResponse: IEngagementHistory[] = [
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
    progress: 0,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
    progress: 0,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
    progress: '2',
  },
]

export const mockEngagemenstHistoryTableData: IEngagementHistory[] = [
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
    progress: 0,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
    progress: 0,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
    progress: '2%',
  },
]

export const mockCurrentDayEvents: IAppointmentList[] = [
  {
    id: 12,
    startDate: '2021-02-15T10:00:00.467Z',
    endDate: '2021-02-15T11:00:00.467Z',
    contactPerson: 'Muriel Gretchen',
    eventType: 'Visite argumentée',
    clientName: 'Jon Snow',
    cipCode: '1234567',
    city: 'Paris',
    address1: 'Rue de Lavande no10',
    zipCode: '123456',
  },
  {
    id: 13,
    startDate: '2021-02-15T11:00:00.467Z',
    endDate: '2021-02-15T12:00:00.467Z',
    contactPerson: 'Tony Stark',
    eventType: 'Visite argumentée',
    clientName: 'James Bond',
    cipCode: '7654321',
    city: 'London',
    address1: 'Road to Heaven no29',
    zipCode: '123456',
  },
]

export const mockContractTypes: IContractData[] = [
  {
    id: 1,
    label: 'Direct Frontline',
    nrOfContracts: 2,
  },
  {
    id: 2,
    label: 'Direct Chevaux',
    nrOfContracts: 10,
  },
  {
    id: 3,
    label: 'Distributeur Frontline',
    nrOfContracts: 1,
  },
  {
    id: 4,
    label: 'Distributeur Chevaux',
    nrOfContracts: 6,
  },
  {
    id: 5,
    label: 'Groupement Direct Frontline',
    nrOfContracts: 2,
  },
  {
    id: 6,
    label: 'Groupement Distributeur Frontline',
    nrOfContracts: 8,
  },
  {
    id: 7,
    label: 'ELSIE Direct Frontline',
    nrOfContracts: 0,
  },
  {
    id: 8,
    label: 'Direct Frontline nouveau client',
    nrOfContracts: 15,
  },
  {
    id: 9,
    label: 'Distributeur Frontline nouveau client',
    nrOfContracts: 11,
  },
  {
    id: 10,
    label: 'Groupement Direct Frontline nouveau client',
    nrOfContracts: 23,
  },
  {
    id: 11,
    label: 'Groupement Distributeur Frontline nouveau client',
    nrOfContracts: 2,
  },
]

export const mockActivityReports: IUserActivity = {
  visitReportsSummary: {
    currentMonth: 2,
    currentTrimester: 2,
    currentYear: 256,
  },
  activity: [
    {
      month: 1,
      visits: 0,
      appointments: 0,
    },
    {
      month: 2,
      visits: 2,
      appointments: 2,
    },
    {
      month: 3,
      visits: 3,
      appointments: 3,
    },
    {
      month: 4,
      visits: 0,
      appointments: 0,
    },
    {
      month: 5,
      visits: 0,
      appointments: 0,
    },
    {
      month: 6,
      visits: 0,
      appointments: 0,
    },
    {
      month: 7,
      visits: 1,
      appointments: 1,
    },
    {
      month: 8,
      visits: 0,
      appointments: 0,
    },
    {
      month: 9,
      visits: 7,
      appointments: 7,
    },
    {
      month: 10,
      visits: 0,
      appointments: 0,
    },
    {
      month: 11,
      visits: 0,
      appointments: 0,
    },
    {
      month: 12,
      visits: 0,
      appointments: 0,
    },
  ],
}

export const mockNewVisitReportFormInitialState: IVisitReportForm = {
  userId: '',
  contactPersonId: '',
  contactOutcomeId: '',
  visitDate: '',
  duration: null,
  contactTypeId: null,
  numberOfPersons: null,
  training: null,
  training1: '',
  training2: '',
  comment: '',
  qualification13: [],
  qualification14: [],
  retrocession: [],
  promotion: [],
  // promotionMonth: '',
  // previousSellOut: null,
  // currentSellOut: null,
  plvOptions: [],
  photo1: '',
  photo2: '',
  photo3: '',
  version: VISIT_REPORT_CURRENT_VERSION,
}

const lastYear = new Date().getFullYear() - 1
const currentYear = new Date().getFullYear()

export const mockSummaryRecapHistoryResponse: IRecapSummary[] = [
  {
    year: lastYear,
    periods: [
      {
        name: 'TOTAL1',
        quantity: 1,
        value: 2.0,
      },
    ],
  },
  {
    year: currentYear,
    periods: [
      {
        name: 'TOTAL2',
        quantity: 1,
        value: 2.0,
      },
    ],
  },
]

export const mockProcessedSummaryData = [
  {
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
  },
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
  },
]

export const mockProductsRecapHistoryResponse: IRecapProducts[] = [
  {
    name: 'Product1',
    summary: [
      {
        year: lastYear,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
      {
        year: currentYear,
        periods: [
          {
            name: 'TOTAL2',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
  },
]

export const mockProcessedProductsData = [
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
]

export const mockAnnualHistoryResponse: IRecapHistory[] = [
  {
    name: 'ProductLine1',
    summary: [
      {
        year: lastYear,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
      {
        year: currentYear,
        periods: [
          {
            name: 'TOTAL2',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
    products: [
      {
        name: 'Product1',
        summary: [
          {
            year: lastYear,
            periods: [
              {
                name: 'TOTAL1',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
          {
            year: currentYear,
            periods: [
              {
                name: 'TOTAL2',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'ProductLine15',
    summary: [
      {
        year: 2020,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
    products: [
      {
        name: 'Product1',
        summary: [
          {
            year: 2020,
            periods: [
              {
                name: 'TOTAL1',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
        ],
      },
    ],
  },
]

export const mockProcessedAnnualHistoryReponse: IHistoryTableRow[] = [
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'ProductLine1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'summary',
  },
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
  {
    name: 'ProductLine15',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'summary',
  },
  {
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
]

export const mockGetContractTypeForm: any = {
  formId: 1,
  label: 'Direct Frontline',
  client: {
    id: 123,
    contactPerson: 'Muriel Gretchen',
    address1: 'Rue des long pres',
    address2: null,
    zipCode: '92100',
    city: 'BOULOGNE BILLANCOURT',
  },
  fields: [
    {
      id: 1,
      label: 'Furexel, Furexel combi',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '10% à partir de 150€',
          value: 1,
        },
        {
          name: '50% à partir de 1500€',
          value: 2,
        },
        {
          name: 'Non',
          value: 3,
        },
      ],
    },
    {
      id: 2,
      label: 'Eqvalan, Eqvalan duo',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '5% à partir de 550€',
          value: 1,
        },
        {
          name: '12% à partir de 3000€',
          value: 2,
        },
        {
          name: 'Non',
          value: 3,
        },
      ],
    },
    {
      id: 3,
      label: 'Gastrogard',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '5% à partir de 650€',
          value: 1,
        },
        {
          name: 'Non',
          value: 2,
        },
      ],
    },
  ],
}

export const mockReclamationFormValues: IReclamationFormFields = {
  age: '',
  agreementContactInfo: 'Non',
  agreementExtraDetails: '' || null,
  city: '',
  description: '',
  email: '',
  expirationDate: '',
  fax: '',
  fullName: '',
  gender: '' || null,
  incidentDate: '',
  initials: '',
  laboratory: '',
  lot: '',
  notifier: '' || null,
  officeName: '',
  other: '',
  patient: '',
  phone: '',
  postalCode: '',
  product: '',
  productCipCode: '',
  productConcerned: '',
  reclamationAddress: '',
  specialtyAvailable: '' || null,
  type: '' || null,
  signature: '',
}

export const mockOfflineSynchroData: IOfflineSynchroData[] = [
  {
    creationDate: '23.12.2020',
    fileName: '/dashboard',
    status: '200',
  },
  {
    creationDate: '23.12.2020',
    fileName: '/dashboard',
    status: '200',
  },
]

export const mockContractHistoryData: IContractHistoryData = {
  currentYear: {
    year: 2022,
    listContractTypes: [
      {
        id: 24,
        label: 'Direct Frontline',
        nrOfContracts: 97,
      },
      {
        id: 25,
        label: 'Direct Chevaux',
        nrOfContracts: 33,
      },
      {
        id: 26,
        label: 'Distributeur Frontline',
        nrOfContracts: 8,
      },
      {
        id: 27,
        label: 'Distributeur Chevaux',
        nrOfContracts: 15,
      },
      {
        id: 28,
        label: 'Groupement Direct Frontline',
        nrOfContracts: 6,
      },
      {
        id: 29,
        label: 'Groupement Distributeur Frontline',
        nrOfContracts: 6,
      },
      {
        id: 30,
        label: 'ELSIE Direct Frontline',
        nrOfContracts: 2,
      },
      {
        id: 31,
        label: 'Direct Frontline nouveau client',
        nrOfContracts: 3,
      },
      {
        id: 32,
        label: 'Distributeur Frontline nouveau client',
        nrOfContracts: 2,
      },
      {
        id: 35,
        label: 'ELSIE Distributeur Frontline',
        nrOfContracts: 3,
      },
    ],
  },
  history: [
    {
      year: 2021,
      listContractTypes: [
        {
          id: 24,
          label: 'Direct Frontline',
          nrOfContracts: 97,
        },
        {
          id: 25,
          label: 'Direct Chevaux',
          nrOfContracts: 33,
        },
        {
          id: 26,
          label: 'Distributeur Frontline',
          nrOfContracts: 8,
        },
      ],
    },
    {
      year: 2020,
      listContractTypes: [
        {
          id: 29,
          label: 'Groupement Distributeur Frontline',
          nrOfContracts: 6,
        },
        {
          id: 30,
          label: 'ELSIE Direct Frontline',
          nrOfContracts: 2,
        },
        {
          id: 31,
          label: 'Direct Frontline nouveau client',
          nrOfContracts: 3,
        },
      ],
    },
    {
      year: 2019,
      listContractTypes: [
        {
          id: 32,
          label: 'Distributeur Frontline nouveau client',
          nrOfContracts: 2,
        },
        {
          id: 35,
          label: 'ELSIE Distributeur Frontline',
          nrOfContracts: 3,
        },
      ],
    },
  ],
}

export const mockContractHistoryDataEmptyHistory: IContractHistoryData = {
  currentYear: {
    year: 2022,
    listContractTypes: [
      {
        id: 24,
        label: 'Direct Frontline',
        nrOfContracts: 97,
      },
      {
        id: 25,
        label: 'Direct Chevaux',
        nrOfContracts: 33,
      },
      {
        id: 26,
        label: 'Distributeur Frontline',
        nrOfContracts: 8,
      },
      {
        id: 27,
        label: 'Distributeur Chevaux',
        nrOfContracts: 15,
      },
      {
        id: 28,
        label: 'Groupement Direct Frontline',
        nrOfContracts: 6,
      },
      {
        id: 29,
        label: 'Groupement Distributeur Frontline',
        nrOfContracts: 6,
      },
      {
        id: 30,
        label: 'ELSIE Direct Frontline',
        nrOfContracts: 2,
      },
      {
        id: 31,
        label: 'Direct Frontline nouveau client',
        nrOfContracts: 3,
      },
      {
        id: 32,
        label: 'Distributeur Frontline nouveau client',
        nrOfContracts: 2,
      },
      {
        id: 35,
        label: 'ELSIE Distributeur Frontline',
        nrOfContracts: 3,
      },
    ],
  },
  history: [],
}

const mockVisitReportImage = {
  key: null,
  content: null,
}

export const mockVisitReport: IVisitReport = {
  id: 4,
  date: '2021-11-08',
  duration: '60',
  contactPerson: 'Levente 1',
  contactType: 'Visit',
  contactOutcome: 'Signature march\u00e9 a distance',
  numberOfTrainedPersons: null,
  comment: '',
  training1: 0,
  training2: 0,
  qualification1: 0,
  qualification2: 0,
  qualification4: false,
  qualification5: false,
  qualification6: false,
  qualification7: false,
  qualification8: false,
  qualification9: false,
  qualification10: false,
  qualification11: false,
  qualification12: false,
  qualification13: false,
  qualification14: false,
  retrocession: false,
  promotion: false,
  promotionMonth: 'january',
  previousSellOut: 0,
  currentSellOut: 0,
  qualification26: 0,
  plvOptions: [],
  photo1: mockVisitReportImage,
  photo2: mockVisitReportImage,
  photo3: mockVisitReportImage,
  version: 'vTesting',
}

export const contractDetailMock: ICustomContract = {
  cipCode: '2001439',
  contractDate: '2020-11-08',
  clientName: 'Richter Tudor 5001 Test Pharmacy',
  contractLabel: 'DirectFrontline',
  creationDate: '2019-01-20T00:00:00Z',
  dcrEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  id: 47,
  isDownloadModalOpen: false,
  isHistoryPage: true,
  kamEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  setControlDownload: () => {},
  downloadFormState: DATA_INITIAL_STATE,
  setDownloadFormState: () => {},
  setSendEmailState: () => {},
  setControlDelete: () => {},
  setAvailableEmails: () => {},
  deleted: false,
  isPdfGenerated: true,
}

export const contractDetailMockNoPdf: ICustomContract = {
  cipCode: '2001439',
  contractDate: '2020-11-08',
  clientName: 'Richter Tudor 5001 Test Pharmacy',
  contractLabel: 'DirectFrontline',
  creationDate: '2019-01-20T00:00:00Z',
  dcrEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  id: 47,
  isDownloadModalOpen: false,
  isHistoryPage: true,
  kamEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  setControlDownload: () => {},
  downloadFormState: DATA_INITIAL_STATE,
  setDownloadFormState: () => {},
  setSendEmailState: () => {},
  setControlDelete: () => {},
  setAvailableEmails: () => {},
  deleted: false,
  isPdfGenerated: false,
}

export const mockMarqueData: ISelectOption[] = [
  { value: '1', label: 'Marque 1' },
  { value: '2', label: 'Marque 2' },
  { value: '3', label: 'Marque 3' },
]

export const mockMarque: IResponseData<any> = {
  data: mockMarqueData,
  status: 'success',
  message: '',
}

export const mockAnyData: ISelectOption[] = []

export const mockResponseAnyData: IResponseData<any> = {
  data: mockAnyData,
  status: 'success',
  message: '',
}

export const turnoverMockData: ITurnoverData = {
  currentYear: [
    {
      month: 1,
      turnover: 20,
    },
    {
      month: 2,
      turnover: 35,
    },
    {
      month: 3,
      turnover: 15,
    },
    {
      month: 4,
      turnover: 40,
    },
    {
      month: 5,
      turnover: 21,
    },
    {
      month: 6,
      turnover: 18,
    },
    {
      month: 7,
      turnover: 20,
    },
    {
      month: 8,
      turnover: 60,
    },
    {
      month: 9,
      turnover: 45,
    },
    {
      month: 10,
      turnover: 23,
    },
    {
      month: 11,
      turnover: 50,
    },
    {
      month: 12,
      turnover: 20,
    },
  ],
  lastYear: [
    {
      month: 1,
      turnover: 20,
    },
    {
      month: 2,
      turnover: 15,
    },
    {
      month: 3,
      turnover: 25,
    },
    {
      month: 4,
      turnover: 12,
    },
    {
      month: 5,
      turnover: 45,
    },
    {
      month: 6,
      turnover: 65,
    },
    {
      month: 7,
      turnover: 85,
    },
    {
      month: 8,
      turnover: 40,
    },
    {
      month: 9,
      turnover: 25,
    },
    {
      month: 10,
      turnover: 16,
    },
    {
      month: 11,
      turnover: 35,
    },
    {
      month: 12,
      turnover: 37,
    },
  ],
}

export const usersResponseMockData: IResponseData<any> = {
  data: mockUsersList,
  status: 'success',
  message: '',
}

export const filtersMock: IDashboardCaFilters = {
  marque: [
    {
      value: '1',
      label: 'Marque 1',
    },
    {
      value: '2',
      label: 'Marque 2',
    },
    {
      value: '3',
      label: 'Marque 3',
    },
  ],
  targetType2Ids: [
    {
      value: '1',
      label: 'TC2-Lib',
    },
    {
      value: '4',
      label: 'Type1',
    },
    {
      value: '5',
      label: 'Type2',
    },
    {
      value: '6',
      label: 'Type3',
    },
  ],
  groupIds: [
    {
      value: '1',
      label: 'hgj',
    },
    {
      value: '2',
      label: 'Label2',
    },
    {
      value: '3',
      label: 'Label3',
    },
  ],
}

export const filtersMockSelection: IDashboardCaFilters = {
  marque: [
    {
      value: '1',
      label: 'Marque 1',
    },
  ],
  targetType2Ids: [
    {
      value: '1',
      label: 'TC2-Lib',
    },
  ],
  groupIds: [
    {
      value: '2',
      label: 'Label2',
    },
  ],
}

export const mockTargetType: IResponseData<any> = {
  data: filtersMock.targetType2Ids,
  status: 'success',
  message: '',
}

export const mockGroup: IResponseData<any> = {
  data: filtersMock.groupIds,
  status: 'success',
  message: '',
}

export const mockVisitsCoverageData: IVisitsCoverageData = {
  visitedPharmacies: 10,
  unvisitedPharmacies: 5,
  visits: 50,
  visitsFrequency: 5,
}

export const mockVisitsCoverageDataEmpty: IVisitsCoverageData = {
  visitedPharmacies: 0,
  unvisitedPharmacies: 0,
  visits: 0,
  visitsFrequency: 0,
}

export const mockVisitsCoverageDataPerType: IVisitsCoverageData = {
  visitedPharmacies: 25,
  unvisitedPharmacies: 10,
  visits: 100,
  visitsFrequency: 4,
}

export const mockFormState: IHandledError = {
  status: STATUS.SUCCESS,
  message: '',
  messageCode: '',
}

export const mockSelectedUserName = 'JON Smith'

export const mockSelectedUser = {
  id: '2',
  name: mockSelectedUserName,
}

export const mockDataStatusPendingByAction = {
  [ACTION_NOUVEAU_RENDEZ_VOUS]: false,
  [ACTION_NOUVEAU_RAPPORT]: false,
  [ACTION_HISTORIQUE]: false,
  [ACTION_FORMULAIRES]: true,
}
