import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
import {
  IDashboardVisitsCoverageFilters,
  TChartDataResponse,
} from '../../../interfaces/IDashboard'
import { dashboardVisitsCoverageSelector } from '../../../store/selectors'
import { DATEPICKER_FILTERS_FORMAT, STATUS } from '../../../enums/common'

export const useDashboardVisitsCoverageChart = (
  user: string,
  chartDataResponse: TChartDataResponse,
  targetType: string
) => {
  const intl = useIntl()
  const {
    visitedPharmacies,
    unvisitedPharmacies,
    visitsFrequency,
  } = chartDataResponse

  const selectedUserAtSubmit = useRef(user)
  const [title, setTitle] = useState('')
  const { filters } = useSelector(
    dashboardVisitsCoverageSelector.getDashboardVisitsCoverageValue
  )

  const labels = [
    intl.formatMessage({ id: 'dashboard.visited.label' }),
    intl.formatMessage({ id: 'dashboard.unvisited.label' }),
  ]

  const subtitle = intl.formatMessage({ id: 'dashboard.visitsCoverage.label' })
  const subtitleDetails = intl.formatMessage(
    {
      id: `dashboard.visitsCoverage.${
        targetType ? 'details.targetType' : 'details'
      }.label`,
    },
    {
      visitsFrequency,
      targetType,
    }
  )

  const chartData = [visitedPharmacies, unvisitedPharmacies]

  const getChartTitle = useCallback(
    (
      selectedFilters: IDashboardVisitsCoverageFilters,
      selectedUser: string
    ) => {
      const { startDate, endDate, groupIds } = selectedFilters
      let chartBaseTitle = intl.formatMessage(
        { id: 'dashboard.visitsCoverage.chart.title' },
        {
          kamName: selectedUser,
          startDate: startDate
            ? format(
                utcToZonedTime(startDate, 'UTC'),
                DATEPICKER_FILTERS_FORMAT
              )
            : '',
          endDate: endDate
            ? format(utcToZonedTime(endDate, 'UTC'), DATEPICKER_FILTERS_FORMAT)
            : '',
        }
      )

      const groupIdsLabels =
        !!groupIds?.length && groupIds?.map((group) => group.label).join(', ')

      if (!groupIdsLabels && !targetType) return chartBaseTitle

      chartBaseTitle += intl.formatMessage(
        {
          id: `dashboard.visitsCoverage.chart.${
            targetType ? 'targetType' : 'groupIds'
          }.title`,
        },
        {
          groupIds: groupIdsLabels,
          targetType,
        }
      )

      return chartBaseTitle
    },
    [intl, targetType]
  )

  const shouldChartRender = useMemo(
    () =>
      chartDataResponse.status === STATUS.SUCCESS &&
      !!chartData.length &&
      chartData.some((item: number) => item !== 0),
    [chartData, chartDataResponse.status]
  )

  useEffect(() => {
    setTitle(getChartTitle(filters, selectedUserAtSubmit.current))
  }, [getChartTitle, filters, shouldChartRender])

  return {
    title,
    subtitle,
    subtitleDetails,
    labels,
    chartData,
    shouldChartRender,
  }
}
