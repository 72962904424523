import React, { FC, useMemo } from 'react'
import { IAppointmentView } from '../../interfaces/IAppointments'
import {
  dateInterval,
  formatDate,
  handleHtmlContent,
  matchOptionsValue,
  timeConvert,
} from '../../utils/helpers'
import Loader from '../loader/Loader'
import { STATUS, EVENTS_DATETIME_FORMAT } from '../../enums/common'
import Alerts from '../alerts/Alerts'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import { StickyWrapper } from '../common/ui/StickyWrapper'

export const ClientAppointmentData: FC<IAppointmentView> = ({
  data,
  status,
  message,
  deleteStatus,
  appointmentTypes,
}) => {
  const {
    appointmentTypeId,
    startDate,
    endDate,
    client,
    comment,
    contactPerson,
  } = data

  const appointmentType = useMemo(
    () =>
      matchOptionsValue<number>(appointmentTypes?.data, appointmentTypeId)
        ?.label,
    [appointmentTypes, appointmentTypeId]
  )

  const eventStart = formatDate(startDate, EVENTS_DATETIME_FORMAT)
  const eventInterval = dateInterval(startDate, endDate)

  const commentContent =
    (comment && <div dangerouslySetInnerHTML={handleHtmlContent(comment)} />) ||
    '-'

  if (status === STATUS.PENDING || deleteStatus === STATUS.PENDING) {
    return <Loader />
  }

  return (
    <section className="clientAppointmentData backgroundDefault px1 py1 hasBorderRadius">
      <StickyWrapper customClass="px0">
        <Alerts status={status} message={message} />
      </StickyWrapper>
      <div className="clientAppointmentDataHeader row backgroundNeutral mx0 px2 py15 mb15">
        <div className="colMd5 pl0 mb05">
          <DynamicFormattedMessage
            id="appointmentData.type.label"
            className="textPrimary fontMedium mb05"
            tag="p"
          />
          <p className="textPrimary textMd">{appointmentType || '-'}</p>
        </div>
        <div className="colMd4 pl0 mb05">
          <DynamicFormattedMessage
            id="appointmentData.startDate.label"
            className="textPrimary fontMedium mb05"
            tag="p"
          />
          <p className="textPrimary textMd">{eventStart}</p>
        </div>
        <div className="colMd3 px0 mb05">
          <DynamicFormattedMessage
            id="appointmentData.duration.label"
            className="textPrimary fontMedium mb05"
            tag="p"
          />
          <p className="textPrimary textMd">{timeConvert(eventInterval)}</p>
        </div>
      </div>
      <div className="row mx0 px2 py15 mb15">
        <div className="colSm5 pl0 mb1">
          <DynamicFormattedMessage
            id="appointmentData.clientName.label"
            className="textPrimary fontMedium mb1"
            tag="p"
          />
          <p className="textPrimary">
            {`${client?.name} ` || '-'}
            {client?.cipCode && (
              <ClientCipCode cipCode={`${client?.cipCode}`} />
            )}
            {client?.city ? ` - ${client?.city}` : ''}
          </p>
        </div>
        <div className="colSm5 pl0">
          <DynamicFormattedMessage
            id="appointmentData.contact.label"
            className="textPrimary fontMedium mb1"
            tag="p"
          />
          <p className="textPrimary">{contactPerson?.name || '-'}</p>
        </div>
      </div>
      <div className="row mx0 px2 py15">
        <div className="col12 px0">
          <DynamicFormattedMessage
            id="form.field.comment.label"
            className="textPrimary fontMedium mb1"
            tag="p"
          />
          <div className="commentWrapper textPrimary">{commentContent}</div>
        </div>
      </div>
    </section>
  )
}
