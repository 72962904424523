import React, { FC } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { PageTitleSection } from '../components/common/ui/PageTitleSection'
import { FORMS } from '../constants/routes'
import { ClientForms } from '../components/forms/ClientForms'
import { FormsPageTitle } from '../components/contracts/FormsPageTitle'
import { ROUTES } from '../enums/routes'

const Forms: FC = () => {
  const { clientId } = useParams()

  return (
    <section className="pageContentWrapper formsPage pl75">
      <div className="px3 py3">
        <PageTitleSection
          pageName={clientId ? '' : FORMS}
          backRoute={
            clientId && !window.history.state
              ? generatePath(ROUTES.clientDetails, {
                  clientId,
                })
              : ''
          }
        />
        {clientId && <FormsPageTitle clientId={clientId} />}
        <ClientForms clientId={clientId} />
      </div>
    </section>
  )
}

export default Forms
