import { format, utcToZonedTime } from 'date-fns-tz'
import {
  IEngagementHistory,
  IHistoryAnnualTotal,
  IHistoryTableRow,
  IOrderHistory,
  IRecapHistory,
  IRecapProducts,
  IRecapProductSum,
  IRecapSummary,
  THistoryViewType,
} from '../interfaces/IHistory'
import { HISTORY_DATE_FORMAT, HISTORY_DATE_TIME_FORMAT } from '../enums/common'
import {
  KEY_CURRENT_YEAR,
  KEY_PREVIOUS_YEAR,
  TYPE_PRODUCT,
  TYPE_SUMMARY,
  VIEW_TYPE,
  VIEW_TYPE_PERIODS,
} from '../constants/tables'
import { currentYearValue, roundTwoDecimals } from './helpers'
import { HISTORY_RAF_QTY_STORE_PATH } from '../constants/history'

export const prepareEngagementHistoryResponse = (data: IEngagementHistory[]) =>
  data.map(
    ({ contractLabel, category, date, discountRate, details, progress }) => ({
      contractLabel,
      category,
      date: date
        ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_TIME_FORMAT)
        : '',
      discountRate: `${discountRate}%`,
      details,
      progress: progress !== 0 ? `${progress}%` : progress,
    })
  )

export const prepareOrderHistoryResponse = (data: IOrderHistory[]) =>
  data.map(({ date, deliveryDate, orderNumber, source, amount }) => ({
    date: date ? format(utcToZonedTime(date, 'UTC'), HISTORY_DATE_FORMAT) : '',
    deliveryDate: deliveryDate
      ? format(utcToZonedTime(deliveryDate, 'UTC'), HISTORY_DATE_FORMAT)
      : '',
    orderNumber,
    source,
    amount,
  }))

export const processSummaryData = (summary: IRecapSummary[]) =>
  summary.map(({ year, periods }) => {
    const key = year === currentYearValue ? KEY_CURRENT_YEAR : KEY_PREVIOUS_YEAR

    return {
      [key]: periods.reduce((previousValue, { name, quantity, value }) => {
        // eslint-disable-next-line no-param-reassign
        previousValue[name] = {
          quantity:
            (previousValue[name]?.quantity || 0) + roundTwoDecimals(quantity),
          value: (previousValue[name]?.value || 0) + roundTwoDecimals(value),
        }

        return previousValue
      }, {} as IRecapProductSum),
    }
  })

export const processProductsData = (products: IRecapProducts[]) =>
  products.map(({ name, summary }) => ({
    name,
    type: TYPE_PRODUCT,
    ...Object.assign({}, ...processSummaryData(summary)),
  }))

export const processRecapHistoryTableData = (data: IRecapHistory[]) => {
  const tableRows: IHistoryTableRow[] = []
  if (!(data && data.length !== 0)) {
    return []
  }

  data.forEach(({ name, summary, products }: IRecapHistory) => {
    tableRows.push(
      {
        name,
        type: TYPE_SUMMARY,
        ...Object.assign({}, ...processSummaryData(summary)),
      },
      ...processProductsData(products)
    )
  })

  return tableRows
}

export const calculateYearByPeriod = (
  yearData: IRecapProductSum,
  currentYearData: any,
  periodKeys: string[]
) =>
  periodKeys.reduce((yearAcc: IRecapProductSum, periodKey) => {
    const period = periodKey.toUpperCase()
    const yearQuantity = yearData[period]?.quantity || 0
    const currentYearQuantity = currentYearData[period]?.quantity || 0
    const yearValue = yearData[period]?.value || 0
    const currentYearValues = currentYearData[period]?.value || 0

    return {
      ...yearAcc,
      [period]: {
        quantity: yearQuantity + currentYearQuantity,
        value: yearValue + currentYearValues,
      },
    }
  }, {})

export const getHistoryTotalPerViewType = (
  summaryData: IHistoryTableRow[],
  viewType: THistoryViewType,
  initialState: IHistoryAnnualTotal
) =>
  summaryData.reduce((acc, { previousYear, currentYear }) => {
    if (viewType !== VIEW_TYPE.TOTAL) {
      const periodKeys = VIEW_TYPE_PERIODS[viewType] || []

      const previousYearByPeriod = calculateYearByPeriod(
        acc.previousYear,
        previousYear || {},
        periodKeys
      )

      const currentYearByPeriod = calculateYearByPeriod(
        acc.currentYear,
        currentYear || {},
        periodKeys
      )

      return {
        previousYear: { ...previousYearByPeriod },
        currentYear: { ...currentYearByPeriod },
      }
    }

    return {
      previousYear: {
        TOTAL1: {
          quantity:
            acc.previousYear.TOTAL1.quantity +
            (previousYear?.TOTAL1?.quantity || 0),
          value:
            acc.previousYear.TOTAL1.value + (previousYear?.TOTAL1?.value || 0),
        },
      },
      currentYear: {
        TOTAL2: {
          quantity:
            acc.currentYear.TOTAL2.quantity +
            (currentYear?.TOTAL2?.quantity || 0),
          value:
            acc.currentYear.TOTAL2.value + (currentYear?.TOTAL2?.value || 0),
        },
      },
    }
  }, initialState)

export const getRafQtyReduxPath = (clientId: number | undefined) =>
  `${clientId?.toString()}.${HISTORY_RAF_QTY_STORE_PATH}`
