import React, { FC } from 'react'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'
import {
  IHistoryTableData,
  IHistoryTableRow,
  IRecapHistoryRafQty,
} from '../../../../interfaces/IHistory'
import {
  ANNUAL_RECAP_TABLE_HEADER,
  TYPE_SUMMARY,
} from '../../../../constants/tables'
import {
  currentBusinessYearStartingValue,
  currentDate,
  currentYearValue,
  isEmptyObj,
} from '../../../../utils/helpers'
import { AnnualTableRow } from './AnnualTableRow'
import { useHistoryTotal } from '../../../../hooks/history/useHistoryTotal'

export const AnnualTableContent: FC<IHistoryTableData> = ({
  data,
  clientId,
}) => {
  const { summaryTotal, rafQty, totalRafQty } = useHistoryTotal(data, clientId)

  return (
    <table className="customTable customTableRecap wFull">
      <thead className="customTableHeader">
        <tr>
          {ANNUAL_RECAP_TABLE_HEADER.map(
            (headerName: string, index: number) => (
              <td key={headerName} className={`${index !== 0 && 'textCenter'}`}>
                <DynamicFormattedMessage
                  id={`table.header.${headerName}.label`}
                  className="fontBold textUppercase textPrimary textCustomXs textLeft"
                  values={{
                    year:
                      currentDate >= currentBusinessYearStartingValue
                        ? currentYearValue + 1
                        : currentYearValue,
                    previousYear:
                      currentDate >= currentBusinessYearStartingValue
                        ? currentYearValue
                        : currentYearValue - 1,
                  }}
                />
              </td>
            )
          )}
        </tr>
      </thead>
      <tbody className="customTableBody">
        {!!data?.length && !isEmptyObj(summaryTotal) && (
          <AnnualTableRow
            currentYear={summaryTotal.currentYear}
            previousYear={summaryTotal.previousYear}
            customClass="fontBold backgroundSecondaryLight"
            rafQty={totalRafQty}
          />
        )}
        {!!data?.length &&
          data.map(
            (
              { type, name, currentYear, previousYear }: IHistoryTableRow,
              index: number
            ) => {
              return (
                <AnnualTableRow
                  key={index.toString()}
                  currentYear={currentYear}
                  previousYear={previousYear}
                  name={name}
                  customClass={
                    type === TYPE_SUMMARY
                      ? 'fontBold backgroundNeutralLight'
                      : ''
                  }
                  rafQty={
                    rafQty ? (rafQty[index] as IRecapHistoryRafQty)?.qty : 0
                  }
                />
              )
            }
          )}
      </tbody>
    </table>
  )
}
