import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Browser } from 'leaflet'
import { BACK_CAMERA, FRONT_CAMERA, PHOTO_FILE_NAME } from '../enums/common'
import { TUsedCamera } from '../interfaces/ICommonComponents'

export const useUserMedia = () => {
  const videoRef: any = useRef(null)
  const photoRef: any = useRef(null)
  const stripRef: any = useRef(null)
  const [hasUserMedia, setHasUserMedia] = useState(false)
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true)
  const [usedCamera, setUsedCamera] = useState<TUsedCamera>(BACK_CAMERA)
  const [grantedPermission, setGrantedPermission] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [disabledSwitch, setDisabledSwitch] = useState(false)
  const { setValue } = useFormContext()

  // fix for older browsers
  if (navigator.mediaDevices === undefined) {
    ;(navigator as any).mediaDevices = {}
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = (constraints) => {
      const getUserMedia =
        // @ts-ignore
        navigator.webkitGetUserMedia ||
        // @ts-ignore
        navigator.mozGetUserMedia ||
        // @ts-ignore
        navigator.msGetUserMedia

      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        )
      }

      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }

  const getCamera = (camera: TUsedCamera) => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: camera,
        },
      })
      .then((stream) => {
        const video = videoRef.current
        if ('srcObject' in video) {
          video.srcObject = stream
          // @ts-ignore
        } else if (navigator.mozGetUserMedia) {
          video.mozSrcObject = stream
        } else {
          video.src = (window.URL || window.webkitURL).createObjectURL(stream)
        }

        video.onloadedmetadata = () => {
          video.play()
          setHasUserMedia(true)
          setIsVideoPlaying(true)
        }
      })
      .catch(() => {
        setHasUserMedia(false)
        setIsVideoPlaying(false)
      })
  }

  const paintToCanvas = () => {
    const video = videoRef.current
    const photo = photoRef.current
    const ctx = photo.getContext('2d')
    const landscapeOrientation = window.innerHeight < window.innerWidth

    const width = landscapeOrientation ? 320 : 240
    const height = landscapeOrientation ? 240 : 320
    photo.width = width
    photo.height = height

    return setInterval(() => {
      ctx.drawImage(video, 0, 0, width, height)
    }, 200)
  }

  const takePhoto = (
    photoR: any,
    type: string,
    imageUpdateCallback?: () => void,
    imageUploadCallback?: () => void
  ) => {
    const data = photoR.current.toDataURL('image/jpeg')
    const value = data
      ? {
          dataURL: data,
          fileName: PHOTO_FILE_NAME,
        }
      : { dataURL: '' }
    setValue(type, JSON.stringify(value))
    setIsCanvasEmpty(false)
    if (imageUpdateCallback) {
      imageUpdateCallback()
    }
    if (imageUploadCallback) {
      imageUploadCallback()
    }
  }

  const handleClearPhoto = (type: string, callback?: () => void) => {
    const value = { dataURL: '' }
    setValue(type, JSON.stringify(value))
    setIsCanvasEmpty(true)
    if (callback) {
      callback()
    }
  }

  const stop = () => {
    const stream = videoRef.current.srcObject
    const tracks = stream && (stream.getTracks() as MediaStreamTrack[])

    tracks.forEach((track: MediaStreamTrack) => track.stop())
    videoRef.current.srcObject = null
  }

  const promptPermissions = () => {
    if (!navigator.permissions || !navigator.permissions.query) return

    navigator.permissions
      .query({ name: 'camera' })
      .then((permissionObj) => {
        if (permissionObj.state === 'granted') setGrantedPermission(true)

        // eslint-disable-next-line
        permissionObj.onchange = (e: any) => {
          if (!(e.target.state === 'granted'))
            return setGrantedPermission(false)
          setGrantedPermission(true)
        }
      })
      .catch(() => {
        setGrantedPermission(false)
      })
  }

  useEffect(() => {
    if (Browser.safari || Browser.gecko || Browser.gecko3d)
      return setGrantedPermission(true)

    promptPermissions()
  }, [])

  useEffect(() => {
    const isFirefox = Browser.gecko || Browser.gecko3d
    setDisabledSwitch(!!isFirefox)
  }, [])

  const handleSwitchCamera = (camera: TUsedCamera) => {
    stop()
    setUsedCamera(camera)
    getCamera(camera)
  }

  const handleSwitchToFront = () => {
    handleSwitchCamera(FRONT_CAMERA)
  }

  const handleSwitchToBack = () => {
    handleSwitchCamera(BACK_CAMERA)
  }

  useEffect(() => {
    const checkIfUserMedia =
      navigator.mediaDevices && navigator.mediaDevices.getUserMedia

    setHasUserMedia(!!checkIfUserMedia)
  }, [videoRef])

  return {
    videoRef,
    photoRef,
    stripRef,
    isCanvasEmpty,
    hasUserMedia,
    getCamera,
    takePhoto,
    handleClearPhoto,
    paintToCanvas,
    stop,
    grantedPermission,
    usedCamera,
    setUsedCamera,
    isVideoPlaying,
    handleSwitchToFront,
    handleSwitchToBack,
    disabledSwitch,
  }
}
