import React, { ChangeEvent, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

function SelectInput({
  name,
  id,
  register,
  error,
  options,
  renderLabel = true,
  shouldRender = true,
  defaultValue,
  onChange,
  className,
  parentClassName,
  defaultPlaceholder,
  disabled,
}: {
  name: string
  id: string
  register?: (ref: HTMLSelectElement) => void
  error?: FieldError | undefined
  options: {
    name: string
    value: string
  }[]
  renderLabel?: boolean
  shouldRender?: boolean
  defaultValue?: string
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  className?: string
  parentClassName?: string
  defaultPlaceholder?: boolean
  disabled?: boolean
}): ReactElement {
  const intl = useIntl()
  const isDisabled = disabled || options?.length === 0
  const placeholder = defaultPlaceholder
    ? `default.placeholder`
    : `${name}.placeholder`
  if (!shouldRender) return <></>

  return (
    <div className={`inputGroup ${parentClassName ?? ''}`}>
      <DynamicFormattedMessage
        tag="label"
        id={`form.field.${name}.label`}
        className="inputLabel"
        htmlFor={id}
        shouldRender={renderLabel}
      />
      <select
        className={`inputField inputSelect wFull ${className}`}
        name={name}
        id={id}
        ref={register}
        disabled={isDisabled}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        <option value="" hidden={true}>
          {intl.formatMessage({
            id: placeholder,
          })}
        </option>
        {options.map((option) => {
          if (!option.name) {
            return <React.Fragment key={option.value} />
          }

          return (
            <option
              value={option.value}
              key={option.value}
              selected={option.value === defaultValue}
            >
              {option.name}
            </option>
          )
        })}
      </select>
      {error && (
        <DynamicFormattedMessage
          tag="span"
          id={`form.validation.${
            ['pattern', 'validate'].includes(error.type)
              ? error.message
              : error.message || error.type
          }`}
          className="errorMessage"
        />
      )}
    </div>
  )
}

export default SelectInput
