import { DateSelectArg, DatesSetArg } from '@fullcalendar/react'
import { utcToZonedTime } from 'date-fns-tz'
import {
  addMinutes,
  format,
  isAfter,
  isWeekend,
  isWithinInterval,
  set,
  setHours,
} from 'date-fns'
import {
  IClientCalendarEvent,
  IClientCalendarEventResponse,
  IClientVisitReport,
} from '../interfaces/IClientDetails'
import { DATE_REQUEST_FORMAT } from '../enums/common'
import {
  CALENDAR_INITIAL_EVENT_TYPE,
  CALENDAR_INITIAL_VIEW,
} from '../constants/api'
import { IUser } from '../interfaces/IUsers'
import {
  EVENT_TYPE_RANDEZ_VOUS,
  EVENT_VISIT_REPORT_APPEL,
} from '../enums/agenda'
import { MAX_TIME, MIN_TIME } from '../constants/calendar'
import {
  CUSTOM_COLOR_10,
  CUSTOM_COLOR_3,
  CUSTOM_COLOR_8,
  DEFAULT_COLOR,
  PRIMARY_COLOR,
} from '../constants/colors'

export const createAppointmentsFiltersQuery = (filters: DatesSetArg) => {
  const startDate = format(
    utcToZonedTime(filters.startStr, 'UTC'),
    DATE_REQUEST_FORMAT
  )
  const endDate = format(
    utcToZonedTime(filters.endStr, 'UTC'),
    DATE_REQUEST_FORMAT
  )

  return `?size=1000&startDate=${startDate}&endDate=${endDate}`
}

export const createReportsFiltersQuery = (filters: DatesSetArg) => {
  const startDate = format(
    utcToZonedTime(filters.startStr, 'UTC'),
    DATE_REQUEST_FORMAT
  )
  const endDate = format(
    utcToZonedTime(filters.endStr, 'UTC'),
    DATE_REQUEST_FORMAT
  )

  return `?size=1000&startDate=${startDate}&endDate=${endDate}`
}

export const prepareAppointmentsResponseForCalendar = (
  events: IClientCalendarEventResponse[]
) =>
  events.map(
    ({
      id,
      clientName,
      city,
      startDate,
      endDate,
      cipCode,
      hasPreviousOrderHistory,
    }) => {
      const eventTitle = `${clientName} (${cipCode}, ${city})`

      return {
        id,
        title: eventTitle,
        start: startDate,
        end: endDate,
        allDay: false,
        color: hasPreviousOrderHistory ? PRIMARY_COLOR : CUSTOM_COLOR_10,
      }
    }
  )

export const prepareReportsResponseForCalendar = (
  events: IClientVisitReport[]
) =>
  events.map(
    ({
      id,
      startDate,
      clientName,
      city,
      cipCode,
      duration,
      clientId,
      eventType,
    }) => {
      const eventTitle = `${clientName} (${cipCode}, ${city})`

      return {
        id,
        start: startDate,
        end: format(
          utcToZonedTime(addMinutes(new Date(startDate), duration || 0), 'UTC'),
          DATE_REQUEST_FORMAT
        ),
        title: eventTitle,
        allDay: false,
        color:
          eventType === EVENT_VISIT_REPORT_APPEL
            ? DEFAULT_COLOR
            : CUSTOM_COLOR_3,
        borderColor:
          eventType === EVENT_VISIT_REPORT_APPEL
            ? CUSTOM_COLOR_8
            : CUSTOM_COLOR_3,
        textColor:
          eventType === EVENT_VISIT_REPORT_APPEL
            ? PRIMARY_COLOR
            : DEFAULT_COLOR,
        extendedProps: {
          clientId: clientId as number,
        },
      }
    }
  )

export const getDeviceInitialView = () => {
  const initialView = localStorage.getItem(CALENDAR_INITIAL_VIEW)
  if (!initialView || !initialView.length) {
    return
  }

  return initialView
}

export const getDeviceInitialEventType = () => {
  const initialEventType = localStorage.getItem(CALENDAR_INITIAL_EVENT_TYPE)
  if (!initialEventType || !initialEventType.length) {
    return EVENT_TYPE_RANDEZ_VOUS
  }

  return initialEventType
}

export const prepareUsersOptions = (
  users: IUser[],
  loggedUser: number | undefined
) => {
  if (!users || users.length === 0) {
    return []
  }

  users.unshift(
    users.splice(
      users.findIndex((item) => item.id === loggedUser),
      1
    )[0]
  )

  return users.map(({ id, firstName, lastName }) => ({
    value: id.toString(),
    name: `${lastName} ${firstName}`,
  }))
}

export const currentTime = (data: Date) => {
  const selectedDate = new Date(data)
  const today = new Date()

  return new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    today.getHours(),
    today.getMinutes(),
    0
  )
}

export const getMonthViewAvailableSlot = (
  arg: DateSelectArg,
  events: IClientCalendarEvent[]
) => {
  const isSelectedInTheFuture = isAfter(
    set(arg.start, { hours: 0, minutes: 0, seconds: 0 }),
    set(new Date(), { hours: 0, minutes: 0, seconds: 0 })
  )

  const todaySlot = new Date()
  todaySlot.setMinutes(Math.ceil(todaySlot.getMinutes() / 30) * 30)
  todaySlot.setSeconds(0)
  let wantedSlot = isSelectedInTheFuture
    ? setHours(arg.start, MIN_TIME)
    : todaySlot

  let i = 0
  let availableSlot

  while (i < events?.length && !availableSlot) {
    const event = events[i]
    const eventStart = utcToZonedTime(event.start, 'UTC')
    const eventEnd = utcToZonedTime(event.end!, 'UTC')
    const eventEndDate = eventEnd.getDate()
    const isTomorrowWeekend = isWeekend(
      utcToZonedTime(event.end!, 'UTC').setDate(eventEndDate + 1)
    )

    if (
      isWithinInterval(wantedSlot, {
        start: eventStart,
        end: eventEnd,
      })
    ) {
      wantedSlot =
        eventEnd.getHours() === MAX_TIME
          ? setHours(
              eventEnd.setDate(eventEndDate + (isTomorrowWeekend ? 3 : 1)),
              MIN_TIME
            )
          : eventEnd
    }

    if (eventStart > wantedSlot) {
      availableSlot = wantedSlot
    }

    i += 1
  }

  return availableSlot || wantedSlot
}
