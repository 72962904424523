import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getJwtUserDetails, handleError, userIsAdmin } from '../utils/api'
import { STATUS } from '../enums/common'
import { getUsersOptions } from '../services/usersService'
import { prepareUsersOptions } from '../utils/agenda'
import { IResponseData } from '../interfaces/ICommonComponents'
import { IUser, IUsersOptions } from '../interfaces/IUsers'
import { sharedDataSelectors } from '../store/selectors'
import { sharedDataActions } from '../store/reducers/sharedDataReducer'
import { isUserAvailable } from '../utils/user'

export const useGetUsers = () => {
  const jwt = getJwtUserDetails()
  const intl = useIntl()
  const dispatch = useDispatch()
  const isAdmin = userIsAdmin()
  const userId = useSelector(sharedDataSelectors.getUserSavedData).selectedUser
  const apiUsers = useSelector(sharedDataSelectors.getUsers)
  const [users, setUsers] = useState<IResponseData<IUsersOptions[]>>({
    data: [],
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const [isUserNotAvailableError, setIsUserNotAvailableError] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const isUsersLoadingError = useMemo(() => {
    return users.status === STATUS.DANGER
  }, [users])

  useEffect(() => {
    if (!isAdmin || users.status !== STATUS.SUCCESS || !users.data) return

    const isUser = isAdmin && users.data.some((u) => u.value === userId)
    if (initialLoad && !isUser && userId !== jwt?.id?.toString()) {
      setIsUserNotAvailableError(true)
    }
  }, [
    userId,
    initialLoad,
    isUserNotAvailableError,
    isUsersLoadingError,
    users,
    isAdmin,
    jwt,
  ])

  useEffect(() => {
    if (!isAdmin) return

    setUsers({
      data: [],
      status: STATUS.PENDING,
      message: '',
    })
    getUsersOptions()
      .then(({ body: { data } }) => {
        setUsers({
          data: prepareUsersOptions(data, jwt.id),
          status: STATUS.SUCCESS,
          message: '',
        })
        dispatch(sharedDataActions.setUsers([...data]))
      })
      .catch((error) => {
        const handledError = handleError(error, intl)
        setUsers({
          data: [],
          ...handledError,
        })
      })
  }, [jwt.id, jwt.role, intl, dispatch, isAdmin])

  const usersStatusPending = useMemo(() => users.status === STATUS.PENDING, [
    users,
  ])

  useEffect(() => {
    if (!isAdmin || !users?.data?.length || !apiUsers?.length) return

    const isUserInDb = isUserAvailable(apiUsers as IUser[], userId)
    if (isUserInDb) return

    dispatch(sharedDataActions.setSelectedUser(jwt?.id?.toString()))
  }, [dispatch, jwt, userId, users, apiUsers, isAdmin])

  return {
    users,
    usersStatusPending,
    isUsersLoadingError,
    isUserNotAvailableError,
    setIsUserNotAvailableError,
    setInitialLoad,
  }
}
