import React, { FC } from 'react'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { useImageUploadHook } from '../../hooks/UseImageUploadHook'
import Button from '../common/ui/Button'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import { ICustomImageUpload } from '../../interfaces/IVisitReports'
import { PHOTO, PHOTO_1, PHOTO_LABELS } from '../../constants/visitReport'

export const CustomImageUpload: FC<ICustomImageUpload> = ({
  photoPath,
  type,
  updateForm,
  isDisabled,
  showUploadBtn,
  onUploadCallback,
  btnDeleteClass,
  onImageDeleteCallback,
  duplicateError,
  isMandatoryError,
  clearError,
}) => {
  const { images, maxNumber, onChange } = useImageUploadHook(photoPath)

  const handleImageUpdate = () => {
    if (clearError) {
      clearError()
    }
  }

  const selectedPhoto = PHOTO_LABELS.find((option) => option.value === type)
  const photoLabel = selectedPhoto ? selectedPhoto.label : PHOTO
  const isDuplicateError = duplicateError
    ? duplicateError.some((obj) => obj.type === type && obj.error)
    : false

  return (
    <div className="customImageUpload">
      <ImageUploading
        multiple={false}
        value={images}
        onChange={(imageList: ImageListType) => {
          onChange(imageList, type)
          if (onUploadCallback) {
            onUploadCallback()
          }
          if (type === PHOTO_1 && imageList.length) {
            handleImageUpdate()
          }
          updateForm()
        }}
        maxNumber={maxNumber}
        acceptType={['jpg', 'jpeg', 'png', 'heic', 'heif']}
        maxFileSize={4000000}
      >
        {({ imageList, onImageUpload, onImageRemove, errors }) => (
          <div className="row justifyContentBetween">
            <div className="customImageUploadWrapper dFlex alignItemsStart justifyContentStart mb2">
              {!imageList.length ? (
                <DynamicFormattedMessage
                  id="upload.button.label"
                  tag={Button}
                  className={`btn btnPrimary btnUpload btnUploadImage mr15 ${
                    isDisabled ? 'btnDisabled' : ''
                  }`}
                  onClick={onImageUpload}
                  disabled={isDisabled}
                />
              ) : (
                imageList.map((image, index) => (
                  <div
                    key={image.dataURL}
                    className="customImageUploadBtnWrapper"
                  >
                    <DynamicFormattedMessage
                      id="clearPhoto.button.label"
                      tag={Button}
                      className={`btn btnDelete btnDeleteImage dBlock ${btnDeleteClass}`}
                      onClick={() => {
                        onImageRemove(index)
                        if (onImageDeleteCallback) {
                          onImageDeleteCallback()
                        }
                      }}
                    />
                    <DynamicFormattedMessage
                      id="upload.button.label"
                      tag={Button}
                      className="btn btnPrimary btnUpload btnUploadImage mr15 btnDisabled mt2"
                      shouldRender={showUploadBtn}
                      disabled={true}
                    />
                  </div>
                ))
              )}
              {type === PHOTO_1 && isMandatoryError && (
                <DynamicFormattedMessage
                  tag="span"
                  id="form.validation.required"
                  className="errorMessage"
                />
              )}
              {isDuplicateError && (
                <DynamicFormattedMessage
                  tag="span"
                  id="form.validation.duplicateImage"
                  values={{ label: photoLabel }}
                  className="errorMessage"
                />
              )}
              {errors && (
                <div>
                  {errors.acceptType && (
                    <DynamicFormattedMessage
                      tag="span"
                      id="form.validation.imagesType"
                      className="errorMessage"
                    />
                  )}
                  {errors.maxFileSize && (
                    <DynamicFormattedMessage
                      tag="span"
                      id="form.validation.maxFileSize"
                      className="errorMessage"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  )
}
