import React, { FC } from 'react'
import { IHistoryAnnualTotal } from '../../../../interfaces/IHistory'
import { roundTwoDecimals } from '../../../../utils/helpers'
import DynamicFormattedMessage from '../../../common/ui/DynamicFormattedMessage'

export const QuarterTableRow: FC<IHistoryAnnualTotal> = ({
  currentYear,
  previousYear,
  name,
  customClass = '',
  rafQty,
}) => {
  return (
    <tr className={`textPrimary textCustomXs ${customClass}`}>
      <td>
        {name || (
          <DynamicFormattedMessage
            id="page.history.annual.total"
            className="textUppercase"
          />
        )}
      </td>
      <td className="textCenter">{currentYear?.Q1?.quantity || '-'}</td>
      <td className="textCenter">{currentYear?.Q2?.quantity || '-'}</td>
      <td className="textCenter">{currentYear?.Q3?.quantity || '-'}</td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.Q1?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.Q2?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(currentYear?.Q3?.value) || '-'}
      </td>
      <td className="textCenter">{previousYear?.Q1?.quantity || '-'}</td>
      <td className="textCenter">{previousYear?.Q2?.quantity || '-'}</td>
      <td className="textCenter">{previousYear?.Q3?.quantity || '-'}</td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.Q1?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.Q2?.value) || '-'}
      </td>
      <td className="textCenter">
        {roundTwoDecimals(previousYear?.Q3?.value) || '-'}
      </td>
      <td className="textCenter">{rafQty}</td>
    </tr>
  )
}
