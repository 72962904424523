import { format } from 'date-fns'
import { IFilters, IOption, ISelectOption } from '../interfaces/IFilters'
import { GEOLOCATION, ITEMS_PER_PAGE } from '../enums/common'
import { CLIENT_SOURCE } from '../constants/userData'
import { timezoneOffset } from './helpers'

export const createFiltersQuery = (
  filters: IFilters,
  offset: number,
  size: number,
  source?: CLIENT_SOURCE
) => {
  let query = `?size=${size}&offset=${
    offset * ITEMS_PER_PAGE
  }&timezoneOffset=${timezoneOffset()}`
  let filterValue = ''

  if (source) {
    query += `&source=${source}`
  }

  Object.entries(filters).map(([key, val]) => {
    filterValue = val
    if (!val || val.length === 0) {
      return query
    }

    if (key === 'lastVisitStart' || key === 'lastVisitEnd') {
      filterValue = format(new Date(val), 'yyyy-MM-dd')
    }

    if (key === 'range') {
      const geolocation = localStorage.getItem(GEOLOCATION)
        ? JSON.parse(localStorage.getItem(GEOLOCATION) || '')
        : null

      if (geolocation) {
        const { latitude, longitude } = geolocation

        query += `&currentLocationLatitude=${latitude}&currentLocationLongitude=${longitude}`
      }
    }

    if (Array.isArray(val)) {
      query += getQueryFromArray(val, key)

      return query
    }

    query += `&filters[${key}][]=${filterValue}`

    return query
  })

  return query
}

const getQueryFromArray = (array: (ISelectOption | string)[], key: string) => {
  return array
    .map(
      (item: ISelectOption | string) =>
        `&filters[${key}][]=${(item as ISelectOption).value || item}`
    )
    .join('')
}

export const prepareOptions = (options: IOption[]) => {
  return options.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }))
}

export const fieldHasValue = (
  field: string | Array<ISelectOption>
): boolean => {
  return (
    (typeof field !== 'object' && !!field) ||
    (typeof field === 'object' && !!field?.length) ||
    field instanceof Date
  )
}

export const prepareFieldSelectOptions = (
  field: ISelectOption[],
  values: string,
  filterByValue: boolean = true
) => {
  return field.filter(({ value, label }) =>
    values.split(',').includes(filterByValue ? value : label)
  )
}

export const isPage = (pathname: string, page: string) =>
  pathname.indexOf(page) >= 0

export const clearSimpleSearchInput = (inputName: string) => {
  const simpleSearchInput = document.getElementsByClassName(
    inputName
  )[0] as HTMLInputElement
  simpleSearchInput.value = ''
}

export const isFiltersSet = (filters: IFilters) =>
  !!Object.entries(filters).find(([, value]) => fieldHasValue(value)) ||
  !!filters?.search

export const setGeolocationToLocalStorage = (data: Position) => {
  const geolocation = {
    latitude: data.coords.latitude.toFixed(4),
    longitude: data.coords.longitude.toFixed(4),
  }

  localStorage.setItem(GEOLOCATION, JSON.stringify(geolocation))
}

export const getGeolocationCurrentPosition = async (
  successCallback: PositionCallback,
  errorCallback?: PositionErrorCallback | undefined
) => navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
