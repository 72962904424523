import { useEffect, useState } from 'react'
import { IClientDcrCallResponseData } from '../../interfaces/IClientDetails'
import { getDcrCalls } from '../../services/clientDetailsService'
import { clientDcrCallDataInitialState } from '../../constants/userData'
import { STATUS } from '../../enums/common'
import { processClientDcrCalls } from '../../utils/data'

export const useClientDcrCalls = (clientId: number) => {
  const [
    clientDcrCalls,
    setClientDcrCalls,
  ] = useState<IClientDcrCallResponseData>({
    clientDcrCallsData: clientDcrCallDataInitialState,
    status: STATUS.PENDING,
    total: 0,
  })

  useEffect(() => {
    const getClientDcrCalls = async () => {
      try {
        const {
          body: { data, total },
        } = await getDcrCalls(clientId)
        setClientDcrCalls((prevState) => {
          return {
            ...prevState,
            clientDcrCallsData: processClientDcrCalls(data),
            status: STATUS.SUCCESS,
            total,
          }
        })
      } catch (e) {
        setClientDcrCalls((prevState) => {
          return {
            ...prevState,
            status: STATUS.DANGER,
            total: 0,
          }
        })
      }
    }

    getClientDcrCalls()
  }, [clientId])

  return { clientDcrCalls }
}
