import React, { FC } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import {
  COL_SPAN_BY_VIEW,
  VIEW_TYPE,
  VIEW_TYPES_HEADERS,
  VIEW_TYPES_LIST,
  VIEW_TYPES_SUB_HEADERS,
} from '../../constants/tables'
import { CustomPeriodTable } from './components/CustomPeriodTable'
import { IRecapPanel } from '../../interfaces/ITables'
import { AnnualTableContent } from './components/annualTable/AnnualTableContent'
import { STATUS } from '../../enums/common'
import Loader from '../loader/Loader'
import { useHistoryRecap } from '../../hooks/history/UseHistoryRecap'

export const HistoryRecapPanel: FC<IRecapPanel> = ({ clientId }) => {
  const { view, setView, tableRows, TableContent, status } = useHistoryRecap(
    clientId
  )

  return (
    <div className="recapPanel pt15">
      <div className="dFlex recapPanelCtaWrapper alignItemsCenter mb15">
        <DynamicFormattedMessage
          id="display.type.label"
          className="textPrimary"
        />
        {VIEW_TYPES_LIST.map((viewType) => (
          <DynamicFormattedMessage
            id={`cta.period.${viewType}.label`}
            tag={Button}
            onClick={() => setView(viewType as VIEW_TYPE)}
            className={`btn btnOutline hasBorderRadius hasBoxShadow ${
              viewType === view ? 'active' : ''
            }`}
            key={viewType}
          />
        ))}
      </div>

      <CustomPeriodTable
        tableHeaders={VIEW_TYPES_HEADERS[view]}
        colSpan={COL_SPAN_BY_VIEW[view]}
        subHeaderList={VIEW_TYPES_SUB_HEADERS[view]}
      >
        {TableContent && <TableContent data={tableRows} clientId={clientId} />}
      </CustomPeriodTable>
      {view === VIEW_TYPE.TOTAL && (
        <AnnualTableContent data={tableRows} clientId={clientId} />
      )}
      {status && status === STATUS.PENDING && <Loader />}
    </div>
  )
}
