export enum API_PATHS {
  clients = '/clients',
  ordersHistory = '/orders-history',
  engagementsHistory = '/engagements-history',
  users = '/users',
  appointments = '/appointments',
  visitReport = '/visit-reports',
  reports = '/reports',
  lastOrder = '/last-order-date',
  appointmentTypes = '/appointment-types',
  contactPersons = '/contact-persons',
  editAppointment = '/editAppointment/',
  contractTypes = '/contract-types',
  plvList = '/plv-lists',
  contactOutcomes = '/contact-outcomes',
  contactTypes = '/contact-types',
  lastVisitReport = '/last-visit-report',
  file = '/file',
  upload = '/upload',
  viewAppointment = '/viewAppointment/',
  login = '/tokens/login',
  recap = '/recap',
  activity = '/activity',
  reclamations = '/reclamations',
  destructionCertificates = '/destructionCertificates',
  contracts = '/contracts',
  form = '/form',
  sendEmail = '/sendEmail',
  turnover = '/turnover',
  visitsCoverage = '/visits-coverage',
  checkDuplicateFile = '/file/checkDuplicateFile',
  nextAppointment = '/next-appointment',
  appointmentsNumber = '/reports-number',
  events = '/events',
}
