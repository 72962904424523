import React, { FC, useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loginSelectors } from '../../store/selectors'
import { IPrivateRouteProps } from '../../interfaces/INavigation'
import { Navbar } from '../navigation/Navbar'
import { PageBlur } from '../common/ui/PageBlur'
import { BLUR_ENABLED } from '../../enums/common'
import { BLURRED_ROUTES } from '../../constants/routes'

export const PrivateRoute: FC<IPrivateRouteProps> = ({ children, path }) => {
  const isAuthenticated = useSelector(loginSelectors.getLoginValue)
  const shouldBlurPage = useMemo(
    () =>
      BLUR_ENABLED === 'true' && BLURRED_ROUTES.some((route) => route === path),
    [path]
  )

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <>
      <Navbar />
      <Route
        path={path}
        render={() => (
          <>
            {shouldBlurPage && (
              <PageBlur path={path} shouldBlurPage={shouldBlurPage} />
            )}
            <main className={shouldBlurPage ? 'blurWrapper' : ''}>
              {children}
            </main>
          </>
        )}
      />
    </>
  )
}
