import React, { FC } from 'react'
import { PageTitleSection } from '../../components/common/ui/PageTitleSection'
import { DASHBOARD_CA } from '../../constants/routes'
import DashboardCaContent from '../../components/dashboard/DashboardCaContent'

const DashboardCa: FC = () => {
  return (
    <section className={`pageContentWrapper ${DASHBOARD_CA}Page pl75`}>
      <div className="px3 py3">
        <PageTitleSection pageName={DASHBOARD_CA} />
        <DashboardCaContent />
      </div>
    </section>
  )
}

export default DashboardCa
