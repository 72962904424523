import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import set from 'lodash.set'
import { useIntl } from 'react-intl'
import { IClientDetailsData, TApiResponse } from '../interfaces/IClientDetails'
import {
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  FORM_DATE_REQUEST_FORMAT,
  INITIAL_ACTIVE_PAGE,
  INITIAL_OFFSET,
  SEND_EMAIL_API_TYPE_DESTRUCTION_CERTIFICATE,
  STATUS,
} from '../enums/common'
import {
  IDestructionCertificateForm,
  IDestructionCertificateProduct,
  IEmailData,
} from '../interfaces/IContracts'
import {
  getMissingData,
  handleFormModal,
  prepareDestructionCertificateRequestBody,
  prepareSendEmailRequestBody,
} from '../utils/contracts'
import { IUsersOptions } from '../interfaces/IUsers'
import { useLoadData } from './UseLoadData'
import { getContactPersons } from '../services/appointmentsService'
import { clientContactPersonsActions } from '../store/reducers/clientContactPersonsReducer'
import { getClientContactPersons } from '../store/selectors/clientContactPersonsSelectors'

import { ROUTES } from '../enums/routes'
import {
  createNewDestructionCertificate,
  handleNewContractAddToIndexedDb,
} from '../services/contractsServices'
import { joinData } from '../utils/helpers'
import { ICustomObject } from '../interfaces/ICommonComponents'
import { DESTRUCTION_CERTIFICATE_EMAIL_CONTACT } from '../constants/userData'
import { statusNetworkSelector } from '../store/selectors'
import { clientContractsSendEmailActions } from '../store/reducers/clientContractsSendEmailReducer'
import { TStatus } from '../interfaces/INavigation'
import { clientContractsActions } from '../store/reducers/clientContractsReducer'
import { API_PATHS } from '../enums/apiPaths'
import { statusNetworkReducerActions } from '../store/reducers/statusNetworkReducer'
import { handleError } from '../utils/api'
import { ResponseError } from '../models/errors/ResponseError'

const useNewDestructionCertificateFormHook = (
  clientDetailsData: IClientDetailsData,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  dataLoadStatus: TStatus
) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const {
    id: clientId,
    name,
    cipCode,
    email: clientEmail,
    kamEmail,
  } = clientDetailsData
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [newContactPerson, setNewContactPerson] = useState<IUsersOptions>({
    name: '',
    value: '',
  })
  const [products, setProducts] = useState<IDestructionCertificateProduct[]>([])
  const [missingEmailWarning, setMissingEmailWarning] = useState(false)
  const [missingEmailDanger, setMissingEmailDanger] = useState(false)
  const [emailDanger, setEmailDanger] = useState(false)
  const contactPersons = useLoadData(() => getContactPersons(+clientId), {
    dependencies: [clientId],
    fetchFromRedux: true,
    storeInRedux: true,
    reduxAction: clientContactPersonsActions.setClientContactPersons,
    reduxSelector: getClientContactPersons,
    reduxStorePath: clientId.toString(),
  })
  const form = useForm<IDestructionCertificateForm>({ shouldUnregister: false })
  const { getValues, unregister, clearErrors, watch, setValue, errors } = form
  const [formState, setFormState] = useState<TApiResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })

  const watchFields = watch(['emailCheckbox'])

  const availableEmails = [
    watchFields.emailCheckbox ? clientEmail : '',
    kamEmail,
  ]
  const emailLegend = joinData(availableEmails)
  const clientEmails: ICustomObject = {
    email: clientEmail || '',
    kamEmail: kamEmail || '',
  }

  const emailMissing = clientDetailsData && getMissingData(clientEmails)
  const emailMissingContact = emailMissing?.map(
    (el: string) => DESTRUCTION_CERTIFICATE_EMAIL_CONTACT[el]
  )

  const preparedProducts = useMemo(
    () =>
      products.map(({ index, comment, ...product }) => ({
        cipCode: product.cipCode,
        lot: product.lot,
        designation: product.designation,
        quantity: +product.quantity,
        expirationDate: product.expirationDate
          ? format(new Date(product.expirationDate), FORM_DATE_REQUEST_FORMAT)
          : '',
      })),
    [products]
  )

  const productComments = useMemo(
    () => products.map((product) => product.comment),
    [products]
  )

  const onSubmit = async (values: any) => {
    setFormState({ status: STATUS.PENDING, message: '' })
    const bodyField = prepareDestructionCertificateRequestBody(
      values,
      clientDetailsData,
      clientId,
      contactPersons.data,
      newContactPerson,
      preparedProducts,
      productComments.join(',')
    )

    const emailData: IEmailData = prepareSendEmailRequestBody(
      values?.emailCheckbox,
      {
        client: {
          kamEmail,
          email: clientEmail,
        },
      },
      SEND_EMAIL_API_TYPE_DESTRUCTION_CERTIFICATE
    )

    const returnToPreviousPage = () =>
      window.history.state
        ? window.history.back()
        : history.replace(
            generatePath(ROUTES.clientDestructionCertificates, {
              clientId,
            }),
            {
              offset: INITIAL_OFFSET,
              currentPage: INITIAL_ACTIVE_PAGE,
            }
          )

    try {
      const { body } = await createNewDestructionCertificate({
        ...bodyField,
        createdAt: new Date().toISOString(),
      })

      if (!emailDanger && hasNetwork) {
        dispatch(
          clientContractsSendEmailActions.setContractsSendEmailValues({
            ...emailData,
            entityId: body?.destructionCertificateId,
          })
        )
      }

      setIsModalOpen(false)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      setTimeout(() => returnToPreviousPage(), 2000)
    } catch (error) {
      if (!hasNetwork) {
        const timestamp = Date.now()
        dispatch(
          clientContractsActions.addDestructionCertificate(
            set({}, `${clientId}.destructionCertificates`, [
              {
                id: '',
                clientName: name,
                cipCode,
                creationDate: timestamp,
                kamEmail,
                deleted: false,
                timestamp,
              },
            ])
          )
        )
        handleNewContractAddToIndexedDb(
          bodyField,
          'new-contract-background-sync',
          timestamp,
          timestamp,
          API_PATHS.destructionCertificates
        )

        if (!emailDanger) {
          handleNewContractAddToIndexedDb(
            emailData,
            'new-contract-emails',
            timestamp,
            timestamp,
            API_PATHS.sendEmail
          )
        }

        setIsModalOpen(false)
        dispatch(statusNetworkReducerActions.submitedForm())
        returnToPreviousPage()
        return
      }

      const handledError = handleError(error, intl)
      setFormState(handledError)

      if (
        error instanceof ResponseError &&
        error.hasMessageCode(EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE)
      ) {
        setIsModalOpen(false)
        setTimeout(() => returnToPreviousPage(), 5000)
      }
    }
  }

  const unregisterAddProductForm = useCallback(() => {
    if (!preparedProducts.length) return true
    unregister('formProductsCount')
    const values = getValues()
    values.newProduct?.forEach((item) => {
      unregister([
        `newProduct[${item.index}].cipCode`,
        `newProduct[${item.index}].designation`,
        `newProduct[${item.index}].quantity`,
        `newProduct[${item.index}].lot`,
        `newProduct[${item.index}].comment`,
      ])
    })

    return true
  }, [getValues, unregister, preparedProducts])

  const clearErrorsAddProductForm = useCallback(() => {
    if (!preparedProducts.length) return true
    clearErrors('formProductsCount')
    const values = getValues()
    values.newProduct?.forEach((item, index) => {
      clearErrors([
        `newProduct[${index}].cipCode`,
        `newProduct[${index}].designation`,
        `newProduct[${index}].quantity`,
        `newProduct[${index}].lot`,
      ])
    })
  }, [getValues, clearErrors, preparedProducts])

  useEffect(() => {
    if (!emailMissing) return

    const allMissing = emailMissing?.length === Object.keys(clientEmails).length
    setMissingEmailDanger(allMissing)

    const someMissing = emailMissing?.length > 0 && !allMissing
    setMissingEmailWarning(someMissing)
  }, [emailMissing, clientEmails])

  useEffect(() => {
    setValue('emailCheckbox', clientEmail)
  }, [clientEmail, setValue])

  useEffect(() => {
    const emptyEmailLegend = emailLegend.length === 0
    setEmailDanger(emptyEmailLegend)
  }, [emailLegend.length])

  useEffect(() => {
    const submitDisabled =
      formState.status === STATUS.PENDING ||
      formState.status === STATUS.SUCCESS ||
      dataLoadStatus === STATUS.PENDING ||
      dataLoadStatus === STATUS.DANGER
    setIsSubmitDisabled(submitDisabled)
  }, [formState.status, dataLoadStatus])

  const handleModal = useCallback(
    () => handleFormModal(getValues(), setIsModalOpen),
    [getValues, setIsModalOpen]
  )

  const handleReturn = useCallback(() => {
    handleModal()
    return window.history.state
      ? window.history.back()
      : history.replace(
          generatePath(ROUTES.clientDestructionCertificates, {
            clientId,
          }),
          {
            offset: INITIAL_OFFSET,
            currentPage: INITIAL_ACTIVE_PAGE,
          }
        )
  }, [handleModal, clientId, history])

  return {
    form,
    formState,
    onSubmit,
    contactPersons,
    newContactPerson,
    setNewContactPerson,
    unregisterAddProductForm,
    clearErrorsAddProductForm,
    products,
    setProducts,
    formProductsCount: preparedProducts.length,
    isSubmitDisabled,
    emailMissingContact,
    missingEmailWarning,
    missingEmailDanger,
    handleModal,
    handleReturn,
    errors,
  }
}

export default useNewDestructionCertificateFormHook
