import React, { FC, useState } from 'react'
import { IThirdTab } from '../../../interfaces/IVisitReports'
import { useUserMedia } from '../../../hooks/UseUserMedia'
import { VisitReportImportFileSection } from './components/VisitReportImportFileSection'
import { UserMediaSection } from '../../common/ui/UserMediaSection'

export const VisitReportThirdPanel: FC<IThirdTab> = ({
  defaultData,
  images,
  updateForm,
  clientId,
  isMandatoryError,
  clearError,
  handleDuplicateImageError,
  setIsSubmitDisabled,
}) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const camera = useUserMedia()

  return (
    <>
      <UserMediaSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        setIsCameraOpen={setIsCameraOpen}
      />
      <VisitReportImportFileSection
        camera={camera}
        isCameraOpen={isCameraOpen}
        updateForm={updateForm}
        images={images}
        defaultData={defaultData}
        clientId={clientId}
        isMandatoryError={isMandatoryError}
        clearError={clearError}
        handleDuplicateImageError={handleDuplicateImageError}
        setIsSubmitDisabled={setIsSubmitDisabled}
      />
    </>
  )
}
