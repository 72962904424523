import { useEffect, useState } from 'react'
import { getNextAppointment } from '../../services/visitReportsService'
import { IClientEventData } from '../../interfaces/IClientDetails'

/**
 * Used to fetch the client reports.
 */
export const useClientNextAppointment = (clientId: number) => {
  const [clientNextAppointment, setClientNextAppointment] = useState<
    IClientEventData[]
  >([])

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getNextAppointment(clientId)
        setClientNextAppointment(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [clientId])

  return { clientNextAppointment }
}
