import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import Button from '../common/ui/Button'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { STATUS } from '../../enums/common'
import { VISIT_TRAINING } from '../../constants/form'
import { objEqual } from '../../utils/helpers'
import { TStatus } from '../../interfaces/INavigation'
import {
  ILastVisitReportImages,
  IVisitReportObject,
} from '../../interfaces/IVisitReports'
import { ROUTES } from '../../enums/routes'
import { useClientsDetails } from '../../hooks/clientDetails/UseClientDetails'
import {
  VISIT_REPORT_CURRENT_VERSION,
  VISIT_REPORT_IMAGE_LIST,
} from '../../constants/visitReport'

export const VisitReportFormNav: FC<{
  images?: ILastVisitReportImages
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  formIsChanged: boolean
  status: TStatus
  isSubmitDisabled: boolean
  setIsSubmitDisabled: (value: boolean) => void
}> = ({
  images,
  setIsModalOpen,
  status,
  formIsChanged,
  isSubmitDisabled,
  setIsSubmitDisabled,
}) => {
  const history = useHistory()
  const { clientId } = useParams()
  const dispatch = useDispatch()
  const { getValues } = useFormContext()
  const initialFormData = {
    ...mockNewVisitReportFormInitialState,
    visitDate: getValues().visitDate ? new Date(getValues().visitDate) : '',
    duration: getValues().duration,
  }
  const setIsSubmitDisabledCallback = useCallback(setIsSubmitDisabled, [])

  const handleModal = () => {
    const values = getValues()
    const training: IVisitReportObject = {}
    const imagesDefault: IVisitReportObject = {}
    VISIT_TRAINING.forEach((item) => {
      training[`${item.key}`] = values.training === item.value ? item.value : ''
    })
    const numberOfPersons = values.numberOfPersons
      ? values.numberOfPersons
      : null

    VISIT_REPORT_IMAGE_LIST.forEach((item) => {
      imagesDefault[item] = JSON.stringify({
        // @ts-ignore
        keepOld: images[item]?.content,
      })
    })
    setIsModalOpen(
      !objEqual(
        {
          ...values,
          ...training,
          ...{
            userId: '',
            numberOfPersons,
            version: VISIT_REPORT_CURRENT_VERSION,
          },
        },
        { ...initialFormData, ...imagesDefault }
      )
    )
  }

  useEffect(handleModal, [handleModal, formIsChanged])

  const { status: clientStatus } = useClientsDetails(clientId)

  useEffect(() => {
    const submitDisabled =
      status === STATUS.PENDING ||
      status === STATUS.SUCCESS ||
      status === STATUS.DANGER ||
      clientStatus === STATUS.DANGER

    setIsSubmitDisabledCallback(submitDisabled)
  }, [status, clientStatus, formIsChanged, setIsSubmitDisabledCallback])

  return (
    <div className="clientFileButtonsWrapper dFlex">
      <DynamicFormattedMessage
        id="form.button.cancel"
        tag={Button}
        onClick={() => {
          handleModal()
          setTimeout(() => {
            dispatch(
              newVisitReportActions.setNewVisitReport(
                mockNewVisitReportFormInitialState
              )
            )
          }, 1000)
          return clientStatus === STATUS.DANGER
            ? history.replace(generatePath(ROUTES.dashboard))
            : window.history.state
            ? window.history.back()
            : history.replace(
                generatePath(ROUTES.clientDetails, {
                  clientId,
                })
              )
        }}
        className="btn btnOutline linkUnstyled withBackgroundColorDefault px15 py15 cancelVisitReport"
        data-qa="backButton"
      />
      <DynamicFormattedMessage
        id="form.button.newVisitReport.submit"
        tag={Button}
        type="submit"
        className={`btn btnPrimary px15 py15 submitVisitReport ${
          isSubmitDisabled ? 'btnDisabled' : ''
        }`}
        data-qa="submitComment"
        disabled={isSubmitDisabled}
      />
    </div>
  )
}
